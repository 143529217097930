import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "./styles.scss";
import "./mobile.scss";
import {
  Button,
  ButtonIcon,
  CareerCard,
  InfoCard,
} from "../../../../components";
import { RiArrowRightLine } from "react-icons/ri";
import { GetIcon } from "../../../../config/icon";
import Footer from "../../../Footer";
import {Helmet} from "react-helmet";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wizardNo: 1,
      registeredType: 1,
      viewPointWidth: 0,
      viewPointHeight: 0,
      wizardStep: 1,
      isLoading: false,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    console.log("scrollHeight : " + scrollHeight);
    console.log("clientHeight :" + clientHeight);
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
    // this.props.getAllRecentTransfers();
    //this.props.getAllFavouritesTransfers();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }
  render() {
    const { viewPointHeight, isLoading } = this.state;
    const { isMoreInfo, onClick } = this.props;

    return (
      <>
        <Helmet>
          <title>Careers - Join Us at CreditCardOffers.lk</title>
          <meta name="description"
                content="Explore exciting career opportunities at CreditCardOffers.lk. Join our team and help bring the best credit card offers and deals to people in Sri Lanka." />
          <link rel="canonical" href="https://www.creditcardoffers.lk/careers" />
        </Helmet>
        <div className={"mim__header"}>
          <div className={"container"}>
            <div className={"d-flex flex-row"}>
              <ButtonIcon
                type={"back"}
                onClick={() => this.props.history.push("/")}
                size={"lg-x"}
                buttonIcon={GetIcon("leftArrow")}
              />

              <div className={"mimh__title"}>Careers </div>
            </div>
          </div>
        </div>
        {/* Careers */}

        <div className={"mim__contact"}>
          <div
            className={
              "mimca__top-wrapper d-flex flex-column justify-content-center align-items-center container"
            }
          >
            <div className={"mimca__tw-header"}>
              We are looking for a talent people
            </div>
            <div className={"mimca__tw-content "}>
              We are seeking talented individuals to join our dynamic team. If
              you are innovative, passionate, and ready to make a difference, we
              want to hear from you!
            </div>
          </div>

          <CareerCard
            title="Frontend/Backend Developer (Paid Internship)"
            date="1 days ago"
          />
        </div>
        <div className={"mim__footer"}>
          <Footer />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);
