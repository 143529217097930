import React, { Component } from "react";
import "./style.scss";
import { connect } from "react-redux";
import { ConsoleHeader, FavoriteOfferCard } from "../../../../components";
import { Scrollbars } from "react-custom-scrollbars";
import { getOffer, getOfferByUserId } from "../../../../service";
import { userDetails } from "../../../../utils";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewPointWidth: 0,
      viewPointHeight: 0,
      isActiveShadow: false,
      isLoading: false,
      dataSource: [],
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
    //window.removeEventListener('scroll', this.handleOnScroll);
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    //console.log("scrollHeight : " + scrollHeight);
    //console.log("clientHeight :" + clientHeight);
  };

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
    await this.getAll();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  async getAll() {
    this.setState({ isLoading: true });

    let user = userDetails();
    console.log("state::::", user);
    if (user && user.userId) {
      let res = await getOfferByUserId(user.userId);
      if (res && res.status) {
        let offerData = res.data;
        console.log({ offerData });

        this.setState({
          isLoading: false,
          dataSource: offerData,
        });
        // this.props.onClickViewMoreOfferDetails(
        //   offerData,
        //   categoryData,
        //   bankData
        // );
      }
    }
  }

  render() {
    const { viewPointWidth, viewPointHeight, dataSource, isLoading } =
      this.state;
    const { isEnableEdit } = this.props;

    return (
      <>
        <div className={"cp__offer-wrapper"}>
          <div className={"cp__ow__header"}>
            <ConsoleHeader isEnableEdit={isEnableEdit} title={"Offers"} />
          </div>
          <Scrollbars
            style={{ height: viewPointHeight - 280 }}
            thumbMinSize={30}
            universal={true}
            autoHide
          >
            <div className={"cp__ow__body d-flex flex-wrap"}>
              {!isLoading &&
                dataSource &&
                (dataSource || []).map((item, index) => (
                  <FavoriteOfferCard
                    header={item.header}
                    label={item.label}
                    status={item.status}
                    isSelect={true}
                    createdAt={item.createdAt}
                    imgUrl={item.bannerImageURL}
                    onSelectedMerchant={() => {
                      // if (this.state.selectOffer) {
                      //   this.setState({
                      //     selectOffer: null,
                      //   });
                      // } else {
                      //   this.setState({
                      //     selectOffer: item,
                      //   });
                      // }
                    }}
                  />
                ))}
            </div>
          </Scrollbars>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
