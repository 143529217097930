import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import { v4 as uuidv4 } from "uuid";

const Index = (props) => (
  <>
    <div
      key={uuidv4()}
      className={`check-box-wrapper ${props.type}
         ${props.isState ? "active" : ""}`}
      onClick={props.onClick}
    >
      <div className={"d-flex align-items-center"}>
        <div className={"check-box-wrapper mr-2"}>
          {props.isState && <>{props.icon}</>}
        </div>

        <div className={"cn-text"}>
          {props.text}
          {props.link && <a className={"link"}> {props.link}</a>}
        </div>
      </div>
    </div>
  </>
);

Index.propTypes = {
  text: PropTypes.string.isRequired,
};

Index.defaultProps = {
  text: "text not found",
  icon: "<RiCloseFill/>",
};

export default Index;
