import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./styles.scss";
import { GetIcon } from "../../../config/icon";

import { ButtonIcon, CheckBox } from "../../../components";
import * as actionTypes from "../../../store/actions/types";
import { getOfferByCategoryByBank } from "../../../service";

const Index = (props) => {
  const [bankIdArray, setBankIdArray] = useState([]);
  const [categoryIdArray, setCategoryIdArray] = useState([]);
  const [bankData, setBankData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const isActiveFilter = useSelector((state) => state.offer.isOpen);
  const activeMenuType = useSelector((state) => state.offer.type);
  const activeId = useSelector((state) => state.offer.activeId);
  const activeBankName = useSelector((state) => state.offer.activeBankName);
  const activeCategoryName = useSelector(
    (state) => state.offer.activeCategoryName
  );
  const bankIdList = useSelector((state) => state.offer.bankIdList);
  const categoryIdList = useSelector((state) => state.offer.categoryIdList);

  const dispatch = useDispatch();

  //GET OFFER BY BANK
  const getOfferFilterByBankId = async (bankId, bankName) => {
    console.log("bankID:::" + bankId);
    dispatch({
      type: actionTypes.SET_FILTER_OPTION_BANK,
      payload: { type: "bank", id: bankId, filterName: bankName },
    });

    setBankIdArray((arr) => [...arr, bankId]);
    console.log("bankIdArray :", { bankIdArray });

    dispatch({ type: actionTypes.ON_LOAD_OFFER });
    dispatch({ type: actionTypes.CLOSE_FILTER_ITEM });

    // if (activeCategoryName) {
    //     await getOfferFilterByCategoryByBank(bankId, categoryIdList && categoryIdList[0]);
    //     return;
    // }
    // let res = await getOfferByBankId(bankId, 1, 2);
    // if (res) {
    //     dispatch({
    //         type: actionTypes.ON_COMPLETE_LOAD_OFFER,
    //         payload: {"data": res && res.data}
    //     });

    // }
  };

  //GET OFFER BY CATEGORY
  const getOfferFilterByCategoryId = async (categoryId, categoryName) => {
    console.log("categoryID:::" + categoryId);
    dispatch({
      type: actionTypes.SET_FILTER_OPTION_CATEGORY,
      payload: { type: "category", id: categoryId, filterName: categoryName },
    });

    setCategoryIdArray((arr) => [...arr, categoryIdArray]);
    console.log("categoryIdArray :", { categoryIdArray });

    dispatch({ type: actionTypes.ON_LOAD_OFFER });
    dispatch({ type: actionTypes.CLOSE_FILTER_ITEM });

    // if (activeBankName) {
    //     await getOfferFilterByCategoryByBank(bankIdList && bankIdList[0], categoryId);
    //     return;
    // }

    // let res = await getOfferByCategoryId(categoryId, 1, 2);
    // if (res) {
    //     dispatch({
    //         type: actionTypes.ON_COMPLETE_LOAD_OFFER,
    //         payload: {"data": res && res.data}
    //     });

    // }
  };

  const onClickSelectBank = (i) => {
    let updateBankData = [...bankData];
    updateBankData[i].isActive = !updateBankData[i].isActive;
    setBankData(updateBankData);

    //
  };

  const onClickSelectCategory = (i) => {
    let updateCategoryData = [...categoryData];

    updateCategoryData[i].isActive = !updateCategoryData[i].isActive;
    setCategoryData(updateCategoryData);
  };

  useEffect(() => {
    if (props.bankData?.length > 0 || props.categoryData?.length > 0) {
      setBankData(props.bankData);
      setCategoryData(props.categoryData);
    }
  }, [props.bankData, props.categoryData]);

  //GET OFFER BY CATEGORY BY BANK
  const getOfferFilterByCategoryByBank = async (
    bankId,
    categoryId,
    categoryName,
    bankName
  ) => {
    dispatch({ type: actionTypes.ON_LOAD_OFFER });
    dispatch({ type: actionTypes.CLOSE_FILTER_ITEM });

    // let res = await getOfferByCategoryByBank(categoryId, bankId, 1, 2);
    // if (res) {
    //   dispatch({
    //     type: actionTypes.ON_COMPLETE_LOAD_OFFER,
    //     payload: { data: res && res.data },
    //   });
    // }
  };

  return (
    <>
      <div
        className={`console__filter-item_wrapper ${
          isActiveFilter ? "active" : "hide"
        }`}
      >
        <div className={"cf__item-inner-block"}>
          <div className={"container position-relative"}>
            <div className={"filter-title mb-3"}>
              {activeMenuType === "bank"
                ? "Filter by Bank"
                : activeMenuType === "category"
                ? "Filter by Category"
                : ""}
            </div>
            {activeMenuType === "bank" && (
              <div className={"filter-option-wrapper d-flex flex-wrap"}>
                {bankData &&
                  bankData.length > 0 &&
                  (bankData || []).map((item, index) => (
                    <CheckBox
                      onClick={() => {
                        getOfferFilterByBankId(item.bankId, item.bankName);
                        onClickSelectBank(index);
                      }}
                      text={item.bankName}
                      type={"filter"}
                      isState={item.isActive}
                      icon={GetIcon("close")}
                    />
                  ))}
              </div>
            )}

            {activeMenuType === "category" && (
              <div
                className={"filter-option-wr with-h-scroll d-flex flex-wrap"}
              >
                {categoryData &&
                  categoryData.length > 0 &&
                  (categoryData || []).map((item, index) => (
                    <CheckBox
                      onClick={() => {
                        getOfferFilterByCategoryId(
                          item.categoryId,
                          item.categoryName
                        );
                        onClickSelectCategory(index);
                      }}
                      text={item.categoryName}
                      type={"filter"}
                      isState={item.isActive}
                      icon={GetIcon("close")}
                    />
                  ))}
              </div>
            )}

            <div className={"close-filter"}>
              <ButtonIcon
                type={"close"}
                onClick={() =>
                  dispatch({
                    type: actionTypes.CLOSE_FILTER_ITEM,
                    payload: null,
                  })
                }
                size={"md"}
                buttonIcon={GetIcon("close")}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

Index.propTypes = {};

Index.defaultProps = {
  text: "text not found",
  icon: "<RiCloseFill/>",
};

export default Index;
