import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;


export const createNewSubscribe = async (body) => {
    try {
        // Get response
        let response = await axios.post(
            `${BASE_URL}/new/subscribe`,
            body
        );
        return response.data;
    } catch (err) {
        console.log("ERR_GET_FILTER_BY_CATEGORY_BANK_API:", err);
        throw err;
    }
};
