import React from "react";
import "./styles.scss";
import { GetIcon } from "../../../config/icon";
import { getMaskedPhoneNumber } from "../../../utils";

const Index = (props) => {
  return (
    <>
      <div className={"top-section-join-frm"}>
        {props.isLogo && (
          <>
            {props.isBack && (
              <div
                className={"back-action d-flex align-items-center"}
                onClick={async () => {
                  props.history.push("/sign-in");
                }}
              >
                <div className={"icon-wrp"}> {GetIcon("back")}</div>
                <div className={"txt"}>Home</div>
              </div>
            )}
            <div className={"logo-section"}>
              <img
                className={"join-form-logo sm"}
                src={require("../../../assets/img/logo/creditcardoffers.lk.svg")}
              />
            </div>
          </>
        )}
      </div>

      {/*form-header-section*/}
      <div className={"form-header-section"}>
        <h1>{props.header}</h1>
        {props.subtitle && (
          <span className={"join-form-sub-title"}>
            A verification code will be sent to your new mobile number ending in
            <span className={"highlight"}> 04.</span>
            Please enter your updated mobile number below.
          </span>
        )}
        {props.type === "reset" ? (
          <>
            {props.wizardNo >= 2 && (
              <span className={"join-form-sub-title"}>
                Discover
                <span className={"highlight"}>best</span>credit card offers in
                one place
              </span>
            )}
          </>
        ) : (
          <>
            {props.wizardNo <= 2 && (
              <span className={"join-form-sub-title"}>
                Discover
                <span className={"highlight"}>best</span>credit card offers in
                one place
              </span>
            )}
          </>
        )}

        {props.type === "reset" ? (
          <>
            {props.wizardNo === 1 && (
              <div className={"verify-mobile-msg d-fle mt-1 "}>
                <span className={"join-form-sub-title"}>
                  We send a code to
                  <span className={"highlight"}>
                    {getMaskedPhoneNumber(props.mobileNo)}{" "}
                  </span>
                </span>
                <a
                  className={"change-no"}
                  onClick={props.onClickUpdateMobileNo}
                >
                  Change
                </a>
              </div>
            )}
          </>
        ) : (
          <>
            {props.wizardNo === 3 && (
              <div className={"verify-mobile-msg d-fle mt-1 "}>
                <span className={"join-form-sub-title"}>
                  We send a code to
                  <span className={"highlight"}>
                    {getMaskedPhoneNumber(props.mobileNo)}{" "}
                  </span>
                </span>
                <a
                  className={"change-no"}
                  onClick={props.onClickUpdateMobileNo}
                >
                  Change
                </a>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

Index.propTypes = {};

Index.defaultProps = {
  header: "text not found",
};

export default Index;
