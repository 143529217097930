import * as actionTypes from "../actions/types";
import produce from "immer";

const initialState = {
  data: [],
  error: null,
  isLoading: false,
  isLoadingMoreOffer: false,
  isOpen: false,
  type: null,
  totalCount: 0,
  pageNo: 1,
  noOfPage: 10,
  offerData: [],
  activeId: null,
  activeBankName: [],
  activeCategoryName: [],
  recentSelectBanks: [],
  recentSelectCategories: [],
  bankIdList: [],
  categoryIdList: [],
  isActiveFilter: false,
  selectedOfferData: "",
  selectedOffer: null,
};

let newData = [];
export default produce((draft = initialState, action) => {
  const { type, payload } = action;
  console.log("draft", draft);
  switch (type) {
    case actionTypes.ON_LOAD_OFFER:
      draft.offerData = [];
      draft.isLoading = true;
      return draft;
    case actionTypes.ON_LOADING_MORE_OFFER:
      draft.isLoadingMoreOffer = true;
      return draft;
    case actionTypes.ON_LOADING_MORE_OFFER_COMPLETE:
      draft.isLoadingMoreOffer = false;
      return draft;
    case actionTypes.ON_COMPLETE_LOAD_OFFER:
      console.log("ON_COMPLETE_LOAD_OFFER", payload.data);
      draft.offerData = payload.data;
      draft.isLoading = false;
      draft.totalCount = payload.totalCount;
      draft.pageNo = payload.pageNo;
      return draft;
    case actionTypes.SET_OFFER_DATA_FILTER:
      console.log("SET_OFFER_DATA_FILTER", payload.data);
      draft.offerData = payload.offerData;
      draft.isLoading = false;
      draft.totalCount = payload.totalCount;
      draft.pageNo = payload.pageNo;
      return draft;
    case actionTypes.ON_LOAD_MORE_OFFER:
      console.log("moreoffer", draft.offerData);
      draft.offerData = draft.offerData.concat(...newData, payload.data);
      draft.isLoading = false;
      draft.isLoadingMoreOffer = false;
      draft.totalCount = payload.totalCount;
      draft.pageNo = payload.pageNo;
      return draft;
    case actionTypes.OPEN_FILTER_ITEM:
      draft.type = payload;
      draft.isOpen = true;
      return draft;
    case actionTypes.CLOSE_FILTER_ITEM:
      draft.type = null;
      draft.isOpen = false;
      return draft;
    case actionTypes.SET_FILTER_OPTION_BANK:
      console.log("SET_FILTER_OPTION_BANK", payload.filterName);
      let bankIdsList = [...draft.bankIdList];
      if (bankIdsList.includes(payload.id)) {
        bankIdsList = bankIdsList.filter((id) => id !== payload.id);
      } else {
        bankIdsList = [...bankIdsList, payload.id];
      }
      let bankNameList = [...draft.activeBankName];
      if (bankNameList.some((bank) => bank.filterName === payload.filterName)) {
        // bankNameList = bankNameList.map((bank) =>
        //   bank.id === payload?.id ? { ...bank, isActive: !bank.isActive } : bank
        // );
        bankNameList = bankNameList.filter((bank) => bank.id !== payload.id);
      } else {
        bankNameList = [...bankNameList, payload];
      }

      draft.type = payload.type;
      draft.bankIdList = bankIdsList;
      draft.activeId = payload.id;
      draft.activeBankName = bankNameList;
      draft.recentSelectBanks = bankNameList.length != 0 && bankNameList;
      // draft.isOpen = true;
      draft.isActiveFilter = bankIdsList.length > 0 ? true : false;
      return draft;
    case actionTypes.SET_FILTER_OPTION_CATEGORY:
      console.log("SET_FILTER_OPTION_CATEGORY", payload.filterName);
      let categoryIdsList = [...draft.categoryIdList];
      if (categoryIdsList.includes(payload.id)) {
        categoryIdsList = categoryIdsList.filter((id) => id !== payload.id);
      } else {
        categoryIdsList = [...categoryIdsList, payload.id];
      }
      let categoryNameList = [...draft.activeCategoryName];
      if (
        categoryNameList.some(
          (category) => category.filterName === payload.filterName
        )
      ) {
        // categoryNameList = categoryNameList.map((category) =>
        //   category.id === payload?.id
        //     ? { ...category, isActive: !category.isActive }
        //     : category
        // );
        categoryNameList = categoryNameList.filter(
          (bank) => bank.id !== payload.id
        );
      } else {
        categoryNameList = [...categoryNameList, payload];
      }
      draft.type = payload.type;
      draft.categoryIdList = categoryIdsList;
      draft.activeId = payload.id;
      draft.activeCategoryName = categoryNameList;
      draft.recentSelectCategories =
        categoryNameList.length != 0 && categoryNameList;
      // draft.isOpen = true;
      draft.isActiveFilter = categoryIdsList.length > 0 ? true : false;
      return draft;
    case actionTypes.CLEAR_ALL_FILTER:
      console.log("CLEAR_ALL_FILTER");
      draft.type = null;
      draft.isOpen = false;
      draft.bankIdList = [];
      draft.categoryIdList = [];
      draft.activeId = null;
      draft.activeBankName = [];
      draft.activeCategoryName = [];
      draft.isActiveFilter = false;
      return draft;
    default:
      return draft;
  }
});
