import * as actionTypes from "./types";

export const onClickOpenOfferView = (data) => {
  return {
    type: actionTypes.OPEN_OFFER_VIEW_MODAL,
    payload: { data },
  };
};

export const onClickCloseOfferView = (data) => {
  return {
    type: actionTypes.CLOSE_OFFER_VIEW_MODAL,
    payload: { data },
  };
};
