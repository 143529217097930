import React from "react";

const TOKEN_KEY = 'iszkQ9SQdT';
const INSIGHT_KEY = 'ccoInsight@keyNew891';

const jwt = require('jsonwebtoken');

export const login = (token) => {
    return new Promise((resolve, reject) => {
        try {
            // Store the token in localStorage
            localStorage.setItem(TOKEN_KEY, token);
            // Resolve the promise successfully
            resolve();
        } catch (error) {
            // Reject the promise if an error occurs
            reject(error);
        }
    });
};


export const getToken = () => {
    // console.log('get token....');
    const token = localStorage.getItem(TOKEN_KEY);
    return token;
};

export const userLogOut = () => {
    console.log('remove token....');
    localStorage.removeItem(TOKEN_KEY);
    return true;
};

export const isLogin = () => {
    console.log('login....');
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
        let decoded = jwt.decode(token);
        if (decoded) {
            return true;
        } else {
            console.log('User cant be access main console : logout');
            return false;
        }
    } else {
        console.log('User cant be access main console : logout');
        return false;
    }
};

export const userDetails = () => {
    //console.info('get  user details....');
    const token = getToken();
    console.log('token', jwt.decode(token));
    if (token) {
        // console.log(jwt.decode(token));
        return jwt.decode(token);
    } else {
        console.info('user  details not found....');
        return false
    }
};


export const setInsight = (token) => {
    localStorage.setItem(INSIGHT_KEY, token);
};


export const getInsight = () => {
    console.log("first_getInsight", null);
    return new Promise((resolve, reject) => {
        try {
            const token = localStorage.getItem(INSIGHT_KEY);
            if (token !== null) {
                console.log("inner_getInsight", token);
                resolve(jwt.decode(token));
            } else {
                console.log("inner_getInsight","not found");
                reject(false);
            }
        } catch (error) {
            console.log("inner_getInsight","not found");
            reject(false);
        }
    });
};

export const getInsightToken = () => {
    // console.log('get token....');
    const token = localStorage.getItem(INSIGHT_KEY);
    return token;
};


export const removeInsight = () => {
    console.log('remove Insight token....');
    localStorage.removeItem(INSIGHT_KEY)
    return true;
};

export const appVersion = () => {
    // console.log('get token....');
    return process.env.REACT_APP_VERSION;
}

