import React, {Component} from "react";
import "./style.scss";
import {appVersion, GetMessageText, getToken, login, validateNumber,} from "../../utils";
import "./mobile.scss";
import * as actionTypes from "../../store/actions/types";
import {openNotification} from "../../config/notification";
import * as user from "../../service/userApi";
import {connect} from "react-redux";
import {GetIcon} from "../../config/icon";
import {Button, FilterColor_1, FilterColor_2, Input, JoinFormHeader, Navigator,} from "../../components";
import {RiArrowRightLine} from "react-icons/ri";
import {Helmet} from "react-helmet";

class SignInComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isSuccess: false,
      wizardNo: 1,
      userId: null,
      tags: [],
      tag: null,
      otp: null,
      token: null,
      termsAndCondition: false,
      formIsValid: false,
      viewPointWidth: 0,
      viewPointHeight: 0,
      displayOtpResend: false,
      isEnableLoginIn: false,
      isExistingUser: false,
      existingUser: null,
      dataForm: {
        mobileNo: {
          key: "mobileNo",
          elementType: "phoneNo",
          elementConfig: {
            type: "input",
            placeholder: "Ex : 715 986 461",
            minLength: "9",
            maxLength: "9",
          },
          value: "",
          validation: {
            required: true,
            maxLength: 9,
            mobileNo: true,
          },
          valid: false,
          touched: false,
          label: "Enter your Mobile No",
        },
        password: {
          key: "password",
          elementType: "input",
          elementConfig: {
            type: "password",
            placeholder: "*************",
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "Password",
        },
      },
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    // window.addEventListener('scroll', this.listenToScroll);
    window.addEventListener("scroll", this.handleOnScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
    // window.addEventListener('scroll', this.listenToScroll);
  }

  onClickGoToNext = (_wizardNo) => {
    this.setState({
      wizardNo: _wizardNo,
    });
  };

  //==== input Handle Change
  inputHandleChange = (event, inputIdentity) => {
    const updateSignUpForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateSignUpForm[inputIdentity],
    };

    switch (inputIdentity) {
      case "mobileNo":
        updatedFormElement.value = validateNumber(
          updatedFormElement.value,
          event.target.value.toString()
        );
        break;
      default:
        updatedFormElement.value = event.target.value;
        break;
    }

    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updateSignUpForm[inputIdentity] = updatedFormElement;
    let formIsValid = true;
    for (let inputIdentifier in updateSignUpForm) {
      formIsValid = updateSignUpForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateSignUpForm, formIsValid: formIsValid });
  };

  //=== validation
  checkValidity(value, rules) {
    let isValid = true;

    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }
    if (rules.mobileNo) {
      isValid = true;
    }

    if (rules.email) {
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      isValid = re.test(String(value).toLowerCase()) && isValid;
    }
    return isValid;
  }

  validation = (validationValue, inputIdentity) => {
    const updateSignUpForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateSignUpForm[inputIdentity],
    };

    updatedFormElement.value = validationValue;
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updateSignUpForm[inputIdentity] = updatedFormElement;
    let formIsValid = true;
    for (let inputIdentifier in updateSignUpForm) {
      formIsValid = updateSignUpForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateSignUpForm, formIsValid: formIsValid });
  };

  async updateInputValidationErrors() {
    const { wizardNo, userType } = this.state;
    let df = { ...this.state.dataForm };
    let formIsValid = true;
    //========= VALIDATION USER -- OFFER VIEWER
    //this.setState({isLoading: true});
    for (let itemKey of Object.keys(df)) {
      if (itemKey === "mobileNo") {
        if (!df[itemKey].value) {
          df[itemKey].touched = true;
          df[itemKey].valid = false;
          formIsValid = false;
        } else {
          if (df[itemKey].value.length < df[itemKey].validation.maxLength) {
            df[itemKey].touched = true;
            df[itemKey].valid = false;
            df[itemKey].invalidReason = GetMessageText("invalidMobileNo");
            formIsValid = false;
          }
        }
        this.setState({
          dataForm: df,
          formIsValid: formIsValid,
          isLoading: false,
        });
      }
    }
  }

  async onOtpEnter(otp) {
    console.log("hue otp entered", otp);
    this.setState({ isEnableLoginIn: true, otp: otp });
  }

  async onOtpResend(userId, mobileNo) {
    this.setState({ isLoading: true });
    let res = await user.otpSend(userId, mobileNo);
    if (res) {
      this.setState({ isLoading: false, displayOtpResend: false });
    }
  }

  onClickRightIcon(key) {
    let df = { ...this.state.dataForm };
    if (df[key].elementConfig.type === "password")
      df[key].elementConfig.type = "text";
    else df[key].elementConfig.type = "password";
    this.setState({ dataForm: df });
  }

  onCountdownFinish() {
    this.setState({ displayOtpResend: true, isEnableLoginIn: false });
  }

  async sendOtp(isResend) {
    try {
      // (cif, email, username, deviceId, otpEventId, mobileNo, credToken)
      await this.setState({
        displayOtpResend: false,
      });
    } catch (e) {
      console.log("error sending otp", e.message);
    }
  }

  async sendVerify(userId, OTP, token) {
    this.setState({ isLoading: true, displayOtpResend: false });
    let res = await user.otpVerify(userId, OTP, token);
    if (res && res.data && res.data.status) {
      console.log("sign in user:::", res.data.data);
      this.setState({ isLoading: false, displayOtpResend: false });
      login(res?.data?.token);
      this.props.onAddLoginUser(res.data.data);
      this.props.onClickCloseSignUp();
    } else {
      this.setState({ isLoading: false, displayOtpResend: false });
      openNotification({
        title: "One time password",
        message: "Invalid authentication, Please check again OTP",
        type: "danger",
        container: "top-center",
      });
    }
  }

  render() {
    const {
      dataForm,
      isLoading,
      isSuccess,
      viewPointHeight,
      viewPointWidth,
      userId,
      token,
      wizardNo,
      otp,
      isExistingUser,
    } = this.state;
    const { props } = this.props;
    return (
        <>
          {/*<h1>Hi....</h1>*/}

            <Helmet>
              <title>Sign In - Access Best Credit Card Offers in Sri Lanka</title>
              <meta name="description" content="Sign in to CreditCardOffers.lk to access exclusive credit card offers, deals, and promotions in Sri Lanka. Manage your account and stay updated on the latest offers." />
              <link rel="canonical" href="https://www.creditcardoffers.lk/sign-in" />
            </Helmet>

          <div className="row justify-content-md-center">
            <div className={"join-artworkTop"}>
              <img src={require("../../assets/img/join_artWork_top.svg")}/>
            </div>
            <div className={"mobile-signin-join-artworkTop"}>
              <img
                  src={require("../../assets/img/mobile_join_artWork_top.svg")}
              />
            </div>
            <div
                className={
                  "col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xxl-5"
                }
            >
              <div className={"new_join-form-wrapper"}>
                <div
                    className={"d-flex justify-content-start "}
                    style={{marginBottom: "12px"}}
                >
                <Navigator
                  size={"sm"}
                  icon={"leftArrow"}
                  navigationTitle={"Home"}
                  onClick={async () => this.props.history.push("/")}
                />
              </div>
              <JoinFormHeader
                isLogo={true}
                wizardNo={this.state.wizardNo}
                history={this.props.history}
                header={"Sign in"}
              />

              <div className={"join-form-action-section"}>
                <div className={"data-form-wrapper flex-row"}>
                  <>
                    <Input
                      elementConfig={dataForm.mobileNo.elementConfig}
                      elementType={dataForm.mobileNo.elementType}
                      required={dataForm.mobileNo.validation.required}
                      maxLength={dataForm.mobileNo.validation.maxLength}
                      invalidReason={dataForm.mobileNo.invalidReason}
                      isinvalid={!dataForm.mobileNo.valid}
                      touched={dataForm.mobileNo.touched}
                      value={dataForm.mobileNo.value}
                      size={"lg"}
                      verificationStatus={dataForm.mobileNo.valid}
                      showVerification={!!dataForm.mobileNo.value}
                      margin={"m-t-24"}
                      label={dataForm.mobileNo.label}
                      changed={(event) =>
                        this.inputHandleChange(event, dataForm.mobileNo.key)
                      }
                    />
                  </>
                  {isExistingUser && (
                    <>
                      <div className={"mt-3 animate__animated animate__fadeIn"}>
                        <Input
                          elementConfig={dataForm.password.elementConfig}
                          elementType={dataForm.password.elementType}
                          required={dataForm.password.validation.required}
                          isinvalid={!dataForm.password.valid}
                          isShowPasswordStrengthMeter={false}
                          invalidReason={dataForm.password.invalidReason}
                          touched={dataForm.password.touched}
                          value={dataForm.password.value}
                          isRightIcon={true}
                          onClickRightIcon={() =>
                            this.onClickRightIcon(dataForm.password.key)
                          }
                          rightIcon={GetIcon("eye")}
                          size={"lg"}
                          margin={"m-t-0"}
                          label={dataForm.password.label}
                          changed={(event) =>
                            this.inputHandleChange(event, dataForm.password.key)
                          }
                        />
                      </div>
                      <div
                        className={"link"}
                        onClick={async () => {
                          let token = getToken();
                          let res = await user.newOTPVerify(
                            userId,
                            "MOBILE",
                            token,
                            dataForm["mobileNo"].value
                          );
                          if (res && res.data && res.data.status) {
                            this.props.history.push("/forget-password");
                            this.props.openSignupOTP({
                              user: this.state.existingUser,
                              token: token,
                            });
                          }
                        }}
                      >
                        &nbsp; Forget password
                      </div>
                    </>
                  )}
                </div>

                <div className={"join-form-cta-section"}>
                  <Button
                    text={!isExistingUser ? "Verify" : "Sign in"}
                    type={"dark md"}
                    size={"full-width"}
                    isLoading={isLoading}
                    rightIcon={true}
                    reactIcon={<RiArrowRightLine />}
                    onClick={async () => {
                      let existingMobileRes = null;
                      await this.updateInputValidationErrors();
                      if (!isExistingUser && !userId) {
                        if (this.state.formIsValid) {
                          this.setState({
                            isLoading: true,
                            userId: null,
                            token: null,
                          });
                          //find existing user and when check if user mobile verification and accountBlock status
                          existingMobileRes = await user.isExistingUserMobileNo(
                            dataForm["mobileNo"].value
                          );
                          this.setState({ isLoading: false });
                          if (existingMobileRes?.data) {
                            console.log("existing", existingMobileRes);
                            if (existingMobileRes && existingMobileRes.status) {
                              login(existingMobileRes.token);
                              let loginUser =
                                existingMobileRes && existingMobileRes.data;
                              if (loginUser.isMobileVerification) {
                                this.setState({ isLoading: false });

                                this.setState({
                                  isExistingUser: true,
                                  userId: existingMobileRes && loginUser.userId,
                                  existingUser: loginUser,
                                });
                              } else {
                                this.setState({ isLoading: false });
                                // const token = getToken();
                                const token = existingMobileRes.token;
                                this.setState({ token: token });
                                let res = await user.newOTPVerify(
                                  loginUser.userId,
                                  "MOBILE",
                                  token,
                                  dataForm["mobileNo"].value
                                );
                                if (res && res.data && res.data.status) {
                                  this.props.history.push("/verify-mobileNo");
                                  this.props.openSignupOTP({
                                    user: loginUser,
                                    token: token,
                                  });
                                }
                              }
                            }
                            // if (!existingMobileRes?.data?.isMobileVerification) {
                            //   alert('mobile verification.');
                            //
                            // } else {
                            //   alert('found user.');
                            // }
                          } else {
                            alert("not found.");
                          }
                        } else {
                          //verification false
                          //please send OTP for verify user
                        }
                      } else {
                        //user authentication
                        this.setState({ isLoading: true });
                        let res = await user.signIn({
                          mobileNo: dataForm["mobileNo"].value,
                          password: dataForm["password"].value,
                          userId: this.state.userId,
                        });
                        this.setState({ isLoading: false });
                        if (res?.data?.data && res?.data?.status) {
                          let user = res?.data?.data;
                          console.log("valid user", user, user.isAccountBlock);
                          if (user.isAccountBlock) {
                            openNotification({
                              title: "Account blocked!",
                              message: "Your account blocked.",
                              type: "danger",
                              container: "top-center",
                            });
                            this.props.history.push("/");
                          } else {
                            login(res?.data?.token);
                            this.props.onAddLoginUser(res?.data?.data);
                            this.props.history.push("/");
                          }
                        } else {
                          openNotification({
                            title: "Sign in",
                            message:
                              "Invalid login details.Please check and try again.",
                            type: "danger",
                            container: "top-center",
                          });
                        }
                      }
                    }}
                  />
                </div>
                <div
                  className={"mobile-signin-login-url-wrap d-flex flex-row "}
                >
                  New to creditcardoffers.lk ?{" "}
                  <div
                    className={"link"}
                    onClick={() => this.props.history.push("/sign-up")}
                  >
                    &nbsp; Join now{" "}
                  </div>
                </div>
              </div>
            </div>

            <div className={"login-url-wrap d-flex "}>
              New to creditcardoffers.lk ?{" "}
              <div
                className={"link"}
                onClick={() => this.props.history.push("/sign-up")}
              >
                &nbsp; Join now{" "}
              </div>
            </div>
            <div
              className={
                "d-flex flex-row justify-content-center app_version mt-3"
              }
            >
              Version {appVersion()}
            </div>
          </div>
          <div className={"mobile-signin-join-form-cta-section"}>
            <Button
              text={!isExistingUser ? "Verify" : "Sign in"}
              type={"dark md"}
              size={"full-width"}
              isLoading={isLoading}
              rightIcon={true}
              reactIcon={<RiArrowRightLine />}
              onClick={async () => {
                let existingMobileRes = null;
                await this.updateInputValidationErrors();
                if (!isExistingUser && !userId) {
                  if (this.state.formIsValid) {
                    this.setState({
                      isLoading: true,
                      userId: null,
                      token: null,
                    });
                    //find existing user and when check if user mobile verification and accountBlock status
                    existingMobileRes = await user.isExistingUserMobileNo(
                      dataForm["mobileNo"].value
                    );
                    this.setState({ isLoading: false });
                    if (existingMobileRes?.data) {
                      this.setState({
                        isExistingUser: true,
                        userId: existingMobileRes?.data?.userId,
                      });
                      // if (!existingMobileRes?.data?.isMobileVerification) {
                      //   alert('mobile verification.');
                      //
                      // } else {
                      //   alert('found user.');
                      // }
                    } else {
                      alert("not found.");
                    }

                    console.log("existing", existingMobileRes);
                    // if (existingMobileRes && existingMobileRes.status) {
                    //   login(existingMobileRes.data.token);
                    //   let loginUser =
                    //     existingMobileRes &&
                    //     existingMobileRes.data &&
                    //     existingMobileRes.data.user;
                    //   if (loginUser.isMobileVerification) {
                    //     this.setState({ isLoading: false });
                    //
                    //     this.setState({
                    //       isExistingUser: true,
                    //       userId: existingMobileRes && loginUser.userId,
                    //     });
                    //   } else {
                    //     this.setState({ isLoading: false });
                    //     const token = getToken();
                    //
                    //     let res = await user.newOTPVerify(
                    //       loginUser.userId,
                    //       "MOBILE",
                    //       token,
                    //       dataForm["mobileNo"].value
                    //     );
                    //     if (res && res.data && res.data.status) {
                    //       this.props.history.push("/verify-mobileNo");
                    //       this.props.openSignupOTP({
                    //         user: loginUser,
                    //         token: token,
                    //       });
                    //     }
                    //   }
                    // }
                  } else {
                    //verification false
                    //please send OTP for verify user
                  }
                } else {
                  //user authentication
                  this.setState({ isLoading: true });
                  let res = await user.signIn({
                    mobileNo: dataForm["mobileNo"].value,
                    password: dataForm["password"].value,
                    userId: this.state.userId,
                  });
                  this.setState({ isLoading: false });
                  if (res?.data && res?.data?.status) {
                    let user = res?.data?.data;
                    console.log("valid user", user, user.isAccountBlock);
                    if (user.isAccountBlock) {
                      openNotification({
                        title: "Account blocked!",
                        message: "Your account blocked.",
                        type: "danger",
                        container: "top-center",
                      });
                      this.props.history.push("/");
                    } else {
                      login(res?.data?.token);
                      this.props.onAddLoginUser(res?.data?.data);
                      this.props.history.push("/");
                    }
                  } else {
                    openNotification({
                      title: "Sign in",
                      message:
                        "Invalid login details.Please check and try again.",
                      type: "danger",
                      container: "top-center",
                    });
                  }
                }
              }}
            />
          </div>
        </div>
        <div className={"filter-bg-1"}>
          <FilterColor_1 />
        </div>
        <div className={"filter-bg-2"}>
          <FilterColor_2 />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.user.data,
    isLogin: state.user.isLogin,
    isRegister: state.user.isRegister,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLoginUserDetailsById: (id) => dispatch(user.getLoginUserDetailsById(id)),
    onClickOpenSignUp: () =>
      dispatch({ type: actionTypes.OPEN_SIGN_UP, status: true }),
    onClickCloseSignUp: () =>
      dispatch({ type: actionTypes.CLOSED_SIGN_UP, status: false }),
    onAddLoginUser: (userData) =>
      dispatch({ type: actionTypes.ADD_LOGIN_USER, loginUser: userData }),
    onClickCloseSignIn: () =>
      dispatch({ type: actionTypes.CLOSED_SIGN_IN, status: false }),
    openSignupOTP: (payload) =>
      dispatch({ type: actionTypes.OPEN_OTP_VERIFY, payload: payload }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignInComponent);
