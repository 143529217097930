import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import { Dropdown, Image } from "antd";
import moment from "moment";
import { RiMore2Fill } from "react-icons/ri";
import { Tag } from "../..";

const Index = (props) => {
  const menu = (
    <>
      <div className={"header-menu-wrapper"}>
        <div
          className={"hm__inner-content d-flex align-items-center"}
          onClick={props.onClickViewOffer}
        >
          <div className={"hmi-text"}>View offer</div>
        </div>

        <div className={"hm__inner-content d-flex align-items-center"}>
          <div className={"hmi-text"}>Active</div>
        </div>
        <div className={"hm__inner-content d-flex align-items-center"}>
          <div className={"hmi-text"} onClick={props.onClickEdit}>
            Edit
          </div>
        </div>
      </div>
    </>
  );
  return (
    <>
      <div
        className={`cco__offer-card__wrapper d-flex flex-row`}
        onClick={props.onClickViewOffer}
      >
        <div className={"img-wrapper"}>
          <img src={props.imgUrl} alt="creditcard offers" />
        </div>
        <div className={"cco__ocw__logo"}>
          <img src={props.logo} alt="creditcard offers" />
        </div>
        <div
          className={
            "d-flex flex-row justify-content-between align-items-center"
          }
          style={{ width: "100%", paddingLeft: "12px" }}
        >
          <div className={"d-flex flex-column"}>
            <div className={"cco__ocw__details"}>{props.details}</div>
            <div className={"cco__ocw__name"}>{props.name}</div>
            {/* <div className={"cco__ocw__tags d-flex flex-row"}>
              <Tag type={"pending"} tagName={props.status} />
              <Tag type={"username"} tagName={props.userName} />
              <Tag type={"companyName"} tagName={props.bankName} />
            </div> */}
            <div className={"cco__ocw__time"}>
              {moment(props.createAt).startOf("day").fromNow()}
            </div>
          </div>
          {/* <div className={"cco__ocw__menu"}>
            <Dropdown overlay={menu}>
              <span className={"ml-1 pass-info"}>
                <RiMore2Fill />
              </span>
            </Dropdown>
          </div> */}
        </div>
      </div>
      <div className={"border-bottom"}></div>
    </>
  );
};

Index.propTypes = {
  title: PropTypes.string.isRequired,
};

Index.defaultProps = {
  title: "",
};

export default Index;
