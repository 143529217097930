import * as actionTypes from '../actions/types';

const initialState = {
    data: [],
    activeData: [],
    filterData: [],
    error: null,
    loading: false
};
let newData = [];
let activeData = [];

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_TAG_PENDING:
            return {
                ...state,
                error: null,
                data: [],
                loading: true,
            };
        case actionTypes.GET_TAG_FAILURE:
            return {
                ...state,
                error: action.error,
                data: [],
                loading: false,
            };
        case actionTypes.GET_ALL_TAG:
            return {
                ...state,
                error: null,
                data: action.data,
                loading: false,
            };
        case actionTypes.ADD_TAG:
            return {
                ...state,
                error: null,
                data: state.data.concat(action.newTag),
                loading: false
            };
        case actionTypes.GET_CATEGORY_BY_TAG:
            return {
                ...state,
                error: null,
                data: action.data,
                loading: false,
            };
        case actionTypes.GET_CATEGORY_BY_TAG_PENDING:
            return {
                ...state,
                error: null,
                data: [],
                loading: true,
            };
        case actionTypes.GET_CATEGORY_BY_TAG_FAILURE:
            return {
                ...state,
                error: action.error,
                data: [],
                loading: false,
            };
        case actionTypes.UPDATE_ADD_TAG:
            let data = state.data[0].hashTag.concat(action.newTag);
            state.data[0].hashTag = data;
            return {
                ...state,
                error: null,
                data: state.data,
                loading: false
            };
        case actionTypes.CLEAR_TAG:
            newData = [];
            activeData = [];
            return {
                ...state,
                error: null,
                data: [],
                loading: false
            };
        case actionTypes.SELECTED_TAG:
            console.log('event fire selected data...');
            for (let key in state.data[0].hashTag) {
                if (state.data[0].hashTag[key] === action.sData) {
                    state.data[0].hashTag[key].isActive = !state.data[0].hashTag[key].isActive;
                }
            }
            return {
                ...state,
                error: null,
                data: newData.concat(state.data),
                loading: false,
            };
        case actionTypes.GET_SELECTED_TAG:
            console.log('event fire selected data...');
            for (let key in state.data[0].hashTag) {
                if (state.data[0].hashTag[key].isActive === true) {
                    activeData.push(state.data[0].hashTag[key]);
                }
            }
            console.log(activeData);

            return {
                ...state,
                error: null,
                activeData: activeData,
                loading: false,
            };
        default:
            return state
    }
};

