import React from "react";
import "./styles.scss";
import "./mobile.scss";

const Index = (props) => (
  <>
    <div
      className={`info-card-main-wrapper d-flex flex-row justify-content-between align-items-center  ${props.type} `}
    >
      {props.type === "content-left" && (
        <>
            <div
                className={"icmw__body d-flex flex-column align-content-start"}
                style={{marginRight: "48px"}}
            >
                <div className={"icmw__b-title"}>{props.title}</div>
                <div className={"icmw__b-content"}>{props.content}</div>
            </div>
            <div className={"icmw__image"}>
                <img alt="creditcard offers" src={require(props.imgUrl)} />
            </div>
        </>
      )}
      {props.type === "content-right" && (
        <>
          <div className={"icmw__image_right"}>
              <img alt="creditcard offers" src={require('../../../assets/img/Who_WeAre.svg')} />
          </div>
          <div
            className={"icmw__body d-flex flex-column align-content-start"}
            style={{ marginLeft: "57px" }}
          >
            <div className={"icmw__b-title"}>{props.title}</div>
            <div className={"icmw__b-content"}>{props.content}</div>
          </div>
        </>
      )}
    </div>
  </>
);

Index.propTypes = {};

Index.defaultProps = {};

export default Index;
