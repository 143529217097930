import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import { RadioBox, Tag } from "../../index";
import moment from "moment";

const Index = (props) => (
    <>
        <div className={"recent-offer-wrapper "}>
            <div className={"recent-offer-card-wrp"}>
                <div className={"r-off-img-wrp"}>
                    <img src={props.imgUrl} alt="creditcard offers" />
                    <div className={"r-off-bank-logo"}>
                        <img
                            src={
                                "https://cco-media-v4.s3.ap-southeast-1.amazonaws.com/66328a65fb5e293fbb8b92a7.jpg"
                            }
                        />
                    </div>
                </div>
                <div className={"d-flex flex-column"}>
                    <div className={"cco__ocw__details"}>{props && props.header}</div>
                    <div className={"cco__ocw__name"}>{props && props.label}</div>
                    <div className={"cco__ocw__tags d-flex flex-row"}>
                        <Tag type={"pending"} text={props && props.status} />
                    </div>
                    <div className={"cco__ocw__time"}>
                        {moment(props && props.createdAt)
                            .startOf("day")
                            .fromNow()}
                    </div>
                </div>
            </div>

            <div className={"box-select-wrp"}>
                <RadioBox
                    text={"select"}
                    isSelect={props.isSelect}
                    onClick={props.onSelectedMerchant}
                />
            </div>
        </div>
    </>
);

Index.propTypes = {
    text: PropTypes.string.isRequired,
};

Index.defaultProps = {
    text: "",
};

export default Index;
