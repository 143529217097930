import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import "./mobile.scss";
import {
  Button,
  ButtonIcon,
  Input,
  SearchCard,
  TabBox,
} from "../../../components";
import { RiCloseLine } from "react-icons/ri";
import * as actionTypes from "../../../store/actions/types";
import Scrollbars from "react-custom-scrollbars";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabName: "bank",
      viewPointHeight: 0,
      viewPointWidth: 0,
      bankData: [],
      categoryData: [],
      recentBanks: [],
      recentCategories: [],
      dataForm: {
        bank: {
          key: "bank",
          elementType: "input",
          elementConfig: {
            type: "input",
            placeholder: "Bank name type here..",
          },
          value: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "",
        },
        category: {
          key: "category",
          elementType: "input",
          elementConfig: {
            type: "input",
            placeholder: "category name type here..",
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "",
        },
      },
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
  }

  inputHandleChange = (event, inputIdentity) => {
    const updateSignUpForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateSignUpForm[inputIdentity],
    };
    updatedFormElement.value = event.target.value;
    updatedFormElement.touched = true;
    updateSignUpForm[inputIdentity] = updatedFormElement;
    let formIsValid = true;
    for (let inputIdentifier in updateSignUpForm) {
      formIsValid = updateSignUpForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateSignUpForm, formIsValid: formIsValid });
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    this.setState((prevState) => ({
      categoryData: prevState.categoryData.map((category) => ({
        ...category,
        isActive: false, // Set isActive to false for each bank
      })),
    }));

    this.setState((prevState) => ({
      bankData: prevState.bankData.map((bank) => ({
        ...bank,
        isActive: false, // Set isActive to false for each bank
      })),
    }));
    const sessionData = sessionStorage.getItem("recentBanks");
    console.log({ sessionData });
    if (sessionData) {
      // this.setState({ recentBanks: });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.bankData !== this.props.bankData ||
      prevProps.categoryData !== this.props.categoryData
    ) {
      const sessionData = sessionStorage.getItem("recentBanks");
      // console.log({ sessionData });
      this.setState({ bankData: this.props.bankData });
      this.setState({ categoryData: this.props.categoryData });
    }

    if (
      prevProps.activeBankName !== this.props.activeBankName ||
      prevProps.activeCategoryName !== this.props.activeCategoryName
    ) {
      if (this.props.activeCategoryName?.length === 0) {
        const { categoryData } = this.state;
        this.setState((prevState) => ({
          categoryData: prevState.categoryData.map((category) => ({
            ...category,
            isActive: false, // Set isActive to false for each bank
          })),
        }));
      }
      if (this.props.activeBankName?.length === 0) {
        this.setState((prevState) => ({
          bankData: prevState.bankData.map((bank) => ({
            ...bank,
            isActive: false, // Set isActive to false for each bank
          })),
        }));
      }
    }
  }

  onClickChangeTab = (_tabName) => {
    console.log(_tabName);
    this.setState({ tabName: _tabName });
  };

  //GET OFFER BY BANK
  getOfferFilterByBankId = async (
    bankId,
    bankName,
    logoURL,
    thumbnailURL,
    isActive
  ) => {
    console.log("bankID:::" + bankId);
    this.props.setFilterBanks({
      type: "bank",
      id: bankId,
      filterName: bankName,
      logoURL: logoURL,
      thumbnailURL: thumbnailURL,
      isActive: !isActive,
    });

    this.setState({ ...this.state.bankIdArray, bankId });
    // console.log("bankIdArray :", { bankIdArray });

    // this.props.onLoadOffer();
    // this.dispatch({ type: actionTypes.CLOSE_FILTER_ITEM });

    // if (activeCategoryName) {
    //     await getOfferFilterByCategoryByBank(bankId, categoryIdList && categoryIdList[0]);
    //     return;
    // }
    // let res = await getOfferByBankId(bankId, 1, 2);
    // if (res) {
    //     this.dispatch({
    //         type: actionTypes.ON_COMPLETE_LOAD_OFFER,
    //         payload: {"data": res && res.data}
    //     });

    // }
  };

  //GET OFFER BY CATEGORY
  getOfferFilterByCategoryId = async (categoryId, categoryName, iconName) => {
    console.log("categoryID:::" + categoryId);
    this.props.setFilterCategory({
      type: "category",
      id: categoryId,
      filterName: categoryName,
      iconName: iconName,
      isActive: true,
    });

    this.setState({ ...this.state.categoryIdArray, categoryId });
    // setCategoryIdArray((arr) => [...arr, categoryIdArray]);

    // this.props.onLoadOffer();
    // this.dispatch({ type: actionTypes.CLOSE_FILTER_ITEM });

    // if (activeBankName) {
    //     await getOfferFilterByCategoryByBank(bankIdList && bankIdList[0], categoryId);
    //     return;
    // }

    // let res = await getOfferByCategoryId(categoryId, 1, 2);
    // if (res) {
    //     this.dispatch({
    //         type: actionTypes.ON_COMPLETE_LOAD_OFFER,
    //         payload: {"data": res && res.data}
    //     });

    // }
  };

  onClickSelectBank = (id) => {
    const { bankData } = this.state;
    this.setState((prevState) => ({
      bankData: prevState.bankData.map(
        (bank) =>
          bank.bankId === id ? { ...bank, isActive: !bank.isActive } : bank // Update bank data object
      ),
    }));
  };

  onClickSelectCategory = (id) => {
    const { categoryData } = this.state;
    this.setState((prevState) => ({
      categoryData: prevState.categoryData.map(
        (category) =>
          category.categoryId === id
            ? { ...category, isActive: !category.isActive }
            : category // Update bank data object
      ),
    }));
  };

  handleSetRecent = (item, type) => {
    // sessionStorage.setItem(sessionData);
    if (type === "bank") {
      if (!this.state.recentBanks.find((X) => X.id === item.id)) {
        this.setState({ recentBanks: [...this.state.recentBanks, item] });
      }
    } else {
      if (!this.state.recentCategories.find((X) => X.id === item.id)) {
        this.setState({
          recentCategories: [...this.state.recentCategories, item],
        });
      }
    }
  };

  render() {
    const {
      tabName,
      dataForm,
      viewPointHeight,
      viewPointWidth,
      categoryData,
      bankData,
      recentBanks,
      recentCategories,
    } = this.state;
    const { isFilterOpen, closeMe, recentSelectBanks, recentSelectCategories } =
      this.props;

    return (
      <>
        <section
          className={`coo__modal-main-wrapper animate__animated  ${
            isFilterOpen ? "show-me" : "hide-me"
          } `}
        >
          <div className={"cco_modal-filter-wrapper"}>
            <div className={"close-modal-filter-wrapper flex-row"}>
              <ButtonIcon
                type={"close"}
                onClick={() => closeMe()}
                size={"md"}
                buttonIcon={<RiCloseLine />}
              />
            </div>
            <div className={"cco-modal-title"}>All filters</div>
            <Scrollbars
              style={{
                height:
                  viewPointWidth <= 650
                    ? viewPointHeight - 70
                    : viewPointHeight - 150,
              }}
              thumbMinSize={30}
              universal={true}
              autoHide
            >
              <div className={"modal__filter-inner-wrp"}>
                <div className={"cco-filter-tag-wrp d-flex"}>
                  <TabBox
                    type={"tab md"}
                    remove={false}
                    isSelect={tabName === "bank"}
                    select={false}
                    format={"text"}
                    text={"Bank"}
                    onClick={() => {
                      this.onClickChangeTab("bank");
                    }}
                  />
                  <TabBox
                    type={"tab md"}
                    remove={false}
                    isSelect={tabName === "category"}
                    select={false}
                    format={"text"}
                    text={"Category"}
                    onClick={() => {
                      this.onClickChangeTab("category");
                    }}
                  />
                </div>

                <div className={"cco-filter-search-bar"}>
                  <>
                    <Input
                      elementConfig={dataForm[tabName].elementConfig}
                      elementType={dataForm[tabName].elementType}
                      required={dataForm[tabName].validation.required}
                      maxLength={dataForm[tabName].validation.maxLength}
                      invalidReason={dataForm[tabName].invalidReason}
                      isinvalid={!dataForm[tabName].valid}
                      touched={dataForm[tabName].touched}
                      value={dataForm[tabName].value}
                      size={"sm-search"}
                      verificationStatus={dataForm[tabName].valid}
                      showVerification={!!dataForm[tabName].value}
                      margin={"m-t-8"}
                      label={dataForm[tabName].label}
                      changed={(event) =>
                        this.inputHandleChange(event, dataForm[tabName].key)
                      }
                    />
                  </>
                </div>

                <div className={"cco-filter-recent-wrapper"}>
                  <div
                    className={
                      "cco-f-r-header-wrapper d-flex justify-content-between"
                    }
                  ></div>
                  {/*{recentBanks && recentCategories &&*/}
                  {/*    <div className={"cco-f-tag-wrapper mt-1 d-flex"}>*/}
                  {/*      {tabName === "bank" &&*/}
                  {/*          recentBanks &&*/}
                  {/*          recentBanks?.length > 0 && (*/}
                  {/*              <>*/}
                  {/*                <div className="d-flex flex-row ">*/}
                  {/*                  {(recentBanks || []).map((item, index) => (*/}
                  {/*                      <TabBox*/}
                  {/*                          type={"recent-tag sm mr-1"}*/}
                  {/*                          remove={true}*/}
                  {/*                          isSelect={false}*/}
                  {/*                          select={false}*/}
                  {/*                          format={"text"}*/}
                  {/*                          text={item.filterName}*/}
                  {/*                          onClick={async () => {*/}
                  {/*                            await this.getOfferFilterByBankId(*/}
                  {/*                                item.id,*/}
                  {/*                                item.filterName,*/}
                  {/*                                item.logoURL,*/}
                  {/*                                item.thumbnailURL,*/}
                  {/*                                item.isActive*/}
                  {/*                            );*/}
                  {/*                          }}*/}
                  {/*                      />*/}
                  {/*                  ))}*/}
                  {/*                </div>*/}
                  {/*              </>*/}
                  {/*          )}*/}

                  {/*      {tabName === "category" && recentCategories?.length > 0 && (*/}
                  {/*          <>*/}
                  {/*            <div className="d-flex flex-row ">*/}
                  {/*              {(recentCategories || []).map((item, index) => (*/}
                  {/*                  <TabBox*/}
                  {/*                      type={"recent-tag sm mr-1"}*/}
                  {/*                      remove={false}*/}
                  {/*                      isSelect={item.isActive}*/}
                  {/*                      select={false}*/}
                  {/*                      format={"text"}*/}
                  {/*                      text={item.filterName}*/}
                  {/*                      onClick={async () => {*/}
                  {/*                        await this.getOfferFilterByCategoryId(*/}
                  {/*                            item.id,*/}
                  {/*                            item.filterName,*/}
                  {/*                            item?.iconName*/}
                  {/*                        );*/}
                  {/*                      }}*/}
                  {/*                  />*/}
                  {/*              ))}*/}
                  {/*            </div>*/}
                  {/*          </>*/}
                  {/*      )}*/}
                  {/*    </div>*/}
                  {/*}*/}
                </div>

                {tabName === "bank" && (
                  <div
                    className={
                      "filter-information-content-wrapper d-flex flex-column mb-3"
                    }
                  >
                    {bankData
                      ?.filter((item) => {
                        if (dataForm["bank"].value === "") {
                          return item; // Corrected the return statement
                        } else if (
                          item.bankName
                            ?.toLowerCase()
                            .includes(dataForm["bank"].value.toLowerCase())
                        ) {
                          return item; // Corrected the return statement
                        }
                      })
                      .sort((a, b) => {
                        // Sort to place active items at the top
                        return b.isActive - a.isActive;
                      })
                      .map((item, index) => (
                        <SearchCard
                          type={"bank"}
                          imageType={"image"}
                          imgUrl={item.thumbnailURL}
                          remove={false}
                          isSelect={item.isActive}
                          select={false}
                          format={"select-box"}
                          text={item.bankName}
                          onClick={async () => {
                            await this.getOfferFilterByBankId(
                              item.bankId,
                              item.bankName,
                              item.logoURL,
                              item.thumbnailURL
                            );
                            this.handleSetRecent(
                              {
                                type: "bank",
                                id: item.bankId,
                                filterName: item.bankName,
                                logoURL: item.logoURL,
                                isActive: true,
                              },
                              "bank"
                            );
                            this.onClickSelectBank(item.bankId);
                          }}
                        />
                      ))}
                  </div>
                )}

                {tabName === "category" && (
                  <div
                    className={
                      "filter-information-content-wrapper d-flex flex-column mb-3"
                    }
                  >
                    {categoryData
                      ?.filter((item) => {
                        if (dataForm["category"].value === "") {
                          return item; // Corrected the return statement
                        } else if (
                          item?.categoryName
                            ?.toLowerCase()
                            .includes(dataForm["category"].value.toLowerCase())
                        ) {
                          return item; // Corrected the return statement
                        }
                      })
                      .sort((a, b) => {
                        // Sort to place active items at the top
                        return b.isActive - a.isActive;
                      })
                      .map((item, index) => (
                        <SearchCard
                          type={"bank"}
                          imageType={"icon"}
                          iconName={item?.iconName}
                          remove={false}
                          isSelect={item.isActive}
                          select={false}
                          format={"select-box"}
                          text={item?.categoryName}
                          onClick={async () => {
                            await this.getOfferFilterByCategoryId(
                              item.categoryId,
                              item.categoryName,
                              item?.iconName
                            );
                            this.handleSetRecent(
                              {
                                type: "category",
                                id: item.categoryId,
                                filterName: item.categoryName,
                                iconName: item?.iconName,
                                isActive: true,
                              },
                              "category"
                            );
                            this.onClickSelectCategory(item.categoryId);
                          }}
                        />
                      ))}
                  </div>
                )}
              </div>
            </Scrollbars>
            <div
              className={
                "filter-action d-flex flex-row align-items-center justify-content-end"
              }
            >
              <div></div>
              <div
                className={
                  "d-flex flex-row align-items-center justify-content-between"
                }
              >
                <Button
                  size={"md-x mr-2"}
                  type={"light"}
                  reactIcon={""}
                  text={"Clear all"}
                  isLoading={false}
                  rightIcon={false}
                  leftIcon={false}
                  onClick={() => {
                    this.props.clearFilters();
                    closeMe();
                  }}
                />
                <Button
                  size={"md-x"}
                  type={"dark"}
                  reactIcon={""}
                  text={"View results"}
                  isLoading={false}
                  rightIcon={false}
                  leftIcon={false}
                  onClick={() => closeMe()}
                />
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isOfferView: state.form.isOfferView,
    offerData: state.form.offerData,
    categoryData: state.category.data,
    isFilterOpen: state.form.isFilterOpen,
    bankData: state.bank.bankData,
    isLogin: state.user.isLogin,
    activeBankName: state.offer.activeBankName,
    activeCategoryName: state.offer.activeCategoryName,
    recentSelectBanks: state.offer.recentSelectBanks,
    recentSelectCategories: state.offer.recentSelectCategories,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeMe: (payload) =>
      dispatch({
        type: actionTypes.FILTER_CLOSE,
      }),
    setFilterBanks: (payload) =>
      dispatch({
        type: actionTypes.SET_FILTER_OPTION_BANK,
        payload: payload,
      }),
    setFilterCategory: (payload) =>
      dispatch({
        type: actionTypes.SET_FILTER_OPTION_CATEGORY,
        payload: payload,
      }),
    clearFilters: () =>
      dispatch({
        type: actionTypes.CLEAR_ALL_FILTER,
      }),
    onLoadOffer: () =>
      dispatch({
        type: actionTypes.ON_LOAD_OFFER,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
