import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import {GetIcon} from "../../../config/icon";

const Index = (props) => (
  <>
    <div
      className={`filter-menu-card d-flex justify-content-center ${
        props.type
      } ${props.isActive ? "active" : ""}`}
      onClick={props.onClick}
    >
        <div className={"inner-menu-wrp d-flex align-items-center"}>
            {props.iconLeft && (
                <div className={"fmc-icon-wrp d-flex align-items-center"}>
                    {props.iconLeft}
                </div>
            )}
            {props.isText && (
                <div className={`text filter_menu-text`}>{props.text}</div>
            )}
            {props.isActiveCount && (
                <div className={"filter-counter"}>
                    <div className={"activate-filter-count"}>
                        <div className={"text-count"}>{props.count}</div>
                    </div>
                </div>
            )}

            {props.imageType === "bank" && (
                <div className={`filter-logo-wrapper  ${props.imageType}`}>
                    <img src={props.imgUrl} alt="creditcard offers"/>
                </div>
        )}
        {props.imageType === "category" && (
            <div className={`filter-logo-wrapper ${props.isActive ? "active" : ""}
             ${props.imageType}`}>
                {GetIcon(props.iconName)}
            </div>
        )}

        {props.filterList?.length > 0 && (
          <div
            className={`d-flex flex-column ${
              props.filterList?.length > 1 ? "flip5" : ""
            } `}
          >
            <div className={"filter-text-wrapper"}>
              {(props.filterList || []).map((item, index) => (
                <div
                  className={`text ${
                    props.filterList?.length > 1 ? "filter-text" : ""
                  }`}
                >
                  {item}
                </div>
              ))}
            </div>
          </div>
        )}
        {props.filterList?.length === 0 && (
          <div className={`text filter_menu-text`}>{props.text}</div>
        )}
      </div>

      {props.icon && (
        <div className={"arrow d-flex align-items-center"}>{props.icon}</div>
      )}
      {props.isRadioBtn && (
        <>
          <div
            className={`select-radio-box-wrp 
                ${props.isActive ? "active" : ""}`}
          >
              {GetIcon("close")}
          </div>
        </>
      )}

      {props.isRemove && <></>}
    </div>
  </>
);

Index.propTypes = {
  text: PropTypes.string.isRequired,
};

Index.defaultProps = {
  text: "text not found",
  icon: "<RiCloseFill/>",
};

export default Index;
