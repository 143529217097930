import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import "./styles.scss";
import "./mobile.scss";
import {GetIcon} from "../../../config/icon";
import {ButtonIcon, FilterMenu, OfferFilterItem} from "../../../components";
import * as actionTypes from "../../../store/actions/types";
import {getOfferByPage, getOfferCount} from "../../../service";
import {RiArrowLeftSLine, RiArrowRightSLine} from "react-icons/ri";
import {Scrollbars} from "react-custom-scrollbars";

const Index = (props) => {
  const [viewPointWidth, setViewPointWidth] = useState(0);
  const [viewPointHeight, setViewPointHeight] = useState(0);
  const [bankData, setBankData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [activeCategory, setActiveCategory] = useState([]);
  const [activeList, setActiveList] = useState([]);
  const activeMenuType = useSelector((state) => state.offer.type);
  const offerData = useSelector((state) => state.offer.offerData);
  const isActiveFilter = useSelector((state) => state.offer.isActiveFilter);
  const activeId = useSelector((state) => state.offer.activeId);
  const activeBankName = useSelector((state) => state.offer.activeBankName);
  const activeCategoryName = useSelector(
      (state) => state.offer.activeCategoryName
  );
  const bankIdList = useSelector((state) => state.offer.bankIdList);
  const categoryIdList = useSelector((state) => state.offer.categoryIdList);

  // start scrollbar
  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions, true);
    window.addEventListener("scroll", handleOnScroll, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      window.removeEventListener("scroll", handleOnScroll, true);
      window.removeEventListener("resize", updateWindowDimensions, true);
    };
  }, []);

  const handleOnScroll = () => {
    let scrollTop =
        (document.documentElement && document.documentElement.scrollTop) ||
        document.body.scrollTop;
    let scrollHeight =
        (document.documentElement && document.documentElement.scrollHeight) ||
        document.body.scrollHeight;
    let clientHeight =
        document.documentElement.clientHeight || window.innerHeight;
  };

  const updateWindowDimensions = () => {
    setViewPointWidth(window.innerWidth);
    setViewPointHeight(window.innerHeight);
  };

  const dispatch = useDispatch();
  const getOffer = async () => {
    dispatch({type: actionTypes.CLEAR_ALL_FILTER});
    dispatch({type: actionTypes.ON_LOAD_OFFER});
    let updateBankData = [...bankData];
    let updateCategoryData = [...categoryData];
    for (let idx = 0; idx < bankData.length; idx++) {
      // updateBankData[idx].isActive = false;
    }
    for (let x = 0; x < categoryData.length; x++) {
      // updateCategoryData[x].isActive = false;
    }
    setBankData(updateBankData);
    setCategoryData(updateCategoryData);
    // if (offerData?.length === 0) {
    let res = await getOfferByPage(1, 10);
    let resCount = await getOfferCount("accepted");
    if (res) {
      console.log("res", res);
      dispatch({
        type: actionTypes.ON_COMPLETE_LOAD_OFFER,
        payload: {
          data: res && res.data,
          totalCount: resCount?.data[0]?.totalCount,
          pageNo: 1,
        },
      });
      // }
    }
  };

  useEffect(() => {
    if (bankData?.length > 0 || categoryData?.length > 0) {
      setBankData(bankData);
      setCategoryData(categoryData);
    }
  }, [bankData, categoryData]);

  useEffect(() => {
    if (activeBankName?.length !== 0 || activeCategoryName?.length !== 0) {
      setActiveList([...activeBankName, ...activeCategoryName]);
    }
  }, [activeBankName, activeCategoryName]);

  const settings = {
    variableWidth: true,
    className: "slider variable-width",
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: (
      <div>
        <ButtonIcon
          buttonIcon={<RiArrowRightSLine />}
          type={"round"}
          size={"md"}
        />
      </div>
    ),
    prevArrow: (
      <div>
        <ButtonIcon
          buttonIcon={<RiArrowLeftSLine />}
          type={"round"}
          size={"md"}
        />
      </div>
    ),
  };

  return (
    <>
      <div
        className={`console__search-wrapper ${
          props.isScrollTop ? "fix-top active-shadow" : ""
        }`}
      >
        <div className={"container"}>
          <div className={"d-flex"}>
            <div className={"mr-1"}>
              <FilterMenu
                type={"filter"}
                isText={true}
                isActiveCount={
                  bankIdList?.length > 0 || categoryIdList?.length > 0
                }
                count={bankIdList?.length + categoryIdList?.length}
                isActive={!!activeBankName?.length || !!categoryIdList?.length}
                onClick={() =>
                    dispatch({
                      type: actionTypes.FILTER_OPEN,
                    })
                }
                iconLeft={GetIcon("filter")}
                icon={false}
                text={"Filter"}
              />
            </div>

            <Scrollbars
                onScroll={handleOnScroll}
                renderView={(props) => (
                    <div {...props} style={{...props.style, overflowY: "hidden"}}/>
                )}
                thumbMinSize={5}
                style={{width: viewPointWidth - 10, minHeight: 42}}
            >
              <div className={"mr-1"}>
                {activeList?.length > 0 && (
                    <>
                      <div className="d-flex flex-row">
                        {activeList.length > 4 ? (<>
                          {(activeList || []).map((item, index) => (
                              <FilterMenu
                                  type={"view-tag ml-1"}
                                  imageType={null}
                                  imgUrl={item.logoURL}
                                  iconName={item.iconName}
                                  isActiveCount={false}
                                  count={activeList?.length}
                                  isActive={item.isActive}
                                  isRemove={true}
                                  isRadioBtn={true}
                                  onClick={() => {
                                    if (item.type === "bank") {
                                      dispatch({
                                        type: actionTypes.SET_FILTER_OPTION_BANK,
                                        payload: {
                                          type: "bank",
                                          id: item.id,
                                          filterName: item.filterName,
                                          logoURL: item.logoURL,
                                          isActive: false,
                                        },
                                      });
                                    } else {
                                      dispatch({
                                        type: actionTypes.SET_FILTER_OPTION_CATEGORY,
                                        payload: {
                                          type: "category",
                                          id: item.id,
                                          filterName: item.filterName,
                                          iconName: item.iconName,
                                          isActive: false,
                                        },
                                      });
                                    }
                                    setActiveList(
                                        [...activeList].map((bank) =>
                                            bank.bankId === activeList[index]?.id
                                                ? {...bank, isActive: !bank.isActive}
                                                : bank
                                        )
                                    );
                                  }}
                                  icon={false}
                                  text={item.filterName}
                                  filterList={[]}
                              />
                          ))}
                        </>) : (
                            <>
                              {(activeList || []).map((item, index) => (
                                  <FilterMenu
                                      type={"view-tag ml-1"}
                                      imageType={null}
                                      imgUrl={item.logoURL}
                                      iconName={item.iconName}
                                      isActiveCount={false}
                                      count={activeList?.length}
                                      isActive={item.isActive}
                                      isRemove={true}
                                      isRadioBtn={true}
                                      onClick={() => {
                                        if (item.type === "bank") {
                                          dispatch({
                                            type: actionTypes.SET_FILTER_OPTION_BANK,
                                            payload: {
                                              type: "bank",
                                              id: item.id,
                                              filterName: item.filterName,
                                              logoURL: item.logoURL,
                                              isActive: false,
                                            },
                                          });
                                        } else {
                                          dispatch({
                                            type: actionTypes.SET_FILTER_OPTION_CATEGORY,
                                            payload: {
                                              type: "category",
                                              id: item.id,
                                              filterName: item.filterName,
                                              iconName: item.iconName,
                                              isActive: false,
                                            },
                                          });
                                        }
                                        setActiveList(
                                            [...activeList].map((bank) =>
                                                bank.bankId === activeList[index]?.id
                                                    ? {...bank, isActive: !bank.isActive}
                                                    : bank
                                            )
                                        );
                                      }}
                                      icon={false}
                                      text={item.filterName}
                                      filterList={[]}
                                  />
                              ))}
                            </>
                        )}
                      </div>
                    </>
                )}
              </div>
            </Scrollbars>

          </div>
        </div>

        <OfferFilterItem
          bankData={props.bankData?.length > 0 && bankData}
          categoryData={props.categoryData?.length > 0 && categoryData}
        />
      </div>
    </>
  );
};

Index.propTypes = {};

Index.defaultProps = {
  text: "text not found",
  icon: "<RiCloseFill/>",
};

export default Index;
