import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import {GetIcon} from "../../../config/icon";
import {Dropdown} from "antd";

const Index = (props) => {
  const [viewPointWidth, setViewPointWidth] = useState(0);
  const [viewPointHeight, setViewPointHeight] = useState(0);

  // scrollbars start
  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);
    window.addEventListener("scroll", handleOnScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      window.removeEventListener("scroll", handleOnScroll);
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, []);

  const updateWindowDimensions = () => {
    setViewPointWidth(window.innerWidth);
    setViewPointHeight(window.innerHeight);
  };

  const handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
  };
  // scrollbars end

  return (
    <>
      <div
        className={`filter-card d-flex justify-content-center ${props.type} ${
          props.isActive ? "active" : ""
        } ${props.isSelect}`}
        onClick={props.onClick}
      >
        <div className={"inner-wrp d-flex align-items-center"}>
          {props.type === "filter" && (
            <>
              {props.iconLeft && (
                <div className={"fc-icon-wrp d-flex align-items-center"}>
                  {props.iconLeft}
                </div>
              )}
            </>
          )}
          {props.isText && (
            <div
              className={`filter-text`}
              style={{ fontSize: viewPointWidth < 480 ? "11px" : "12px" }}
            >
              {props.text}
            </div>
          )}
          {props.count > 1 && (
            <div
              className={"fc-count d-flex flex-row"}
              style={{ fontSize: viewPointWidth < 480 ? "11px" : "12px" }}
            >
              <div>+1 more</div>
            </div>
          )}
        </div>

        {props.type === "view-card" && (
          <>
            {props.isActive && (
              <>
                {props.isRadioBtn && (
                  <>
                    <div
                      className={`select-radio-box-wrp ml-2 ${
                        props.isActive ? "active" : ""
                      }`}
                    >
                      {GetIcon("close")}
                    </div>
                  </>
                )}
                {props.isRemove && <></>}
              </>
            )}
          </>
        )}
        {props.type === "list-card" && (
          <>
            {/* {props.isActive ? (
              <> */}
            {props.isRadioBtn && (
              <>
                <div
                  className={`select-radio-box-wrp ml-2 ${
                    props.isActive ? "active" : ""
                  }`}
                >
                  {GetIcon("close")}
                </div>
              </>
            )}
            {props.isRemove && <></>}
            {/* </>
            ) : (
              <> */}
            {viewPointWidth > 480 && (
              <Dropdown overlay={props.filterList}>
                <div className={`filter-arrow d-flex align-items-center`}>
                  {GetIcon("chevronDown")}
                </div>
              </Dropdown>
            )}
            {/* </>
            )} */}
          </>
        )}
        {props.type === "filter" && (
          <>
            {props.isRadioBtn && (
              <>
                <div
                    className={`select-radio-box-wrp ml-2 
                ${props.isActive ? "active" : ""}`}
                    onClick={props.onClickClear}
                >
                  {GetIcon("close")}
                </div>
              </>
            )}
            {props.isRemove && <></>}
            {props.noActiveFilter > 0 && <>
              <div className={'filter-count-wrp ml-1'}>
                <div className={'fc-text'}>
                  {props.noActiveFilter}
                </div>
              </div>
            </>}
          </>
        )}
      </div>
    </>
  );
};

Index.propTypes = {
  text: PropTypes.string.isRequired,
};

Index.defaultProps = {
  text: "text not found",
  icon: "<RiCloseFill/>",
};

export default Index;
