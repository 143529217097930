import React from "react";
import { Store } from "react-notifications-component";

// success
// danger
// info
// default
// warning
/*  title: "Wonderful!",
    message: "Configurable",
    type: "success",
    insert: "top",
    container: "top-right",
    animationIn: ["animate__animated animate__fadeIn"], // `animate.css v4` classes
    animationOut: ["animate__animated animate__fadeOut"] // `animate.css v4` classes*/
export const openNotification = (props) => {
  Store.addNotification({
    title: props.title,
    message: props.message,
    type: props.type,
    showIcon:true,
    container:"bottom-left", //props.container,
    animationIn: ["animate__animated animate__fadeInUp"], // `animate.css v4` classes
    animationOut: ["animate__animated animate__fadeOutDown"], // `animate.css v4` classes*/
    isMobile: true,
    dismiss: {
      duration: 10000,
      onScreen: true,
    },
  });
};
