import React from 'react';
import PropTypes from 'prop-types';
import './style.scss'

import {
    RiCheckLine
} from "react-icons/ri";

const Index = (props) => (
    <>
        <div className={`wizard-no-wrapper ${props.userType}  ${props.done ?
            'im-done' : props.isFocusTab ? 'active-me' : ''}`}>
            <div className={'d-flex flex-row'}>
                <div className={'out-side-circle'}>
                    <div className={'inside-circle'}>
                        {!props.done && <>{props.formIcon}</>}
                        {props.done && <RiCheckLine/>}
                    </div>
                </div>

                <div className={'text-wrapper'}>
                    <div className={'step'}>
                        {props.step}
                    </div>
                    <div className={'current-form-name'}>
                        {props.formName}
                    </div>
                </div>
            </div>
        </div>
    </>
);

Index.propTypes = {};

Index.defaultProps = {};

export default Index;
