import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import "./mobile.scss";
import { Button } from "../../index";

//tag types 'error/processing/
const Index = (props) => (
  <>
    <div className={`information-box-wrapper ${props.tag} `}>
      <div className={"container"}>
        <div className={"d-flex justify-content-between"}>
          <div className={"d-flex"}>
            <div className={`ib-icon-wrapper ${props.tag}`}>{props.icon}</div>
            <div className={"ml-3"}>
              <div className={`ib-text-des d-flex flex-column ${props.tag}`}>
                {props.message}
              </div>
              <div className={`tag-error ${props.tag}`} onClick={props.onClick}>
                <div className={"tag-txt"}>{props.title}</div>
              </div>
            </div>
          </div>
          {/*<div className={'button-link-wrapper'}>*/}
          {/*    <Button text={'Resend Link'}*/}
          {/*            type={'light'}*/}
          {/*            size={'sm'}*/}
          {/*            leftIcon={true}*/}
          {/*            rightIcon={false}*/}
          {/*        // onClick={() => this.onClickGoBack(wizardNo - 1)}*/}
          {/*            reactIcon={props.buttonIcon}/>*/}
          {/*</div>*/}
        </div>
      </div>
    </div>
  </>
);

Index.propTypes = {};

Index.defaultProps = {
  text: "text not found",
  icon: "<RiCloseFill/>",
};

export default Index;
