export const getMonthString = monthNumber => {
    let month = [];
    month[0] = "January";
    month[1] = "February";
    month[2] = "March";
    month[3] = "April";
    month[4] = "May";
    month[5] = "June";
    month[6] = "July";
    month[7] = "August";
    month[8] = "September";
    month[9] = "October";
    month[10] = "November";
    month[11] = "December";
    return month[monthNumber];
}

export const getMonthShortString = monthNumber => {
    let month = [];
    month[0] = "JAN";
    month[1] = "FEB";
    month[2] = "MAR";
    month[3] = "APR";
    month[4] = "MAY";
    month[5] = "JUN";
    month[6] = "JUL";
    month[7] = "AUG";
    month[8] = "SEP";
    month[9] = "OCT";
    month[10] = "NOV";
    month[11] = "DEC";
    return month[monthNumber];
}

export const getShortStringToMonth = monthStr => {
    let month = {
        "JAN": "01",
        "FEB": "02",
        "MAR": "03",
        "APR": "04",
        "MAY": "05",
        "JUN": "06",
        "JUL": "07",
        "AUG": "08",
        "SEP": "09",
        "OCT": "10",
        "NOV": "11",
        "DEC": "12"
    };
    return month[monthStr.toUpperCase()];
}


export const getMaskedPhoneNumber = (input) => {
    if (!input)
        return "";
    input = input.trim();
    if (!input.startsWith("+")) {
        input = `+94 ${input}`;
    }
    let inputArr = input.split("");
    if (inputArr.length >= 10) {
        for (let x = 0; x < inputArr.length; x++) {
            if (x > 3 && x < 10) {
                inputArr[x] = "*";
            }
        }
        return inputArr.join("");
    }

    return input;

}
