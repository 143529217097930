import React, {useEffect, useState} from 'react';
import "./styles.scss";
import PropTypes from 'prop-types'


function OtpInput(props) {
    const {numberOfInputs, timeInterval, displayCountdown, onCountdownFinish, onOtpEnter} = props;
    //const [isComplete, setIsComplete] = useState(false);
    const [focusIndex, setFocusIndex] = useState(0);
    const [otpValueArray, setOtpValueArray] = useState(new Array(numberOfInputs));
    const [seconds, setSeconds] = useState(timeInterval);
    const [isCountdownActive, setIsCountdownActive] = useState(displayCountdown);


    useEffect(() => {
        let interval = null;
        if (isCountdownActive && seconds !== 0) {
            interval = setInterval(() => {
                setSeconds(seconds => seconds - 1);
            }, 1000);
        } else {
            if (isCountdownActive && seconds === 0) {
                setIsCountdownActive(false);
                onCountdownFinish();
            }
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [isCountdownActive, seconds]);

    useEffect(() => {
        if (displayCountdown && seconds === 0) {
            setIsCountdownActive(true);
            setSeconds(timeInterval);
        }
    }, [displayCountdown])


    return (
        <div>
            {/*<div className={'verification-code-wrapper d-flex'}>*/}
            {/*    {getInputArray()}*/}
            {/*</div>*/}
            {isCountdownActive && <>
                <div>00:{`${seconds}`}</div>
            </>
            }
        </div>
    )
};

OtpInput.propTypes = {
    numberOfInputs: PropTypes.number.isRequired,
    onOtpEnter: PropTypes.func.isRequired,
    displayCountdown: PropTypes.bool.isRequired,
    timeInterval: PropTypes.number,
    onCountdownFinish: PropTypes.func,
    countdownText: PropTypes.string,
}

export default OtpInput

