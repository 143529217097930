import React from "react";
import "./styles.scss";
import { OfferCardFooterItem, OfferInfoCard } from "../../../components";

import {
  RiCalendar2Line,
  RiEarthLine,
  RiHeadphoneLine,
  RiMapPinLine,
} from "react-icons/ri";

const Index = (props) => (
  <>
    <div
      className={`offer-shimmer-card-wrapper flex-column animate__animated ${props.animate}`}
      onClick={props.onClick}
    >
      {/*merchant-shimmer-header*/}
      <>
        <div
          className={
            "merchant-shimmer-header d-flex flex-row justify-content-start align-items-center"
          }
        >
          <div className={"merchant-shimmer-logo-wrp shimmerBG"}></div>
          <div className={"merchant-shimmer-header-wra d-flex flex-column"}>
            <div className={"msh-title shimmerBG "}></div>
            <div className={"msh-header shimmerBG "}></div>
          </div>
        </div>

        <div className={"offer-shimmer-content-wrapper  d-flex"}>
          <div className={"offer-shimmer-banner shimmerBG"}></div>
          <div className={"offer-shimmer-info-wrapper d-flex flex-column"}>
            <div
              className={
                "d-flex flex-row justify-content-start align-items-center"
              }
              style={{ marginBottom: "16px" }}
            >
              <div className={"osiw__icon shimmerBG"}></div>
              <div className={" d-flex flex-column"}>
                <div className={"osiw__title shimmerBG "}></div>
                <div className={"osiw__subtitle shimmerBG "}></div>
              </div>
            </div>
            <div
              className={
                " d-flex flex-row justify-content-start align-items-center"
              }
              style={{ marginBottom: "16px" }}
            >
              <div className={"osiw__icon shimmerBG"}></div>
              <div className={" d-flex flex-column"}>
                <div className={"osiw__title shimmerBG "}></div>
                <div className={"osiw__subtitle shimmerBG "}></div>
              </div>
            </div>
            <div
              className={
                " d-flex flex-row justify-content-start align-items-center"
              }
              style={{ marginBottom: "16px" }}
            >
              <div className={"osiw__icon shimmerBG"}></div>
              <div className={" d-flex flex-column"}>
                <div className={"osiw__title shimmerBG "}></div>
                <div className={"osiw__subtitle shimmerBG "}></div>
              </div>
            </div>
            <div
              className={
                " d-flex flex-row justify-content-start align-items-center"
              }
              style={{ marginBottom: "16px" }}
            >
              <div className={"osiw__icon shimmerBG"}></div>
              <div className={" d-flex flex-column"}>
                <div className={"osiw__title shimmerBG "}></div>
                <div className={"osiw__subtitle shimmerBG "}></div>
              </div>
            </div>
          </div>
        </div>
      </>

      {/******* offer card footer wrapper ***********/}
      <div
        className={"offer-shimmer-card-footer-wrapper d-flex"}
        style={{ gap: "9px" }}
      >
        <div className={"oscfw__category shimmerBG"}> </div>
        <div className={"oscfw__bank shimmerBG"}> </div>
        <div className={"oscfw__view shimmerBG"}> </div>
      </div>
    </div>
  </>
);

Index.propTypes = {};

Index.defaultProps = {};

export default Index;
