import * as actionTypes from "../actions/types";
import produce from "immer";

const initialState = {
  isMobileSignIn: false,
};

let newData = [];
export default produce((draft = initialState, action) => {
  const { type, payload } = action;
  console.log("mobileSignInReducer", payload);
  switch (type) {
    case actionTypes.OPEN_MOBILE_SIGN_IN_VIEW:
      draft.isMobileSignIn = true;
      return draft;
    case actionTypes.CLOSE_MOBILE_SIGN_IN_VIEW:
      draft.isMobileSignIn = false;
      return draft;
    default:
      return draft;
  }
});
