import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss'
import {Button} from "../../index";

import {
    RiEdit2Line
} from "react-icons/ri";

const Index = (props) => (
    <>
        <div className={'up-console-title-wrapper  d-flex'}>
            <div className={'title-name'}>
                {props.title}
            </div>
            <div className={'up-console-action-wrapper'}>
                {!props.isEnableEdit &&
                <Button text={'Edit'}
                        type={'light'}
                        size={'sm'}
                        onClick={props.onClickEnableEditMode}
                    //isLoading={isLoading}
                        leftIcon={true}
                        rightIcon={false}
                        reactIcon={<RiEdit2Line/>}/>
                }

                {props.isEnableEdit &&
                <Button text={'Update'}
                        type={'light'}
                        size={'sm'}
                        onClick={props.onClickUpdate}
                    //isLoading={isLoading}
                        leftIcon={true}
                        rightIcon={false}
                        reactIcon={<RiEdit2Line/>}/>
                }
            </div>

        </div>
    </>
);

Index.propTypes = {};

Index.defaultProps = {};

export default Index;
