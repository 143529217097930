import React from "react";
import "./styles.scss";
import {
  RiFacebookFill,
  RiLinkedinBoxFill,
  RiTwitterFill,
} from "react-icons/ri";

const Index = (props) => (
  <>
    <div className={"cbw__footer"}>
      <div className={" cbw__f-titles d-flex flex-row justify-content-between"}>
        <div className={"cbw__ft-title"}>{"Terms"}</div>
        <div className={"cbw__ft-title"}>{"Privacy"}</div>
        <div className={"cbw__ft-title"}>{"About Us"}</div>
        <div className={"cbw__ft-title"}>{"Contact Us"}</div>
        <div className={"cbw__ft-title"}>{"How is Works"}</div>
        <div className={"cbw__ft-title"}>{"Help Center"}</div>
        <div className={"cbw__ft-title"}>{"Community Guidlines"}</div>
      </div>
      <div
        className={"d-flex flex-row justify-content-center"}
        style={{ gap: "11px" }}
      >
        <div className={"cbw__f-logo"}>
          <RiLinkedinBoxFill />
        </div>
        <div className={"cbw__f-logo"}>
          <RiTwitterFill />
        </div>
        <div className={"cbw__f-logo"}>
          <RiFacebookFill />
        </div>
      </div>

      <div className={"cbw__f-companyname"}>
        <img
          src={require("../../../assets/img/logo/creditcardoffers.lk.svg")}
          alt="credit card offers"
        />
      </div>
      <div className={"cbw__f-2021"}>{"2024 creditcardoffers.lk"}</div>
    </div>
  </>
);

Index.propTypes = {};

Index.defaultProps = {
  type: "light",
  size: "sm",
  icon: "<RiCloseFill/>",
};

export default Index;
