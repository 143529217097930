import React, {Component} from "react";
import "./styles.scss";
import "./mobile.scss";

import {ButtonIcon, MainHeader, OfferCardFooterItem, OfferDetailCard, PreLoader,} from "../../components";
import {connect} from "react-redux";
import {RiArrowLeftSLine, RiArrowRightSLine, RiGlobalLine, RiPhoneLine,} from "react-icons/ri";
import {createNewImpression, getOfferByOfferId, getOfferInsights,} from "../../service";
import * as actionTypes from "../../store/actions/types";
import {getGeoLocation, getInsight, getIPAddress, getLocationName, userDetails,} from "../../utils";
import {withRouter} from "react-router-dom/cjs/react-router-dom";
import {Menu} from "antd";
import {getFavoriteOfferByUser, getLoginUserDetailsById,} from "../../service/userApi";
import Footer from "../Footer";
import {Helmet} from "react-helmet";

class ViewOffer extends Component {
  constructor(props) {
    super(props);
    //  const user = userDetails();
    // console.log(user);
    let _this = this;
    this.state = {
      isLoading: false,
      dataSource: null,
      bankData: null,
      categoryData: null,
      merchantData: null,
      sessionUser: null,
      isMobileVerification: false,
      userFavoritesList: [],
      settings: {
        variableWidth: true,
        className: "slider variable-width",
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,

        nextArrow: (
          <div>
            <ButtonIcon
              buttonIcon={<RiArrowRightSLine />}
              type={"round"}
              size={"md"}
            />
          </div>
        ),
        prevArrow: (
          <div>
            <ButtonIcon
              buttonIcon={<RiArrowLeftSLine />}
              type={"round"}
              size={"md"}
            />
          </div>
        ),
        insightsData: null,
      },
    };
  }
  async fetchOfferData() {
    // Fix method name
    let param = window.location.pathname;
    param = param.split("/");
    console.log({ param });
    console.log(param);

    if (param && param[2]) {
      let offerId = param[2];
      console.log({ offerId });
      let res = await getOfferByOfferId(offerId);
      if (res && res.status) {
        let offerData = res.data[0];
        let bank = offerData.bank;
        let category = offerData.category;
        let merchant = offerData.merchant;
        console.log({ offerData });

        console.log("getOfferInsights", res);
        this.setState({
          isLoading: false,
          dataSource: offerData,
          bankData: bank,
          categoryData: category,
          merchantData: merchant,
        });

        //get more Insights
        let insightsRes = await getOfferInsights(
          offerData?.merchant?.merchantId,
          offerData?.bank?.bankId,
          offerData?.category?.categoryId,
          9
        );

        console.log("insightsRes", insightsRes);
        this.setState({
          insightsData: insightsRes?.data,
        });

        await this.creteImpression(
          offerId,
          bank?.bankId,
          category?.categoryId,
          merchant?.merchantId
        );

        // this.props.onClickViewMoreOfferDetails(
        //     offerData,
        //     category,
        //     bank,
        //     merchant
        // );
      }
    } else {
      console.error("Invalid path or parameter not found");
    }
  }

  async creteImpression(offerId, bankId, merchantId, categoryID) {
    try {
      // Get the geolocation
      const position = await getGeoLocation();
      const { latitude, longitude } = position?.coords;
      // Wait for all promises to resolve
      const [location, ip] = await Promise.all([
        getLocationName(latitude, longitude),
        getIPAddress(),
      ]);
      //let state = userDetails();
      //create impression
      const insight = await getInsight();
      console.log("Insight:", insight);
      let impressionRes = await createNewImpression({
        merchantId: merchantId,
        offerId: offerId,
        categoryId: categoryID,
        bankId: bankId,
        latitude: position?.coords?.latitude,
        longitude: position?.coords?.longitude,
        location: location,
        ip: ip,
        user: this.state.sessionUser?.userId,
        insightId: insight?.insightId,
      });

      // props.setUserInsight({
      //   data: insightBody
      // });
    } catch (error) {
      console.error("Error:", error);
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    // Check if the state has changed
    if (prevProps.location.pathname !== this.props.location.pathname) {
      if (this.props.location.pathname) {
        await this.fetchOfferData();
      }
    }
  }

  async componentDidMount() {
    document.querySelector("body").scrollTo(0, 0);
    console.log("merchant profile loading....");
    this.setState({ isLoading: true });

    let sessionUser = userDetails();
    if (sessionUser?.userId) {
      console.log("sessionUser", sessionUser);
      this.setState({ sessionUser: sessionUser });
      this.props.onAddLoginUser(sessionUser);
      let favoriteData = await getFavoriteOfferByUser(sessionUser?.userId);
      if (favoriteData?.status) {
        console.log("favoriteData", favoriteData);
        this.setState({
          isMobileVerification: favoriteData?.data?.isMobileVerification,
          userFavoritesList: favoriteData?.data?.favorites,
        });
      }
    }
    const { location } = this.props;

    await this.fetchOfferData(); // Call the corrected method name
  }

  handleNavigate = () => {
    this.props.history.push("/");
  };

  render() {
    const {
      dataSource,
      bankData,
      categoryData,
      merchantData,
      settings,
      insightsData,
      sessionUser,
      isMobileVerification,
    } = this.state;
    const profileMenu = (
      <Menu>
        <Menu.Item>
          <a target="_blank" rel="noopener noreferrer">
            Setting
          </a>
        </Menu.Item>
        <Menu.Item>
          <a
            rel="noopener noreferrer"
            onClick={() => this.onClickGoToPage("profile")}
          >
            Portal
          </a>
        </Menu.Item>
        <Menu.Item>
          <a onClick={() => this.onClickLogOut()} rel="noopener noreferrer">
            Logout
          </a>
        </Menu.Item>
      </Menu>
    );
    const { offerData, isLoading, selectedOffer, logInUser } = this.props;
    return (
        <>
          {/*create new event*/}
          <Helmet>
            <title>
              {`${bankData?.bankName || "Best"} Credit Card Offers | ${dataSource?.header || "Top Deals"} | ${categoryData?.categoryName || "Sri Lanka"}`}
            </title>
            <meta name="description"
                  content={`Discover the best ${bankData?.bankName || "credit card"} offers and promotions in Sri Lanka. Save money with the latest ${categoryData?.categoryName || "deals"} from top banks like ${bankData?.bankName || "leading banks"} at CreditCardOffers.lk.`}/>
            <link rel="canonical"
                  href={`https://www.creditcardoffers.lk/${dataSource?.offerId}/${bankData?.bankName?.toLowerCase()}/${categoryData?.categoryName?.toLowerCase()}`}/>
          </Helmet>
          <MainHeader
              logInUser={sessionUser}
              history={this.props.history}
              privateUser={require("../../assets/img/default-avatar.jpg")}
              profileMenu={profileMenu}
              onClickOpenNewOffer={() => this.props.onClickOpenNewOffer()}
              isLogin={!!sessionUser}
              onClickSignUp={() => this.props.onClickOpenSignUp()}
              onClickLogOut={() => this.onClickLogOut()}
              onClickSignIn={() => this.props.onClickOpenSignIn()}
          />
          {/*{!isMobileVerification && (*/}
          {/*  <InfomationBox*/}
          {/*    type={"full-width"}*/}
          {/*    tag={"verification"}*/}
          {/*    title={"Business Verification Review"}*/}
          {/*    message={*/}
          {/*      "Your profile has been submitted & will be reviewed by our team."*/}
          {/*    }*/}
          {/*    icon={<RiHomeGearLine />}*/}
          {/*    buttonIcon={<RiLinksLine />}*/}
          {/*    messageType={"businessVerification"}*/}
          {/*    onClick={async () => {*/}
          {/*      if (logInUser && logInUser.userId) {*/}
          {/*        const token = getToken();*/}

          {/*        let res = await newOTPVerify(*/}
          {/*          logInUser.user.userId,*/}
          {/*          "MOBILE",*/}
          {/*          token,*/}
        {/*          logInUser.user.mobileNo*/}
        {/*        );*/}
        {/*        if (res && res.data && res.data.status) {*/}
        {/*          this.props.history.push("/verify-mobileNo");*/}
        {/*          this.props.openSignupOTP({*/}
        {/*            user: logInUser.user,*/}
        {/*            token: token,*/}
        {/*          });*/}
        {/*        }*/}
        {/*      } else {*/}
        {/*        this.props.history.push("/sign-up");*/}
        {/*      }*/}
        {/*    }}*/}
        {/*  />*/}
        {/*)}*/}
        <div className={"console-inner-wrapper view-offer"} id={"viewOfferId"}>
          <PreLoader isLoading={isLoading} />
          <div className={"container"}>
            <div className={"row"}>
              {!isLoading && (
                <>
                  {/*======== offer details ========*/}
                  <div className={"col-12 col-lg-9 col-sm-12 col-md-9 mf-0"}>
                    <div
                      className={"offer-view-page-wrapper d-flex flex-column"}
                    >
                      <div className={"Breadcrumb"}>
                        <div className="home-url" onClick={this.handleNavigate}>
                          Home
                        </div>
                        / View offer / {bankData?.bankName} /{" "}
                        {categoryData?.categoryName}
                      </div>
                      {/*==== OFFER VIEW PAGE====*/}
                      <div className={"coo__banner_wrapper"}>
                        <div className={"offv_bg"}>
                          <img
                            src={dataSource?.bannerImageURL}
                            alt="credit card offers"
                          />
                        </div>

                        <div
                          className={
                            "coo__offer-view-header d-flex align-items-center"
                          }
                        >
                          <div className={"coo__merchant-logo"}>
                            <img
                              src={merchantData?.logoURL}
                              alt="creditcard offers"
                            />
                          </div>
                          <div className={"d-flex flex-column"}>
                            <div className={"coo__ov-title"}>
                              {merchantData?.merchantName}
                            </div>
                            <h1 className={"text-header"}>
                              <>{dataSource?.header}</>
                            </h1>
                          </div>
                        </div>

                        <div className={"coo_offer_banner-wrapper"}>
                          <img
                            src={dataSource?.bannerImageURL}
                            alt="credit card offers"
                          />
                          <div className={"coo__bw__offer-tag"}>
                            {dataSource?.offerType === "off" && (
                              <>{dataSource?.offerRate}% off</>
                            )}
                            {dataSource?.offerType === "upto" && (
                              <>upto {dataSource?.offerRate}% off</>
                            )}
                            {dataSource?.offerType === "customize" && (
                              <>{dataSource?.offerRate}</>
                            )}

                            {/*{dataSource?.offerType === "off" && (*/}
                            {/*  <>{dataSource?.offerRate}% off</>*/}
                            {/*)}*/}
                            {/*{dataSource?.offerType === "upto" && (*/}
                            {/*  <>upto {dataSource?.offerRate}% off</>*/}
                            {/*)}*/}
                            {/*{dataSource?.offerType === "customize" && (*/}
                            {/*  <>{dataSource?.offerType}</>*/}
                            {/*)}*/}
                          </div>
                        </div>

                        {/*<div className={"coo__offer-view-banner-black-layer"} />*/}
                      </div>
                      <div
                        className={"coo__offer-view-content d-flex flex-column"}
                      >
                        <OfferDetailCard
                          type={"tag"}
                          option={"d-flex flex-column text-left mb-2 "}
                          description={null}
                          tagList={dataSource?.location}
                          title={"Offer valid location"}
                          tagType={"yellow"}
                        />

                        <OfferDetailCard
                          type={"tag"}
                          option={"d-flex flex-column text-left mb-2 "}
                          description={null}
                          tagList={dataSource?.cardTypes}
                          title={"Valid card"}
                          tagType={"light"}
                        />
                        <OfferDetailCard
                          type={"offRate"}
                          option={"d-flex flex-column text-left mb-2 mt-3"}
                          offerType={dataSource?.offerType}
                          offerRate={dataSource?.offerRate}
                          title={"Offer"}
                        />

                        <OfferDetailCard
                          type={"text"}
                          option={"d-flex flex-column text-left mb-2 mt-3"}
                          offerDateType={dataSource?.offerDateType}
                          offerStart={dataSource?.offerStart}
                          offerEnd={dataSource?.offerEnd}
                          title={"Validity"}
                        />
                        <OfferDetailCard
                          type={"text"}
                          option={"d-flex flex-column text-left mb-2 mt-3"}
                          description={
                            offerData &&
                            dataSource?.location &&
                            dataSource?.location.value
                          }
                          title={"Address"}
                        />
                        <OfferDetailCard
                          type={"text"}
                          option={"d-flex flex-column text-left mb-2 mt-3"}
                          description={
                            offerData &&
                            dataSource?.merchantData &&
                            dataSource?.merchantData.contactNo
                          }
                          title={"Contact"}
                        />

                        <OfferDetailCard
                          type={"text"}
                          option={"d-flex flex-column text-left mb-2 mt-3"}
                          description={dataSource?.description}
                          isHtml={true}
                          title={"Offer Description"}
                        />

                        <OfferDetailCard
                          type={"termsConditions"}
                          option={"d-flex flex-column text-left mb-2 mt-3"}
                          termsConditions={dataSource?.termsConditions}
                          title={"Terms & Conditions"}
                        />

                        <div
                          className={
                            "offer-card-footer-wrapper mt-2 mb-2 d-flex"
                          }
                        >
                          <OfferCardFooterItem
                            title={"Category"}
                            content={categoryData && categoryData?.categoryName}
                            type={"view"}
                          />

                          <OfferCardFooterItem
                            title={"Views"}
                            content={dataSource?.views}
                            type={"view"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*======== bank-details ========*/}
                  <div className={"col-12 col-lg-3 col-sm-12 col-md-3 "}>
                    <div className={"bank-details-wrapper"}>
                      <div className={"d-flex flex-row align-items-center"}>
                        <div className={"bdw__logo"}>
                          <img
                            className={"lading-bg"}
                            src={bankData && bankData?.logoURL}
                            alt="all offers in one place"
                          />
                        </div>
                        <div className={"bdw__title"}>
                          {bankData && bankData?.bankName}
                        </div>
                      </div>
                      <div className={"bdw__description mb-4"}>
                        {bankData && bankData?.description}
                      </div>
                      <div
                        className={"d-flex flex-row align-items-center mb-4"}
                      >
                        <div className={"bdw__icon"}>
                          <RiGlobalLine />
                        </div>
                        <div className={"d-flex flex-column"}>
                          <div className={"bdw__subtitle"}>web</div>
                          <div className={"bdw__detail"}>
                            {bankData && bankData?.webURL}
                          </div>
                        </div>
                      </div>
                      <div
                        className={"d-flex flex-row align-items-center mb-4"}
                      >
                        <div className={"bdw__icon"}>
                          <RiPhoneLine />
                        </div>
                        <div className={"d-flex flex-column"}>
                          <div className={"bdw__subtitle"}>contactNo</div>
                          <div className={"bdw__detail"}>
                            {bankData && bankData?.contactNo}
                          </div>
                        </div>
                      </div>

                      {/*<Button*/}
                      {/*  size={"sm"}*/}
                      {/*  type={"light gray"}*/}
                      {/*  reactIcon={<RiArrowLeftLine />}*/}
                      {/*  text={"Apply Card"}*/}
                      {/*  rightIcon={false}*/}
                      {/*  leftIcon={true}*/}
                      {/*  leftIconSize={"md"}*/}
                      {/*  // onClick={props.onClickSignUp}*/}
                      {/*/>*/}
                    </div>

                    <div className={"social-media-share-wrapper"}>
                      Share offer
                      {/*<FacebookShareButton*/}
                      {/*  url="https://yourwebsite.com"*/}
                      {/*  quote="Check out this amazing content!"*/}
                      {/*  hashtag="#react"*/}
                      {/*/>*/}
                    </div>
                  </div>
                </>
              )}
            </div>

            {/*<div className={""}>*/}
            {/*  <div className={"col-12 col-lg-12 col-sm-12 col-md-12 pl-0 pr-0"}>*/}
            {/*    <div className={"coo_feature_wrapper"}>*/}
            {/*      <h1 className={"text-header mb-3"}>*/}
            {/*        <>Insights & More</>*/}
            {/*      </h1>*/}
            {/*      /!*<Slider {...settings}*!/*/}
            {/*      /!*        className={*!/*/}
            {/*      /!*          "rpmw__slider d-flex flex-row justify-content-center"*!/*/}
            {/*      /!*        }>*!/*/}
            {/*      /!*</Slider>*!/*/}
            {/*      <div className={"d-flex flex-wrap"}>*/}
            {/*        {(insightsData || []).map((item, index) => (*/}
            {/*          <FeatureCard*/}
            {/*            dataSource={item}*/}
            {/*            style={"m-2 ml-0 mr-0"}*/}
            {/*            merchantData={item?.merchant}*/}
            {/*            bankData={item?.bank}*/}
            {/*            categoryData={item?.category}*/}
            {/*            onClick={() => {*/}
            {/*              this.props.history.push(*/}
            {/*                `/offer-view/${item.offerId}`*/}
            {/*              );*/}
            {/*            }}*/}
            {/*          />*/}
            {/*        ))}*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>
        <div>
          <Footer />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    offerData: state.form.offerData,
    categoryData: state.form.categoryData,
    bankData: state.form.bankData,
    insightData: state.insightReducer.insightData,
    isSignUp: state.form.signUp,
    isSignIn: state.form.signIn,
    logInUser: state.user.data,
    isLogin: state.user.isLogin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClickViewMoreOfferDetails: (payload, categoryData, bankData) =>
      dispatch({
        type: actionTypes.OPEN_OFFER_VIEW_MODAL,
        payload: payload,
        categoryData: categoryData,
        bankData: bankData,
      }),
    openSignupOTP: (payload) =>
      dispatch({ type: actionTypes.OPEN_OTP_VERIFY, payload: payload }),
    onClickLogOut: () => dispatch({ type: actionTypes.LOG_OUT }),
    onClickOpenSignUp: () => dispatch({ type: actionTypes.OPEN_SIGN_UP }),
    onClickOpenSignIn: () => dispatch({ type: actionTypes.OPEN_SIGN_IN }),
    onClickOpenNewOffer: () => dispatch({ type: actionTypes.CREATE_NEW_OFFER }),
    getLoginUserDetailsById: (id) => dispatch(getLoginUserDetailsById(id)),
    onAddLoginUser: (userDara) =>
      dispatch({ type: actionTypes.ADD_LOGIN_USER, loginUser: userDara }),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewOffer)
);

// {dataSource?.label} {"-"}{" "}
// {dataSource?.offerType === "off" && (
//     <>{dataSource?.offerRate}% off</>
// )}
// {dataSource?.offerType === "upto" && (
//     <>upto {dataSource?.offerRate}% off</>
// )}
// {dataSource?.offerType === "customize" && (
//     <>{dataSource?.offerType}</>
// )}
