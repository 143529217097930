import * as actionTypes from "../actions/types";

const initialState = {
  data: [],
  error: null,
  loading: false,
};
let newData = [];

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CATEGORY_REQUEST_SUCCESS:
      return {
        ...state,
        error: "",
        loading: false,
        data: action.payload,
      };
    case actionTypes.CATEGORY_REQUEST_PENDING:
      return {
        ...state,
        error: "",
        data: undefined,
        loading: true,
      };
    case actionTypes.CATEGORY_REQUEST_FAILURE:
      return {
        ...state,
        error: "",
        data: undefined,
        loading: false,
      };
    case actionTypes.GET_ALL_CATEGORY:
      return {
        ...state,
        error: null,
        data: action.data,
        loading: false,
      };
    case actionTypes.SET_ALL_CATEGORY:
      return {
        ...state,
        error: null,
        data: action.payload,
        loading: false,
      };
    case actionTypes.GET_LOCAL_CATEGORY:
      return {
        ...state,
        error: null,
        data: state.data,
        loading: false,
      };
    case actionTypes.ADD_CATEGORY:
      return {
        ...state,
        error: null,
        data: state.data.concat(action.newCategory),
        loading: false,
      };
    case actionTypes.ON_REMOVE_CATEGORY:
      return {
        ...state,
        id: action.categoryId,
        data: state.data.filter((cat) => cat.id !== action.categoryId),
        loading: false,
      };
    case actionTypes.GET_CATEGORY_BY_ID:
      return {
        ...state,
        id: action.categoryId,
        loading: false,
      };
    case actionTypes.SELECTED_CATEGORY:
      for (let key in state.data) {
        if (state.data[key].id === action.sData.id) {
          state.data[key].isActive = !state.data[key].isActive;
        }
      }
      return {
        ...state,
        error: null,
        data: newData.concat(state.data),
        loading: false,
      };

    case actionTypes.SELECTED_CATEGORY_GROUP:
      console.log(actionTypes.SELECTED_CATEGORY_GROUP);
      console.log(state.data);
      console.log(action.sData);
      for (let key in state.data) {
        for (let t in action.sData) {
          if (state.data[key].id === action.sData[t]) {
            state.data[key].isActive = true;
          }
        }
      }
      return {
        ...state,
        error: null,
        data: newData.concat(state.data),
        loading: false,
      };

    case actionTypes.SELECTED_CATEGORY_CLEAR:
      for (let key in state.data) {
        state.data[key].isActive = false;
      }
      return {
        ...state,
        error: null,
        data: newData.concat(state.data),
        loading: false,
      };
    default:
      return state;
  }
};
