import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import "./mobile.scss";

const Index = (props) => (
  <>
    <div
      className={`btn-icon-wrapper ${props.type} ${props.size}`}
      onClick={props.onClick}
    >
      {props.buttonIcon}
    </div>
  </>
);

Index.propTypes = {
  type: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
};

Index.defaultProps = {
  type: "light",
  size: "sm",
  icon: "<RiCloseFill/>",
};

export default Index;
