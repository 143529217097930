import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";
//import "./mobile.scss";

const Index = (props) => (
  <>
    <div className={`filter-color-first-wrapper`}>
      <div className={`fcfw__color1`} />
      <div className={`fcfw__color2`} />
    </div>
  </>
);

Index.propTypes = {
};

Index.defaultProps = {
  text: "",
  icon: "",
};

export default Index;
