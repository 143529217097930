import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "./styles.scss";
import "./mobile.scss";
import {
  Button,
  ButtonIcon,
  FilterColor_1,
  FilterColor_2,
  InfoCard,
} from "../../../components";
import { GetIcon } from "../../../config/icon";
import * as actionTypes from "../../../store/actions/types";
import { onClickCloseMobileProfile } from "../../../store/actions/mobileProifleView";
import { RiArrowRightLine, RiHeart2Line } from "react-icons/ri";
import { MdChevronRight, MdOutlineFavoriteBorder } from "react-icons/md";
import Scrollbars from "react-custom-scrollbars";
import { userDetails, userLogOut } from "../../../utils";
import { getFavoriteOfferByUser } from "../../../service/userApi";
import { onClickOpenMobileFavorite } from "../../../store/actions/mobileFavorite";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wizardNo: 1,
      registeredType: 1,
      viewPointWidth: 0,
      viewPointHeight: 0,
      wizardStep: 1,
      isLoading: false,
      userFavoritesList: [],
      isMobileVerification: true,
      sessionUser: null,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    // console.log("scrollTop : " + scrollTop);
    // console.log("scrollHeight : " + scrollHeight);
    // console.log("clientHeight :" + clientHeight);
  };

  async componentDidMount() {
    console.log("componentDidMount");
    window.addEventListener("resize", this.updateWindowDimensions, true);
    window.addEventListener("scroll", this.handleOnScroll, true);

    this.updateWindowDimensions();
    this.handleOnScroll();

    //=========== is available session user ===========
    let sessionUser = userDetails();
    if (sessionUser?.userId) {
      console.log("sessionUser", sessionUser);
      this.setState({ sessionUser: sessionUser });
      this.props.onAddLoginUser(sessionUser);
      let favoriteData = await getFavoriteOfferByUser(sessionUser?.userId);
      if (favoriteData?.status) {
        console.log("favoriteData", favoriteData);
        this.setState({
          isMobileVerification: favoriteData?.data?.isMobileVerification,
          userFavoritesList: favoriteData?.data?.favorites,
        });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  onClickLogOut = () => {
    let _this = this;
    this.props.history.push("/");
    this.setState({
      isMobileVerification: true,
      userFavoritesList: [],
    });
    this.props.onClickLogOut();
    userLogOut(function (res) {
      console.log("logout------");
      console.log(res);
      window.location.reload();
    });
  };

  render() {
    const { viewPointHeight, isLoading, isMobileVerification, sessionUser } =
      this.state;
    const { isMobileProfile, selectMoreInfo, props, logInUser, privateUser } =
      this.props;

    return (
      <>
        <section
          className={`coo__modal-main-wrapper animate__animated ${
            isMobileProfile ? "show-me" : "hide-me"
          }`}
        >
          <div
            className={
              "coo_modal-body-content d-flex flex-column animate__animated animate__fadeInUp"
            }
            style={{
              top: "0",
              borderRadius: "0",
              height: "100%",
              padding: "16px",
            }}
          >
            <div className={"filter-wrapper-1"}>
              <FilterColor_1 />
            </div>
            <div className={"image-wrapper-1"}>
              <img src={require("../../../assets/img/Group 5_mobile.png")} />
            </div>

            <div className={"mpw__close"}>
              <div className={"d-flex flex-row align-items-center"}>
                <ButtonIcon
                  type={"back"}
                  onClick={() => this.props.onClickCloseMobileProfile()}
                  size={"sm x"}
                  buttonIcon={GetIcon("leftArrow")}
                />
                <div className={"close-txt"}>Home</div>
              </div>
            </div>
            <Scrollbars
              style={{ height: viewPointHeight - 110 }}
              thumbMinSize={30}
              universal={true}
              autoHide
            >
              <div className={"coo_modal_body d-flex flex-column"}>
                <div
                  className={
                    "d-flex flex-column justify-content-center align-items-center align-content-center"
                  }
                  style={{
                    marginBottom: "38px",
                    paddingBottom: "21px",
                    borderBottom: "1px solid #F0F0F0",
                  }}
                >
                  {logInUser &&
                    logInUser.user &&
                    logInUser.user.isMobileVerification && (
                      <div className={"coom__b-verification"}>
                        {GetIcon("check")}
                      </div>
                    )}
                  <div className={"coom__b-user-img"}>
                    {/* {privateUser && ( */}
                    <img
                      alt={"credit card offers"}
                      src={require("../../../assets/img/default-avatar.jpg")}
                    />
                    {/* )} */}
                  </div>
                  <div className={"coom__b-user-name"}>
                    Hi, {sessionUser && sessionUser.firstName}
                  </div>
                  <div
                    className={
                      "coom__b-contact d-flex flex-row align-items-center justify-content-between"
                    }
                  >
                    <div className={"coom__b-flag"}>
                      <img
                        src={require("../../../assets/img/flags/LK.svg")}
                        alt="creditcard offers"
                      />
                    </div>
                    <div className={"coom__bc-no"}>
                      {sessionUser && sessionUser.mobileNo}
                    </div>
                  </div>
                </div>
                <div
                  className={
                    " d-flex flex-row align-items-center justify-content-between"
                  }
                >
                  <div
                    className={
                      " d-flex flex-row align-items-center justify-content-between"
                    }
                  >
                    <ButtonIcon
                      type={"menu"}
                      buttonIcon={<MdOutlineFavoriteBorder />}
                      size={"lg"}
                    />
                    <div className={"coom__b-favorites"}>Favourites</div>
                  </div>
                  <div>
                    <ButtonIcon
                      type={"back"}
                      buttonIcon={<MdChevronRight />}
                      size={"sm x"}
                      onClick={() => this.props.onClickOpenMobileFavorite()}
                    />
                  </div>
                </div>
              </div>
            </Scrollbars>
            <div className={"coom__action"}>
              <Button
                size={"md x"}
                type={"light"}
                reactIcon={""}
                text={"Log out"}
                isLoading={false}
                rightIcon={false}
                leftIcon={false}
                onClick={() => {
                  this.props.onClickLogOut();
                  this.props.onClickCloseMobileProfile();
                }}
              />
              <div className={"coom__version"}>Version 1.0</div>
            </div>
            <div className={"filter-wrapper-2"}>
              <FilterColor_2 />
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobileProfile: state.mobileProfileViewReducer.isMobileProfile,
    logInUser: state.user.data,
    privateUser: state.user.privateUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddLoginUser: (userDara) =>
      dispatch({ type: actionTypes.ADD_LOGIN_USER, loginUser: userDara }),
    onClickCloseMobileProfile: () =>
      dispatch({ type: actionTypes.CLOSE_MOBILE_PROFILE_VIEW, status: false }),
    onClickLogOut: () => dispatch({ type: actionTypes.LOG_OUT }),
    onClickOpenMobileFavorite: () =>
      dispatch({ type: actionTypes.OPEN_MOBILE_FAVORITE_LIST }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);
