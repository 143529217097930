import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import { DatePicker, Input, Select, Space, TimePicker } from "antd";
import { RiCheckLine, RiCloseLine } from "react-icons/ri";
import { PasswordStrengthMeter } from "../../../components";

const { Option, OptGroup } = Select;
const { RangePicker } = DatePicker;

const options = [
  {
    value: "Sri Lanka +94",
    label: "Sri Lanka +94",
  },
  {
    value: "India +91",
    label: "India +91",
  },
  {
    value: "England +44",
    label: "England +44",
  },
  {
    value: "Australia +61",
    label: "Australia +61",
  },
];

const Index = (props) => {
  let inputElement = null;
  switch (props.elementType) {
    case "input":
      inputElement = (
        <>
          <input
            className={`form-control txt-input ${props.size}
                    ${
                      props.isinvalid && props.required && props.touched
                        ? "validation-on"
                        : ""
                    }`}
            {...props.elementConfig}
            value={props.value}
            onChange={props.changed}
          />
          {props.showVerification && (
            <div
              className={`verification-status 
                ${props.verificationStatus ? "verified" : "unverified"}`}
              onClick={props.onClickClear}
            >
              {props.verificationStatus && <RiCheckLine />}
              {!props.verificationStatus && <RiCloseLine />}
            </div>
          )}

          {props.isRightIcon && (
            <>
              <div
                onClick={() => {
                  if (props.onClickRightIcon) {
                    props.onClickRightIcon();
                  }
                }}
                className={`text-box-icon-right ${props.size}`}
              >
                {props.rightIcon}
              </div>
            </>
          )}
        </>
      );
      break;
    case "phoneNo":
      inputElement = (
        <>
          <input
            className={`form-control txt-input phone-no ${props.size} 
                ${
                  props.isinvalid && props.required && props.touched
                    ? "validation-on"
                    : ""
                }`}
            {...props.elementConfig}
            value={props.value}
            onChange={props.changed}
          />
          <div className={"input-flg-wrapper d-flex ml-2"}>
            <div className={"flg-img-wpp"}>
              <img
                src={require("../../../assets/img/flags/LK.svg")}
                alt="online event colombo event"
              />
            </div>
            <div className={"country-code"}>+94</div>
          </div>
          {props.showVerification && (
            <div
              className={`phone-verification-status 
                ${props.verificationStatus ? "verified" : "unverified"}`}
            >
              {props.verificationStatus === true && <RiCheckLine />}
              {props.verificationStatus === false && <RiCloseLine />}
            </div>
          )}
        </>
      );
      break;
    case "textArea":
      inputElement = (
        <>
          {" "}
          <textarea
            key={props.key}
            className={`form-control txt-input  text-area ${props.size} 
                ${
                  props.isinvalid && props.required && props.touched
                    ? "validation-on"
                    : ""
                }`}
            {...props.elementConfig}
            value={props.value}
            onChange={props.changed}
          />
        </>
      );
      break;
    case "select":
      inputElement = (
        <Select
          allowClear
          className={`form-control txt-input ${props.size} 
                     ${
                       props.isinvalid && props.required && props.touched
                         ? "validation-on"
                         : ""
                     }`}
          onChange={props.changed}
        >
          {props.elementConfig.options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.displayValue}
            </option>
          ))}
        </Select>
      );
      break;
    case "dropdown":
      inputElement = (
        <Select
          className={`form-control drop-down drop-md ${props.size}
                     ${
                       props.isinvalid && props.required && props.touched
                         ? "validation-on"
                         : ""
                     }`}
          {...props.elementConfig}
          showSearch
          loading={props.isLoading}
          allowClear
          onChange={props.changed}
        >
          {props.data &&
            props.data.map((d) => (
              <Option value={d.id} key={d.id}>
                {d[props.valueName]}
              </Option>
            ))}
        </Select>
      );
      break;
    case "datePicker":
      inputElement = (
        <DatePicker
          className={`form-control date-picker ${props.size}
                     ${
                       props.isinvalid && props.required && props.touched
                         ? "validation-on"
                         : ""
                     }`}
          {...props.elementConfig}
          format={props.format}
          onChange={props.onChange}
        />
      );
      break;
    case "timePicker":
      inputElement = (
        <TimePicker
          className={`form-control date-picker ${props.size}
                    ${
                      props.isinvalid && props.required && props.touched
                        ? "validation-on"
                        : ""
                    }`}
          onChange={props.onChange}
          use12Hours
          {...props.elementConfig}
          value={props.value}
        />
      );
      break;
    case "dateRange":
      inputElement = (
        <RangePicker
          allowClear={true}
          className={`date-range ${props.size}
                     ${
                       props.isinvalid && props.required && props.touched
                         ? "validation-on"
                         : ""
                     }`}
          {...props.elementConfig}
          format={props.format}
          onChange={(dateRange) => {
            props.onChange(props.elementType, dateRange);
          }}
        />
      );
      break;
    case "selectOptGroup":
      inputElement = (
        <Select
          allowClear
          showSearch
          className={`form-control select-option ${props.size} 
                     ${
                       props.isinvalid && props.required && props.touched
                         ? "validation-on"
                         : ""
                     }`}
          placeholder={props.elementConfig.placeholder}
          onChange={props.changed}
        >
          {props.elementConfig.options.map((option, index) => (
            <>
              <OptGroup label={option.group_label}>
                {option.data.map((d, i) => (
                  <>
                    <option key={d.value} value={d.value}>
                      {d.displayValue}
                    </option>
                  </>
                ))}
              </OptGroup>
            </>
          ))}
        </Select>
      );
      break;
    case "select-text":
      inputElement = (
        <Space.Compact>
          <Select
            className={`select-text ${props.type} ${props.size}`}
            defaultValue="Sri Lanka +94"
            options={options}
          />
          <input
            className={`form-control txt-input select-text ${props.size}
                    `}
            {...props.elementConfig}
            value={props.value}
            onChange={props.changed}
          />
        </Space.Compact>
      );
      break;
    default:
      inputElement = (
        <input
          {...props.elementConfig}
          value={props.value}
          onChange={props.changed}
        />
      );
  }
  return (
    <>
      <div
        className={`d-flex flex-column input-label ${props.margin}  
        ${
          props.isinvalid && props.required && props.touched
            ? "validation-on"
            : ""
        }`}
      >
        {props.label && (
          <label
            className={`${
              props.isinvalid && props.required && props.touched
                ? "label-validation-on"
                : "lbl"
            }`}
          >
            {props.label} {props.required ? <b>*</b> : ""}
          </label>
        )}
        {inputElement}

        {props.isShowPasswordStrengthMeter && (
          <PasswordStrengthMeter
            password={props.value}
            fieldId="password"
            label="Password"
            placeholder="Enter Password"
            thresholdLength={8}
            minStrength={3}
            required
          />
        )}

        {props.isinvalid && props.required && props.touched && (
          <>
            {props.required && !props.value && (
              <div className={"error-required"}>Field is required. </div>
            )}
            {props.required && props.value && props.invalidReason && (
              <div className={"error-required"}>{props.invalidReason}</div>
            )}
          </>
        )}
      </div>
    </>
  );
};

Index.propTypes = {
  elementType: PropTypes.string.isRequired,
};

Index.defaultProps = {
  elementType: "input",
};

export default Index;
