import React from 'react';
import PropTypes from 'prop-types';
import './style.scss'

import {
    TimelineItem
} from '../../../components'

import {
    RiStore2Line,
    RiArticleLine,
    RiLock2Line,
    RiContactsBookLine,
    RiBuilding2Line,
    RiPercentLine,
    RiMapPinRangeLine,
    RiApps2Line,
    RiShieldLine
} from "react-icons/ri";

const Index = (props) => (
    <>

        <div className={'wizard-wrapper d-flex flex-row'}>
            <div className={'flex-row'}>
                <TimelineItem
                    itemNo={1}
                    userType={'offerCreate'}
                    formName={'Select Category'}
                    done={props.wizardNo > 1}
                    isFocusTab={true}
                    formIcon={<RiApps2Line/>}
                    step={'Step 1'}/>
                <TimelineItem
                    itemNo={2}
                    userType={'offerCreate'}
                    done={props.wizardNo >= 3}
                    formName={'Select Bank'}
                    isFocusTab={props.wizardNo === 2}
                    formIcon={<RiBuilding2Line/>}
                    step={'Step 2'}/>

                <TimelineItem
                    itemNo={3}
                    userType={'offerCreate'}
                    formName={'Offer Details'}
                    done={props.wizardNo >= 5}
                    isFocusTab={props.wizardNo === 3}
                    formIcon={<RiPercentLine/>}
                    step={'Step 3'}/>

                <TimelineItem
                    itemNo={4}
                    userType={'offerCreate'}
                    formName={'Valid Location'}
                    done={props.wizardNo >= 6}
                    isFocusTab={props.wizardNo === 4}
                    formIcon={<RiMapPinRangeLine/>}
                    step={'Step 4'}/>

                <TimelineItem
                    itemNo={5}
                    userType={'offerCreate'}
                    formName={'terms & conditions'}
                    done={props.wizardNo >= 7}
                    isFocusTab={props.wizardNo === 5}
                    formIcon={<RiShieldLine/>}
                    step={'Step 5'}/>


            </div>


        </div>
    </>
);

Index.propTypes = {
    wizardNo: PropTypes.string.isRequired,
};

Index.defaultProps = {
    wizardNo: 1,
};

export default Index;
