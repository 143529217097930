import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss'

import {
    Button,
    ButtonIcon
} from '../../../components'

import {RiFileUploadLine} from "react-icons/ri";

const Index = (props) => (
    <>
        <div className={'profile-menu-card d-flex'}>
            <div className={'p-menu-title'}>
                {props.title}
            </div>

            <div className={'pm-content-wrapper d-flex flex-column'}>
                <>
                    <div className={`BRattachment-wrapper light`}>
                        <>
                            <input
                                id="uploadNew"
                                type='file'
                                accept="image/*"
                                onChange={props.onSelectFile}
                                hidden/>
                            <div className={'d-flex'}
                                 onClick={() => {
                                     document.getElementById("uploadNew").click();
                                 }}>
                                <div
                                    className={`right-icon-wrapper`}>
                                    <ButtonIcon
                                        type={'dark'}
                                        buttonIcon={<RiFileUploadLine/>}
                                        size={'sm'}/>
                                </div>
                                <div className={'text'}>
                                    Upload
                                </div>
                            </div>
                        </>
                    </div>
                    <div className={'upload-title'}>
                        Upload your company logo
                    </div>
                    <small>File should be PNG , JPG</small>
                </>

            </div>

        </div>
    </>
);

Index.propTypes = {};

Index.defaultProps = {};

export default Index;
