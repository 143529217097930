import axios from 'axios';

import {userDetails, getToken} from '../utils';
import * as config from "../config/baseUrls";
import {openNotification} from "../config/notification";
import * as actionTypes from "../store/actions/types";


export const createTempImage = (data, callback) => {
    const _token = getToken();
    axios.post(config.baseUrl + 'temp/image/upload',
        {data},
        {
            'Content-Type': 'application/json',
            headers: {
                'Authorization': 'Bearer ' + _token
            }
        })
        .then(function (response) {
            callback(response);
        })
        .catch(function (error) {
            callback(false);
        });
};























