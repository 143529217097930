import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import { Icon } from "../../index";
import Moment from "react-moment";
import { GetIcon } from "../../../config/icon";

const isArray = (value) => {
  return Array.isArray(value);
};

const Index = (props) => (
  <>
    <div className={`cco__details-card__wrapper ${props.option}`}>
      <div className={"cco__details-card__title mb-1"}>{props.title}</div>

      {props.type === "text" && !props.isHtml && (
        <div className={"cco__details-card__desc"}>{props.description}</div>
      )}

      {props.isHtml && props.type === "text" && (
        <div
          className={"cco__details-card__desc"}
          dangerouslySetInnerHTML={{ __html: props.description }}
        />
      )}

      {props.type === "text" &&
        (props.title === "Mobile no" || props.title === "E-mail") && (
          <div className={"d-flex flex-row"}>
            {props?.contactList
              ?.filter((x) => x.isActive)
              .map((item, index) => (
                <div
                  className={`cco__details-card__desc mr-2 ${
                    props.contactList.length > 1 ? "mobile-border-left" : ""
                  }`}
                >
                  {item.contact}
                </div>
              ))}
          </div>
        )}

      {props.type === "tag" && (
        <div className={"d-flex flex-wrap mt-1"}>
          {(props.tagList || [])
            .filter((item) => item.isActive)
            .map((item, index) => (
              <div
                key={item.id}
                className={`cco__details-card__tag mr-1 ${props.tagType}`}
              >
                {item.value}
              </div>
            ))}
        </div>
      )}

      <div>
        <div className={"cco__details-card__desc "}>
          {props.offerDateType === "fromTo" && (
            <>
              <>
                Valid from{" "}
                <Moment format=" DD MMMM ">{props.offerStart}</Moment> to{" "}
                <Moment format=" DD MMMM YYYY">{props.offerEnd}</Moment>
              </>
            </>
          )}

          {props.offerDateType === "until" && (
            <>
              <>
                Valid until{" "}
                <Moment format=" DD MMMM YYYY">{props.offerEnd}</Moment>
              </>
            </>
          )}
        </div>

        <div className={"d-flex flex-row"}>
          {props.note && (
            <>
              <div className={"cco__details-card-note"}>Note :{props.note}</div>
            </>
          )}
        </div>
      </div>

      {props.type === "offRate" && (
        <>
          <div className={"cco__details-card__desc"}>
            {props.offerType === "off" && <>{props.offerRate}% off</>}
            {props.offerType === "upto" && <>upto {props.offerRate}% off</>}
            {props.offerType === "customize" && <>{props.offerRate}</>}
          </div>
        </>
      )}

      {props.type === "termsConditions" && (
        <div className={"d-flex flex-column mt-1"}>
          {isArray(props?.termsConditions) ? (
            <>
              {(props?.termsConditions || []).map((item, index) => (
                <div key={item.id} className={"cco__terms-conditions d-flex"}>
                  <Icon
                    type={"light"}
                    size={"x-sm"}
                    option={"mr-2"}
                    buttonIcon={GetIcon("check")}
                  />
                  <>{item.value}</>
                </div>
              ))}
            </>
          ) : (
            <>
              {props.bankId &&
                props?.termsConditions[props?.bankId].map((item, index) => (
                  <div key={item.id} className={"cco__terms-conditions d-flex"}>
                    <Icon
                      type={"light"}
                      size={"x-sm"}
                      option={"mr-2"}
                      buttonIcon={GetIcon("check")}
                    />
                    <>{item.value}</>
                  </div>
                ))}
            </>
          )}
        </div>
      )}
    </div>
  </>
);

Index.propTypes = {
  title: PropTypes.string.isRequired,
};

Index.defaultProps = {
  title: "text not found",
};

export default Index;
