import React, { Component } from "react";
import uuid from "react-uuid";
import "./style.scss";
import {
  RiDashboardLine,
  RiPriceTagLine,
  RiVipCrown2Line,
  RiHotelLine,
  RiErrorWarningLine,
  RiLinksLine,
} from "react-icons/ri";
import { getToken, userDetails } from "../../utils";
import { Scrollbars } from "react-custom-scrollbars";
import * as actionTypes from "../../store/actions/types";
import { connect } from "react-redux";
import { CheckBox, InfomationBox, MenuCard } from "../../components";
import Offer from "./template/offer";
import UserProfile from "./template/userProfile";
import UserProfilePending from "./template/userProfilePending";
import { Route, Switch } from "react-router-dom";
import { getLoginDetails } from "../../service/userApi";
import * as config from "../../config/baseUrls";

class MerchantPortalComponent extends Component {
  constructor(props) {
    super(props);
    const user = userDetails();
    console.log(user);
    let _this = this;
  }

  // componentDidMount() {
  //     console.log('merchant profile loading....');
  //     let state = userDetails().loginUser;
  //     console.log(state);
  //     if (state && state.status && state.id) {
  //         console.log('event fire get user by id' + state.id);
  //         this.props.getLoginUserDetailsById(state.id);
  //         console.log(this.props.privateUser);
  //     } else {
  //         this.props.onClickLogOut();
  //     }
  // }

  render() {
    const { privateUser } = this.props;

    console.log("===== privateUser ======= ");
    console.log(privateUser[0]);
    console.log(privateUser.logoUrl);

    return (
      <>
        {/*create new event*/}
        <div className={"portal-console-main-wrapper"}>
          <div className={"container"}>
            <div className={"row"}>
              <div className={"col-lg-3"}>
                <div className={"portal-left-wrapper "}>
                  <div
                    className={"portal-inner-left-wrapper d-flex flex-column"}
                  >
                    <div className={"profile-img"}>
                      {this.props.privateUser.logoUrl && (
                        <img
                          className={"offer-portal-logo"}
                          alt={"credit card offers"}
                          src={config.baseImageURL + privateUser.logoUrl}
                        />
                      )}
                    </div>
                    <div className={"menu-wrapper"}>
                      <div className={"menu-title"}>Menu</div>
                      <div className={"menu-inner-wrapper mt-2"}>
                        {this.props.logInUser.userType === 2 && (
                          <>
                            <MenuCard
                              icon={<RiHotelLine />}
                              isActive={
                                this.props.pathName ===
                                "/console/merchant/profile"
                              }
                              onClick={() =>
                                this.props.onClickGoToPage("profile")
                              }
                              size={"md"}
                              text={"company Profile"}
                            />

                            <MenuCard
                              icon={<RiDashboardLine />}
                              isActive={
                                this.props.pathName ===
                                "/console/merchant/dashboard"
                              }
                              onClick={() =>
                                this.props.onClickGoToPage("dashboard")
                              }
                              size={"md"}
                              text={"Dashboard"}
                            />

                            <MenuCard
                              icon={<RiPriceTagLine />}
                              size={"md"}
                              isActive={
                                this.props.pathName ===
                                "/console/merchant/offer"
                              }
                              onClick={() =>
                                this.props.onClickGoToPage("offer")
                              }
                              text={"Offer"}
                            />

                            <MenuCard
                              icon={<RiVipCrown2Line />}
                              size={"md"}
                              isActive={
                                this.props.pathName ===
                                "/console/merchant/subscription"
                              }
                              onClick={() =>
                                this.props.onClickGoToPage("subscription")
                              }
                              text={"Subscription Plan"}
                            />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={"col-lg-9"}>
                <Scrollbars
                  autoWidth
                  style={{ height: this.props.viewPointHeight - 160 }}
                >
                  <div className={"portal-right-wrapper"}>
                    <Switch>
                      <Route path="/console/merchant/profile">
                        <UserProfile
                          userDetails={this.props.privateUser}
                          test={"testEvent"}
                        />
                        {/*<UserProfilePending/>*/}
                      </Route>
                      <Route path="/console/merchant/dashboard">
                        <UserProfilePending />
                      </Route>
                      <Route path="/console/merchant/offer">
                        {/*<h1>offer</h1>*/}
                        <UserProfilePending />
                      </Route>
                      <Route path="/console/merchant/subscription">
                        <UserProfilePending />
                      </Route>
                    </Switch>
                  </div>
                </Scrollbars>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    locData: state.location.data,
    privateUser: state.user.privateUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClickOpenSignIn: () =>
      dispatch({ type: actionTypes.OPEN_SIGN_IN, status: true }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MerchantPortalComponent);
