import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss'
import {BiX} from "react-icons/bi";

const Index = (props) => (
    <>
        <div key={props.key}
             className={`selection-tab-box-wrapper mr-1 d-flex ${props.type} ${props.isSelect ? 'is-active' : ''}`}
             onClick={props.onClick}>
            <div className={'text'}>
                {props.text}
            </div>
            {props.remove &&
                <div className={'selection-wrp-checkBox'}
                     onClick={props.onSelect}>
                    <BiX/>
                </div>
            }
            {props.select &&
                <div className={`selection-wrp-checkBox ${props.isSelect}`}
                     onClick={props.onRemove}>
                    {props.isSelect && <BiX/>}
                </div>
            }
        </div>
    </>
);

Index.propTypes = {
    text: PropTypes.string.isRequired,
};

Index.defaultProps = {
    text: '',
};

export default Index;
