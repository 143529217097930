import React, { Component } from "react";
import uuid from "react-uuid";
import "./style.scss";
import {
  RiDashboardLine,
  RiHeart2Line,
  RiVipCrown2Line,
  RiHotelLine,
  RiErrorWarningLine,
  RiLinksLine,
  RiProfileLine,
} from "react-icons/ri";
import { getToken, userDetails } from "../../utils";
import { Scrollbars } from "react-custom-scrollbars";
import * as actionTypes from "../../store/actions/types";
import { connect } from "react-redux";
import { CheckBox, InfomationBox, MenuCard } from "../../components";
import Offer from "./template/offer";
import UserProfile from "./template/userProfile";
import UserProfilePending from "./template/userProfilePending";
import { Route, Switch } from "react-router-dom";
import { getLoginDetails } from "../../service/userApi";

class CustomerPortalComponent extends Component {
  constructor(props) {
    super(props);
    const user = userDetails();
    console.log(user);
  }

  render() {
    return (
      <>
        {/*create new event*/}
        <div
          className={"customer-portal-console-main-wrapper"}
          style={{ height: this.props.viewPointHeight - 30 }}
        >
          <div className={"container"}>
            <div className={"row"}>
              <div className={"col-lg-3"}>
                <div className={"portal-left-wrapper "}>
                  <div
                    className={"portal-inner-left-wrapper d-flex flex-column"}
                  >
                    <div className={"profile-img"}>
                      <img
                        className={"offer-portal-logo"}
                        src={require("../../assets/img/defaultCustomerAvatar.svg")}
                        alt="credit card offers"
                      />
                    </div>
                    <div className={"menu-wrapper"}>
                      <div className={"menu-title"}>Menu</div>
                      <div className={"menu-inner-wrapper mt-2"}>
                        {this.props.logInUser.userType === "1" && (
                          <>
                            <MenuCard
                              icon={<RiProfileLine />}
                              isActive={
                                this.props.pathName ===
                                "/console/customer/profile"
                              }
                              onClick={() =>
                                this.props.onClickGoToPage("profile")
                              }
                              size={"md"}
                              text={"Customer Profile"}
                            />

                            {/*<MenuCard*/}
                            {/*    icon={<RiDashboardLine/>}*/}
                            {/*    isActive={this.props.pathName === '/console/customer/dashboard'}*/}
                            {/*    onClick={() => this.props.onClickGoToPage('dashboard')}*/}
                            {/*    size={'md'}*/}
                            {/*    text={'Dashboard'}/>*/}

                            <MenuCard
                              icon={<RiHeart2Line />}
                              size={"md"}
                              isActive={
                                this.props.pathName ===
                                  "/console/merchant/offer" ||
                                this.props.pathName ===
                                  "/console/customer/offer"
                              }
                              onClick={() =>
                                this.props.onClickGoToPage("offer")
                              }
                              text={"My Offers"}
                            />

                            {/*<MenuCard*/}
                            {/*    icon={<RiVipCrown2Line/>}*/}
                            {/*    size={'md'}*/}
                            {/*    isActive={this.props.pathName === '/console/customer/subscription'}*/}
                            {/*    onClick={() => this.props.onClickGoToPage('subscription')}*/}
                            {/*    text={'Subscription Plan'}/>*/}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={"col-lg-9"}>
                {/*<Scrollbars*/}
                {/*    autoWidth*/}
                {/*    style={{height: this.props.viewPointHeight - 160}}> </Scrollbars>*/}
                <div className={"portal-right-wrapper"}>
                  <Switch>
                    <Route path="/console/customer/profile">
                      <UserProfile />
                      {/*<UserProfilePending/>*/}
                    </Route>
                    <Route path="/console/customer/dashboard">
                      <UserProfilePending />
                    </Route>
                    <Route path="/console/customer/offer">
                      <Offer />
                    </Route>
                    <Route path="/console/customer/subscription">
                      <UserProfilePending />
                    </Route>
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    locData: state.location.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClickOpenSignIn: () =>
      dispatch({ type: actionTypes.OPEN_SIGN_IN, status: true }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerPortalComponent);
