
//local URL
export const baseUrl = "https://6mkmn57nh1.execute-api.us-east-1.amazonaws.com/demo/";
export const baseUrlAdmin = "https://uql7dgtkr9.execute-api.us-east-1.amazonaws.com/demo/";



//test
export const testUrl = "https://qcj6btbiq0.execute-api.us-east-1.amazonaws.com/qa/";
export const baseImageURL = "https://temp-image-bucket-demo.s3.amazonaws.com/";
