import axios from 'axios';

import * as config from "../config/baseUrls"
import {getToken} from '../utils';
import * as actionTypes from '../store/actions/types';
import {openNotification} from "../config/notification";

const token = getToken();

let CATEGORY_URL = config.baseUrlAdmin + 'category/';

export const getAllCategory = () => dispatch => {
    dispatch({
        type: actionTypes.CATEGORY_REQUEST_PENDING,
    });
    let response = axios.get(CATEGORY_URL + 'all', {
        headers: {'Content-Type': 'application/json'}
    }).then(res => {
        if (res && res.data && res.data.response) {
            dispatch({
                type: actionTypes.SET_ALL_CATEGORY,
                data: res.data.response,
            });
        }
    }).catch(err => {
        dispatch({
            type: actionTypes.CATEGORY_REQUEST_FAILURE,
            error: err
        });
    });
};















