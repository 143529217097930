import * as actionTypes from "../actions/types";
import produce from "immer";

const initialState = {
  data: [],
  error: null,
  loading: false,
  isOpen: false,
  isActiveFilter: false,
  type: null,
  activeId: null,
  activeBankName: null,
  bankIdList: [],
};

export default produce((draft = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.OPEN_FILTER_ITEM:
      console.log("payload", action.payload);
      return {
        ...draft,
        type: payload,
        isOpen: true,
      };
    case actionTypes.CLOSE_FILTER_ITEM:
      return {
        ...draft,
        type: null,
        isOpen: false,
      };
    case actionTypes.SET_FILTER_OPTION_BANK:
      console.log("SET_FILTER_OPTION_BANK", payload.bankName);
      draft.type = payload.type;
      draft.bankIdList.push(payload.id);
      draft.activeId = payload.id;
      draft.activeBankName = payload.bankName;
      draft.isOpen = true;
      draft.isActiveFilter = true;
      return draft;
    case actionTypes.CLEAR_ALL_FILTER:
      draft.type = null;
      draft.isOpen = false;
      draft.bankIdList = [];
      draft.activeId = null;
      draft.activeBankName = null;
      draft.isActiveFilter = false;
      return draft;
    default:
      return draft;
  }
});
