import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

const Index = ({
  type,
  placeholder,
  label,
  onChange,
  value,
  size,
  onKeyPress,
}) => (
  <>
    <label>{label}</label>
    <input
      type={type}
      className={`form-control text-box-wrapper ${size}`}
      placeholder={placeholder}
      value={value}
      onKeyPress={onKeyPress}
      onChange={onChange}
    />
  </>
);

Index.propTypes = {
  type: PropTypes.string.isRequired,
};

Index.defaultProps = {
  type: "",
};

export default Index;
