import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss'

import {
    Tag
} from '../../../components'

import {RiFileUploadLine} from "react-icons/ri";
import * as actionTypes from "../../../store/actions/types";


const Index = (props) => {

    //====== get category name by ID
    function getCategoryById(id) {
        for (let key in props.catData) {
            if (props.catData[key] && props.catData[key].id === id) {
                return props.catData[key].categoryName;
            }
        }
    }


    return (
        <div className={`profile-menu-card d-flex ${props.padding}`}>
            <div className={'p-menu-title'}>
                {props.title}
            </div>

            <div className={'pm-content-wrapper d-flex'}>
                {props.type === 'accountType' &&
                <div className={'account-type'}>
                    Offer viewer
                </div>
                }

                {props.type === 'MerchantInfo' && <>
                    <div className={'pm-column-card-wrapper d-flex flex-column'}>
                        <div className={'pm-column-card d-flex justify-content-between'}>
                            <div className={'d-flex flex-column'}>
                                <div className={'pm-info-sub-title'}>
                                    Merchant name
                                </div>
                                <div className={'pm-merchant-name'}>
                                    {props.merchantName}
                                </div>
                            </div>
                            {/*<div className={'pm-edit'}>*/}
                            {/*    Edit*/}
                            {/*</div>*/}
                        </div>

                        <div className={'d-flex justify-content-between p16'}>
                            <div className={'d-flex flex-column'}>
                                <div className={'pm-info-sub-title'}>
                                    Business registration no
                                </div>
                                <div className={'pm-merchant-name'}>
                                    {props.BRno}
                                </div>
                                <div className={'pm-verify-status'}>
                                    unverified
                                </div>
                            </div>

                            {/*<div className={'pm-edit'}>*/}
                            {/*    Edit*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </>

                } {props.type === 'customerInfo' && <>
                    <div className={'pm-column-card-wrapper d-flex flex-column'}>
                        <div className={'pm-column-card d-flex justify-content-between'}>
                            <div className={'d-flex flex-column'}>
                                <div className={'pm-info-sub-title'}>
                                    First name
                                </div>
                                <div className={'pm-merchant-name'}>
                                    {props.merchantName}
                                </div>
                            </div>
                            {/*<div className={'pm-edit'}>*/}
                            {/*    Edit*/}
                            {/*</div>*/}
                        </div>

                        <div className={'d-flex justify-content-between p16'}>
                            <div className={'d-flex flex-column'}>
                                <div className={'pm-info-sub-title'}>
                                    Contact No
                                </div>
                                <div className={'pm-merchant-name'}>
                                    {props.BRno}
                                </div>
                                <div className={'pm-verify-status'}>
                                    unverified
                                </div>
                            </div>

                            {/*<div className={'pm-edit'}>*/}
                            {/*    Edit*/}
                            {/*</div>*/}
                        </div>
                    </div>
                </>
                }

                {props.type === 'ContactInfo' && <>
                    <div className={'pm-column-card-wrapper d-flex flex-column'}>
                        <div className={'pm-column-card d-flex flex-column'}>
                            <div className={'pm-info-sub-title'}>
                                Email address
                            </div>
                            <div className={'pm-merchant-name'}>
                                {props.email}
                            </div>
                            <div className={'pm-verify-status'}>
                                unverified
                            </div>
                        </div>

                        <div className={'pm-column-card d-flex justify-content-between p16'}>
                            <div className={'d-flex flex-column'}>
                                <div className={'pm-info-sub-title'}>
                                    Address
                                </div>
                                <div className={'pm-merchant-name'}>
                                    {props.address}
                                </div>
                            </div>
                            <div className={'pm-edit'}>
                                Edit
                            </div>
                        </div>

                        <div className={'d-flex justify-content-between p16'}>
                            <div className={'d-flex flex-column'}>
                                <div className={'pm-info-sub-title'}>
                                    Phone number
                                </div>
                                <div className={'pm-merchant-name'}>
                                    {props.phoneNo}
                                </div>
                                <div className={'pm-verify-status'}>
                                    unverified
                                </div>
                            </div>

                            <div className={'pm-edit'}>
                                Edit
                            </div>
                        </div>
                    </div>
                </>
                }


                {props.type === 'Other' && <>
                    <div className={'pm-column-card-wrapper d-flex flex-column '}>
                        <div className={'pm-column-card d-flex justify-content-between'}>
                            <div className={'d-flex flex-column'}>
                                <div className={'pm-info-sub-title'}>
                                    Tags
                                </div>
                                <div className={'pm-merchant-name pt-1 d-flex'}>
                                    {props.tags && props.tags.map((post, index) =>
                                        <>
                                            <Tag text={getCategoryById(props.tags[index])}/>
                                        </>
                                    )}
                                </div>
                                <div className={'pm-verify-status pt-1 example'}>
                                    (Ex : Hotel & Restaurant, Hotel)
                                </div>
                            </div>

                            <div className={'pm-edit'}
                                 onClick={() => props.onClickOpenTag()}>
                                {props.tags ? 'Add' : 'Edit'}
                            </div>
                        </div>

                        <div className={'d-flex justify-content-between p16'}>

                            <div className={'d-flex flex-column'}>
                                <div className={'pm-info-sub-title'}>
                                    Location
                                </div>
                                <div className={'pm-merchant-name pt-1 d-flex'}>
                                    {props.location && props.location.map((post, index) =>
                                        <>
                                            <Tag text={'Food & Restaurants'}/>
                                            <Tag text={'Travel'}/>
                                        </>
                                    )}
                                </div>
                                <div className={'pm-verify-status pt-1 example'}>
                                    (Ex : Ella, Bandarawela, Colombo 4)
                                </div>
                            </div>


                            <div className={'pm-edit'}>
                                {props.location ? 'Add' : 'Edit'}
                            </div>
                        </div>


                    </div>
                </>}
            </div>

        </div>
    )
};

Index.propTypes = {};

Index.defaultProps = {};

export default Index;
