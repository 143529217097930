import React, { Component } from "react";
import "./style.scss";
import { RiUploadCloud2Line, RiAddLine, RiCheckLine } from "react-icons/ri";
import { getToken, userDetails } from "../../../../utils";
import * as actionTypes from "../../../../store/actions/types";
import * as tempApi from "../../../../service/tempApi";
import { connect } from "react-redux";
import { GetIcon } from "../../../../config/icon";
import {
  ConsoleHeader,
  Button,
  Input,
  TagBox,
  CheckBox,
  ButtonIcon,
  ProfileViewCardUpload,
  ProfileViewCard,
} from "../../../../components";
import { openNotification } from "../../../../config/notification";
import CategoryTag from "../../../categoryTag";
import LocationTag from "../../../locationTag";
import axios from "axios";
import * as config from "../../../../config/baseUrls";
import ReactCrop from "react-image-crop";

class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      base64: null,
      isUploading: false,
      isEnableEdit: false,
    };
  }

  componentDidMount() {}

  toBase64 = () => {
    console.log("event fire...");
    const _token = getToken();

    let _this = this;
    const file = document.querySelector("#customerProfileUpload").files[0];
    const reader = new FileReader();
    if (file) {
      reader.addEventListener("error", () => {
        console.error(`Error occurred reading file: ${file.name}`);
      });
      console.log(file);
      console.log(reader.result);
      reader.readAsDataURL(file);

      reader.onload = function () {
        let _base64 = reader.result;
        let data = { base64: _base64 };
        tempApi.createTempImage(data, function (res) {
          console.log(res);
        });
      };
    } else {
      console.log("event onabort....");
    }
  };

  onSelectFile = (e) => {
    console.log("event fire...");
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  //onclick enable edit mode
  onClickEnableEditMode = () => {
    this.setState({
      isEnableEdit: !this.isEnableEdit,
    });
  };

  render() {
    const {
      dataForm,
      isEnableEdit,
      base64,
      test,
      isAttachment,
      crop,
      croppedImageUrl,
      src,
      isUploadImage,
      viewPointHeight,
    } = this.state;
    const { privateUser } = this.props;

    return (
      <>
        {/*----- image crop section wrapper ------*/}
        {src && (
          <div className={"container"}>
            <div className={"col-lg-12"}>
              <div
                className={"modal-body-wrapper"}
                style={{ height: viewPointHeight }}
              >
                <div
                  className={"m-header-wrapper d-flex justify-content-between"}
                >
                  <div className={"m-h-title"}>Customer image upload</div>
                  <div
                    className={"m-h-close"}
                    onClick={() => this.onClickCloseUploadImage()}
                  >
                    Close
                  </div>
                </div>
                <div
                  className={
                    "image-crop-preview d-flex align-items-center justify-content-center"
                  }
                >
                  <div className={"profile-logo-view-wrapper"}>
                    {croppedImageUrl && (
                      <img alt="Crop" src={croppedImageUrl} />
                    )}
                  </div>
                  <div className={"upload-action-wrapper"}>
                    <div className={"profile-logo-wrapper ml-2 d-flex"}>
                      <div className={`BRattachment-wrapper light`}>
                        <>
                          <input
                            id="uploadNew"
                            type="file"
                            accept="image/*"
                            onChange={this.onSelectFile}
                            hidden
                          />
                          <div
                            className={"d-flex"}
                            onClick={() => {
                              document.getElementById("uploadNew").click();
                            }}
                          >
                            <div className={`right-icon-wrapper`}>
                              <ButtonIcon
                                type={"dark"}
                                buttonIcon={GetIcon("upload")}
                                size={"sm"}
                              />
                            </div>
                            <div className={"text"}>Upload New Image</div>
                          </div>
                        </>
                      </div>

                      <div className={"BRattachment-wrapper ml-3 light"}>
                        {!isUploadImage && (
                          <div
                            className={"d-flex"}
                            onClick={() => this.onClickUploadImage()}
                          >
                            <div className={`right-icon-wrapper`}>
                              <ButtonIcon
                                type={"dark"}
                                buttonIcon={GetIcon("check")}
                                size={"sm"}
                              />
                            </div>
                            <div className={"text"}>Save</div>
                          </div>
                        )}

                        {isUploadImage && (
                          <div className={"d-flex"}>
                            <div className={`right-icon-wrapper`}>
                              <ButtonIcon
                                type={"dark"}
                                buttonIcon={GetIcon("upload")}
                                size={"sm"}
                              />
                            </div>
                            <div className={"text"}>Uploading...</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {src && (
                  <div
                    className={
                      "d-flex align-item-center justify-content-center"
                    }
                  >
                    <ReactCrop
                      src={src}
                      crop={crop}
                      ruleOfThirds
                      onImageLoaded={this.onImageLoaded}
                      onComplete={this.onCropComplete}
                      onChange={this.onCropChange}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        <CategoryTag title={"Please select interested area"} />

        <div className={"up-console-profile"}>
          <ConsoleHeader
            isEnableEdit={isEnableEdit}
            onClickEnableEditMode={() => this.onClickEnableEditMode()}
            onClickUpdate={() => this.onClickUpdateProfile()}
            title={"Customer Profile"}
          />

          <div className={"up-console-body-wrapper d-flex flex-column"}>
            {/*merchant logo*/}
            <ProfileViewCardUpload
              title={"Customer profile"}
              onSelectFile={this.onSelectFile.bind(this)}
            />

            <ProfileViewCard
              type={"accountType"}
              padding={"pt24"}
              catData={this.props?.catData}
              title={"Account type"}
            />

            <ProfileViewCard
              type={"customerInfo"}
              merchantName={this.props.privateUser?.businessName}
              BRno={this.props.privateUser?.BRno}
              isEdit={true}
              padding={"pt24"}
              catData={this.props.catData}
              title={"Customer info"}
            />

            <ProfileViewCard
              type={"Other"}
              tags={this.props.privateUser?.favoriteArea}
              location={this.props.privateUser?.location}
              onClickOpenTag={() => this.props.onClickOpenCategoryTag()}
              isEdit={true}
              catData={this.props?.catData}
              privateUser={this.props?.privateUser}
              padding={"pt24"}
              title={"Other"}
            />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    locData: state.location.data,
    privateUser: state.user.privateUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClickOpenSignIn: () =>
      dispatch({ type: actionTypes.OPEN_SIGN_IN, status: true }),
    onClickOpenCategoryTag: () =>
      dispatch({ type: actionTypes.OPEN_TAG, status: true }),
    onClickOpenLocationTag: () =>
      dispatch({ type: actionTypes.OPEN_LOCATION_TAG, status: true }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
