import React from "react";
import "./styles.scss";
import { GetIcon } from "../../../config/icon";

const Index = (props) => {
  return (
    <>
      <div
        className={`navigator-wrapper ${props.size}`}
        onClick={props.onClick}
      >
        <div className={"nw__icon"}>{GetIcon(props.icon)}</div>
        <div className={"nw__title"}>{props.navigationTitle}</div>
      </div>
    </>
  );
};

Index.propTypes = {};

Index.defaultProps = {};

export default Index;
