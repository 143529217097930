import React from "react";
import "./styles.scss";
import "./mobile.scss";

const Index = (props) => (
  <>
    <div
      className={`career-card-main-wrapper  align-items-center container  ${props.type} `}
    >
      <div className="ccw_details_wrapper container">
        <div className="ccw_dw_title">{props.title}</div>
        <div className="ccw_dw_location">Colombo</div>
      </div>
      <div className="ccw_subDetails_wrapper container">
        <div className="ccw_sw_date">{props.date}</div>
        <div className="ccw_sw_status">full-remote</div>
      </div>
    </div>
  </>
);

Index.propTypes = {};

Index.defaultProps = {};

export default Index;
