import axios from "axios";

import { getToken, userDetails } from "../utils";
import * as config from "../config/baseUrls";
import * as actionTypes from "../store/actions/types";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const token = getToken();

export const getLoginDetails = () => (dispatch) => {
  const user = userDetails();
  console.log("actionTypes.GET_USER_DETAIL");
  console.log(user);
  dispatch({
    type: actionTypes.GET_USER_DETAILS,
    data: user,
  });
};

export const getUserByUserType = (userType) => (dispatch) => {
  console.log("getUserByUserType");
  dispatch({
    type: actionTypes.GET_USER_BY_TYPE_PENDING,
    data: [],
  });
  axios
    .get(config.baseUrl + "user/" + userType, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(function (response) {
      dispatch({
        type: actionTypes.GET_USER_BY_TYPE,
        data: response.data.data,
      });
    })
    .catch(function (error) {
      dispatch({
        type: actionTypes.GET_USER_BY_TYPE_FAILURE,
        data: [],
        error: error,
      });
    });
};

export const getLoginUserDetailsById = (id) => (dispatch) => {
  console.warn("event fire get login user details....");
  const _token = getToken();
  dispatch({
    type: actionTypes.GET_PRIVATE_USER_DETAILS_BY_ID_PENDING,
    data: [],
  });
  axios
    .get(config.baseUrl + "user/" + id, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + _token,
      },
    })
    .then(function (response) {
      console.log("getLoginUserDetailsById user API");
      console.log(JSON.stringify(response.data.data));
      console.log("==================");
      dispatch({
        type: actionTypes.GET_PRIVATE_USER_DETAILS_BY_ID,
        data: response.data.data,
      });
    })
    .catch(function (error) {
      console.error(error);
      dispatch({
        type: actionTypes.GET_PRIVATE_USER_DETAILS_BY_ID_FAILURE,
        data: [],
      });
    });
};

export const getLoginUserDetailsByIdCallBack = (id, callback) => {
  const _token = getToken();
  axios
    .get(config.baseUrl + "user/" + id, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + _token,
      },
    })
    .then(function (response) {
      callback(response.data.data);
    })
    .catch(function (error) {
      callback(error);
    });
};

export const userExists = (data, callback) => {
  axios
    .get(config.baseUrl + "user/exists/" + data.userName, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(function (response) {
      callback(response);
    })
    .catch(function (error) {
      callback(false);
    });
};

export const updateFavoriteArea = (categoryTag, callback) => {
  axios
    .post(
      config.baseUrl + "user/tag/update",
      {
        tags: categoryTag,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      }
    )
    .then(function (response) {
      callback(response);
    })
    .catch(function (error) {
      callback(false);
    });
};

export const isExistingUserMobileNo = async (mobileNo) => {
  try {
    const options = { data: {} };
    // Get response
    let response = await axios.get(
      `${BASE_URL}/find/existing/user/by/${mobileNo}`,
      options
    );
    return response.data;
  } catch (err) {
    console.error("ERR_GET_ALL_OFFER_API:", err);
    throw err;
  }
};

export const getUserById = async (userId) => {
  try {
    const token = getToken();
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
    // Get response
    let response = await axios.get(`${BASE_URL}/get/user/by/${userId}`, {
      headers: headers,
    });
    return response.data;
  } catch (err) {
    console.error("ERR_GET_ALL_OFFER_API:", err);
    throw err;
  }
};

export const createUser = async (
  userType,
  businessName,
  BRno,
  mobileNo,
  firstName,
  password,
  termsAndCondition,
  location = [],
  favoriteArea = []
) => {
  try {
    const body = {
      firstName: firstName,
      businessName: businessName,
      BRno: BRno,
      mobileNo: mobileNo,
      userType: userType,
      favoriteArea: favoriteArea,
      termsAndCondition: termsAndCondition,
      password: password,
    };
    // Get response
    let response = await axios.post(`${BASE_URL}/create/new/user`, body);
    return response;
  } catch (err) {
    console.error("ERR_CREATE_NEW_USER:", err);
    throw err;
  }
};

export const signIn = async ({ mobileNo, password, userId }) => {
  try {
    const body = {
      mobileNo: mobileNo,
      password: password,
      userId: userId,
    };
    // Get response
    return await axios.post(`${BASE_URL}/user/authentication`, body);
  } catch (err) {
    console.error("ERR_SIGN_IN:", err);
    throw err;
  }
};

export const otpSend = async (userId, mobileNo, token) => {
  try {
    const body = {
      userId: userId,
      mobileNo: mobileNo,
      token: token,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    // Get response
    return await axios.post(`${BASE_URL}/resend/verification/otp`, body, {
      headers,
    });
  } catch (err) {
    console.error("ERR_SIGN_IN:", err);
    return false;
  }
};

export const otpVerify = async (userId, OTP, token) => {
  try {
    const body = {
      otpCode: OTP,
      token: token,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    // Get response
    let response = await axios.post(`${BASE_URL}/verify/mobile`, body, {
      headers: headers,
    });
    return response;
  } catch (err) {
    console.error("ERR_SIGN_IN:", err);
    throw err;
  }
};

export const newOTPVerify = async (
  userId,
  verificationType,
  token,
  mobileNo
) => {
  try {
    const body = {
      verificationType: verificationType,
      token: token,
      mobileNo: mobileNo,
      userId: userId,
    };
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    // Get response
    let response = await axios.post(
      `${BASE_URL}/create/new/otp/verification`,
      body,
      {
        headers: headers,
      }
    );
    return response;
  } catch (err) {
    console.error("ERR_SIGN_IN:", err);
    throw err;
  }
};

///session/validate/:token
export const updatePassword = async (userId, password) => {
  try {
    const body = {
      userId: userId,
      password: password,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    // Get response
    let response = await axios.put(`${BASE_URL}/update/user/password`, body, {
      headers: headers,
    });
    return response;
  } catch (err) {
    console.error("ERR_USER_FAVORITE:", err);
    throw err;
  } finally {
  }
};

export const addOfferToUserFavorite = async (
  offerId,
  categoryId,
  merchantId,
  bankId
) => {
  try {
    const body = {
      offerId: offerId,
      categoryId: categoryId,
      merchantId: merchantId,
      bankId: bankId,
    };
    const token = getToken();
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
    // Get response
    let response = await axios.put(
      `${BASE_URL}/add/user/favorite/offer`,
      body,
      {
        headers: headers,
      }
    );
    return response;
  } catch (err) {
    console.error("ERR_USER_FAVORITE:", err);
    throw err;
  }
};

export const removeOfferFromUserFavorite = async (offerId) => {
  try {
    const body = {
      offerId: offerId,
    };
    const token = getToken();
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
    // Get response
    let response = await axios.put(
      `${BASE_URL}/remove/offer/from/user/favorite`,
      body,
      {
        headers: headers,
      }
    );
    return response;
  } catch (err) {
    console.error("ERR_USER_FAVORITE:", err);
    throw err;
  }
};

export const getFavoriteOfferByUser = async (userId) => {
  try {
    const token = getToken();
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
    // Get response
    let response = await axios.get(
      `${BASE_URL}/get/offer/favorite/by/` + userId,
      {
        headers: headers,
      }
    );
    return response;
  } catch (err) {
    console.error("ERR_USER_FAVORITE:", err);
    throw err;
  } finally {
  }
};
///session/validate/:token
export const getSessionValidateStatus = async () => {
  try {
    const token = getToken();
    console.log("getSessionValidateStatus", token);
    const headers = {
      "Content-Type": "application/json",
    };
    // Get response
    let response = await axios.get(`${BASE_URL}/session/validate/` + token, {
      headers: headers,
    });
    return response;
  } catch (err) {
    console.error("ERR_USER_FAVORITE:", err);
    throw err;
  } finally {
  }
};
