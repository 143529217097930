import React from 'react';
import PropTypes from 'prop-types';
import './style.scss'



const Index = (pro) => (
    <>

        <div className={'interesting-selection-wrapper d-flex flex-wrap'}>

        </div>


    </>
);

Index.propTypes = {
    userType: PropTypes.string.isRequired,
};

Index.defaultProps = {
    userType: 1,
};



export default Index;
