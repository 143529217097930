export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";

//CATEGORY ACTIONS
export const CATEGORY_REQUEST_PENDING = "CATEGORY_REQUEST_PENDING";
export const CATEGORY_REQUEST_SUCCESS = "CATEGORY_REQUEST_SUCCESS";
export const CATEGORY_REQUEST_FAILURE = "CATEGORY_REQUEST_FAILURE";

export const GET_ALL_CATEGORY = "GET_ALL_CATEGORY";
export const SET_ALL_CATEGORY = "SET_ALL_CATEGORY";
export const GET_LOCAL_CATEGORY = "GET_LOCAL_CATEGORY";
export const ADD_CATEGORY = "ADD_CATEGORY";
export const ON_REMOVE_CATEGORY = "ON_REMOVE_CATEGORY";
export const GET_CATEGORY_BY_ID = "GET_CATEGORY_BY_ID";
export const SELECTED_CATEGORY = "SELECTED_CATEGORY";
export const SELECTED_CATEGORY_GROUP = "SELECTED_CATEGORY_GROUP";
export const SELECTED_CATEGORY_CLEAR = "SELECTED_CATEGORY_CLEAR";

export const SET_ALL_BANK = "SET_ALL_BANK";

export const GET_LOCATION_PENDING = "GET_LOCATION_PENDING";
export const GET_ALL_LOCATION = "GET_ALL_LOCATION";
export const GET_LOCATION_FAILURE = "GET_LOCATION_FAILURE";
export const ADD_LOCATION = "ADD_LOCATION";

export const GET_EVENT_PENDING = "GET_EVENT_PENDING";
export const GET_EVENT_BY_ID = "GET_EVENT_BY_ID";
export const GET_ALL_EVENT = "GET_ALL_EVENT";
export const GET_EVENT_FAILURE = "GET_EVENT_FAILURE";
export const ADD_EVENT = "ADD_EVENT";

export const GET_TAG_PENDING = "GET_TAG_PENDING";
export const GET_ALL_TAG = "GET_ALL_TAG";
export const GET_TAG_FAILURE = "GET_TAG_FAILURE";
export const ADD_TAG = "ADD_TAG";
export const GET_CATEGORY_BY_TAG_PENDING = "GET_CATEGORY_BY_TAG";
export const GET_CATEGORY_BY_TAG = "GET_CATEGORY_BY_TAG";
export const GET_CATEGORY_BY_TAG_FAILURE = "GET_CATEGORY_BY_TAG_FAILURE";
export const UPDATE_ADD_TAG = "UPDATE_ADD_TAG";
export const CLEAR_TAG = "CLEAR_TAG";
export const SELECTED_TAG = "SELECTED_TAG";
export const GET_SELECTED_TAG = "GET_SELECTED_TAG";

export const GET_ARTIST_PENDING = "GET_ARTIST_PENDING";
export const GET_ALL_ARTIST = "GET_ALL_ARTIST";
export const GET_ARTIST_FAILURE = "GET_ARTIST_FAILURE";
export const ADD_ARTIST = "ADD_ARTIST";

export const GET_USER_DETAILS = "GET_USER_DETAILS";
export const ADD_LOGIN_USER = "ADD_LOGIN_USER";
export const USER_LOGGED = "USER_LOGGED";
export const GET_USER_BY_TYPE_PENDING = "GET_USER_BY_TYPE_PENDING";
export const GET_USER_BY_TYPE = "GET_USER_BY_TYPE";
export const GET_USER_BY_TYPE_FAILURE = "GET_USER_BY_TYPE_FAILURE";
export const GET_PRIVATE_USER_DETAILS_BY_ID_PENDING =
  "GET_PRIVATE_USER_DETAILS_BY_ID_PENDING";
export const GET_PRIVATE_USER_DETAILS_BY_ID_FAILURE =
  "GET_PRIVATE_USER_DETAILS_BY_ID_FAILURE";
export const GET_PRIVATE_USER_DETAILS_BY_ID = "GET_PRIVATE_USER_DETAILS_BY_ID";
export const UPDATE_PRIVATE_USER_PROFILE_IMAGE =
  "UPDATE_PRIVATE_USER_PROFILE_IMAGE";

//modal types
export const OPEN_SIGN_UP = "OPEN_SIGN_UP";
export const CLOSED_SIGN_UP = "CLOSED_SIGN_UP";
export const CLOSED_SIGN_IN = "CLOSED_SIGN_IN";
export const OPEN_SIGN_IN = "OPEN_SIGN_IN";
export const OPEN_REGISTER = "OPEN_REGISTER";
export const CLOSE_REGISTER = "CLOSE_REGISTER";
export const CLOSE_MY_EVENT = "CLOSE_MY_EVENT";
export const OPEN_MY_EVENT = "OPEN_MY_EVENT";
export const LOG_OUT = "LOG_OUT";
export const OPEN_TAG = "OPEN_TAG";
export const CLOSE_TAG = "CLOSE_TAG";
export const OPEN_LOCATION_TAG = "OPEN_LOCATION_TAG";
export const CLOSE_LOCATION_TAG = "CLOSE_LOCATION_TAG";
export const OPEN_OTP_VERIFY = "OPEN_OTP_VERIFY";
export const CLOSE_OTP_VERIFY = "CLOSE_OTP_VERIFY";
export const SIGNUP_OTP_VERIFY = "SIGNUP_OTP_VERIFY";
export const SET_SIGNUP_USER_MOBILE = "SET_SIGNUP_USER_MOBILE";

export const CREATE_NEW_OFFER = "CREATE_NEW_OFFER";

//FUND TRANSFER
export const OPEN_OFFER_VIEW_MODAL = "OPEN_OFFER_VIEW_MODAL";
export const CLOSE_OFFER_VIEW_MODAL = "CLOSE_OFFER_CONFIRM_MODAL";

//FUND TRANSFER
export const FILTER_OPEN = "FILTER_OPEN";
export const FILTER_CLOSE = "FILTER_CLOSE";

export const OPEN_MOBILE_FAVORITE_LIST = "OPEN_MOBILE_FAVORITE_LIST";
export const CLOSE_MOBILE_FAVORITE_LIST = "CLOSE_MOBILE_FAVORITE_LIST";

export const OPEN_MORE_INFO_MODAL = "OPEN_MORE_INFO_MODAL ";
export const CLOSE_MORE_INFO_MODAL = "CLOSE_MORE_INFO_MODAL";

export const OPEN_MOBILE_PROFILE_VIEW = "OPEN_MOBILE_PROFILE_VIEW ";
export const CLOSE_MOBILE_PROFILE_VIEW = "CLOSE_MOBILE_PROFILE_VIEW";

export const OPEN_MOBILE_SIGN_IN_VIEW = "OPEN_MOBILE_SIGN_IN_VIEW ";
export const CLOSE_MOBILE_SIGN_IN_VIEW = "CLOSE_MOBILE_SIGN_IN_VIEW";

export const OPEN_UPDATE_MOBILE_NO = "OPEN_UPDATE_MOBILE_NO";
export const CLOSE_UPDATE_MOBILE_NO = "CLOSE_UPDATE_MOBILE_NO";

export const OPEN_PROFILE_VIEW = "OPEN_PROFILE_VIEW";
export const CLOSE_PROFILE_VIEW = "CLOSE_PROFILE_VIEW";

//search menu
export const OPEN_FILTER_ITEM = "OPEN_FILTER_ITEM";
export const CLOSE_FILTER_ITEM = "CLOSE_FILTER_ITEM";
export const SET_FILTER_OPTION = "SET_FILTER_OPTION";
export const SET_FILTER_OPTION_BANK = "SET_FILTER_OPTION_BANK";
export const SET_FILTER_OPTION_CATEGORY = "SET_FILTER_OPTION_CATEGORY";
export const CLEAR_FILTER_OPTION_CATEGORY = "CLEAR_FILTER_OPTION_CATEGORY";
export const CLEAR_FILTER_OPTION_BANK = "CLEAR_FILTER_OPTION_BANK";

//search menu
export const ON_LOAD_OFFER = "ON_LOAD_OFFER";
export const ON_LOADING_MORE_OFFER = "ON_LOADING_MORE_OFFER";
export const ON_LOADING_MORE_OFFER_COMPLETE = "ON_LOADING_MORE_OFFER_COMPLETE";
export const ON_COMPLETE_LOAD_OFFER = "ON_COMPLETE_LOAD_OFFER";
export const SET_OFFER_DATA_FILTER = "SET_OFFER_DATA_FILTER";
export const ON_LOAD_MORE_OFFER = "ON_LOAD_MORE_OFFER";
export const CLEAR_ALL_FILTER = "CLEAR_ALL_FILTER";

export const HIDE_LOADER = "HIDE_LOADER";
export const SHOW_LOADER = "SHOW_LOADER";

export const SET_USER_DATA = "SET_USER_DATA";

export const SET_USER_INSIGHT = "SET_USER_INSIGHT";
export const CLEAR_USER_INSIGHT = "CLEAR_USER_INSIGHT";
