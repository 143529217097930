import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss'

const Index = (props) => (
    <>
        <div className={`menu-card-wrapper d-flex 
        ${props.isActive ? 'active' : ''} ${props.type} ${props.size}`}
             onClick={props.onClick}>
            <div className={'mc-left-wrapper d-flex'}>
                <div className={'mc-left-icon'}>
                    {props.icon}
                </div>
                <div className={'mc-text'}>
                    {props.text}
                </div>
            </div>

        </div>
    </>
);

Index.propTypes = {
    text: PropTypes.string.isRequired,
};

Index.defaultProps = {
    text: 'text not found',
    icon: '<RiCloseFill/>'
};

export default Index;
