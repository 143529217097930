import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

import {
  RiStore3Line,
  RiShoppingBagLine,
  RiBuilding2Line,
} from "react-icons/ri";

import { renderIcon } from "../../../store/actions/categoryIcon";

const Index = (props) => (
  <>
    <div
      className={`item-navigation-wrapper d-flex justify-content-between align-items-center
        ${props.type} ${props.size} ${props.bankName} ${
        props.isActive ? "active" : ""
      }`}
      onClick={props.onClick}
    >
      <div
        className={
          "item-cn-left-wrapper d-flex align-items-center justify-content-between"
        }
      >
        <div
          className={`cn-image mr-3 ${
            props.isIcon ? "icon-wrapper" : props.bankName
          }`}
        >
          {props.isImg && <>{props.imgTag}</>}
          {props.isIcon && <>{renderIcon(props.bannerIcon)}</>}
        </div>
        <div className={"cn-text"}>{props.text}</div>
      </div>

      {!props.isArrowRight && (
        <div
          className={`item-arrow-icon ${
            props.isArrowRight ? "right-arrow" : "check-box"
          }
            ${props.isActive ? "active-check-box" : ""}`}
        >
          {props.isActive && <>{props.checkIcon}</>}
        </div>
      )}

      {props.isArrowRight && (
        <div
          className={`item-arrow-icon ${
            props.isArrowRight ? "right-arrow" : "check-box"
          }
            ${props.isActive ? "active-check-box" : ""}`}
        >
          {props.arrowRight}
        </div>
      )}
    </div>
  </>
);

Index.propTypes = {
  text: PropTypes.string.isRequired,
};

Index.defaultProps = {
  text: "text not found",
  icon: "<RiCloseFill/>",
};

export default Index;
