import * as actionTypes from "../actions/types";

const initialState = {
  data: [],
  error: null,
  loading: false,
  isOfferView: false,
  offerData: [],
  categoryData: [],
  bankData: [],
  signUp: false,
  signIn: false,
  isOTPVerification: false,
  isFilterOpen: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.OPEN_SIGN_UP:
      return {
        ...state,
        signUp: true,
        signIn: false,
        isRegister: state.isRegister,
      };
    case actionTypes.CLOSED_SIGN_UP:
      return {
        ...state,
        signUp: false,
        signIn: false,
        isCreateNewOffer: false,
        isRegister: state.isRegister,
      };
    case actionTypes.OPEN_SIGN_IN:
      return {
        ...state,
        signIn: true,
        signUp: false,
        isRegister: state.isRegister,
      };
    case actionTypes.CLOSED_SIGN_IN:
      return {
        ...state,
        signIn: false,
        signUp: false,
        isCreateNewOffer: false,
        isRegister: state.isRegister,
      };
    case actionTypes.OPEN_REGISTER:
      return {
        ...state,
        signIn: false,
        signUp: false,
        isRegister: true,
      };
    case actionTypes.CLOSE_REGISTER:
      return {
        ...state,
        signIn: false,
        signUp: false,
        isRegister: false,
      };
    case actionTypes.CLOSE_MY_EVENT:
      return {
        ...state,
        signIn: false,
        signUp: false,
        isRegister: false,
        isMyEvent: false,
      };
    case actionTypes.OPEN_MY_EVENT:
      return {
        ...state,
        signIn: false,
        signUp: false,
        isRegister: false,
        isMyEvent: true,
      };
    case actionTypes.CREATE_NEW_OFFER:
      return {
        ...state,
        signIn: false,
        signUp: false,
        isRegister: false,
        isCreateNewOffer: true,
        isMyEvent: true,
      };
    case actionTypes.OPEN_TAG:
      return {
        ...state,
        signIn: false,
        signUp: false,
        isRegister: false,
        isCreateNewOffer: false,
        isCategoryTag: true,
      };
    case actionTypes.CLOSE_TAG:
      return {
        ...state,
        signIn: false,
        signUp: false,
        isRegister: false,
        isCreateNewOffer: false,
        isCategoryTag: false,
      };
    case actionTypes.OPEN_LOCATION_TAG:
      return {
        ...state,
        signIn: false,
        signUp: false,
        isRegister: false,
        isCreateNewOffer: false,
        isCategoryTag: false,
        isLocationTag: true,
      };
    case actionTypes.CLOSE_LOCATION_TAG:
      return {
        ...state,
        signIn: false,
        signUp: false,
        isRegister: false,
        isCreateNewOffer: false,
        isCategoryTag: false,
        isLocationTag: false,
      };
    case actionTypes.OPEN_OFFER_VIEW_MODAL:
      console.log("OPEN_OFFER_VIEW_MODAL", action.payload);
      return {
        ...state,
        isOfferView: true,
        offerData: action.payload,
        categoryData: action.categoryData,
        bankData: action.bankData,
      };
    case actionTypes.CLOSE_OFFER_VIEW_MODAL:
      return {
        ...state,
        isOfferView: false,
      };
    case actionTypes.FILTER_OPEN:
      return {
        ...state,
        isFilterOpen: true,
      };
    case actionTypes.FILTER_CLOSE:
      return {
        ...state,
        isFilterOpen: false,
      };
    default:
      return state;
  }
};
