import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss'
import {RiCloseLine} from "react-icons/ri";

const Index = (props) => (
    <div
        key={props.key}
        className={`tag-select-box-wrapper ${props.isActive ? 'active' : ''}`}
        onClick={props.onClick}>
        <div className={`tag-select-box ${props.isActive ? 'active' : 'inactive'}`}>
            {props.option === 'tagClose' && props.isActive &&
            <div className={'active-box-icon'}><RiCloseLine/></div>}
            {props.option !== 'tagClose' && <div className={'active-box'}/>}
        </div>
        <div className={'rd-text'}>
            {props.text}
        </div>


    </div>

);

Index.propTypes = {};

Index.defaultProps = {};

export default Index;
