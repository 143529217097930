import axios from 'axios';
import {v4 as uuidv4} from 'uuid';

let store;

export const injectStore = _store => {
    if (_store) {
        store = _store
    }
};

// axios.interceptors.request.use(
//     async config => {
//         //read token from state
//         let accessToken = store.getState().profile.auth.access_token;
//         if (accessToken) {
//             config.headers.common.Authorization = `Bearer ${accessToken}`;
//         }
//         //read username from state
//         let username = store.getState().profile.user.username;
//         if (username) {
//             config.headers.common.username = username;
//         }
//         //read subscriptionId from state
//         let subscriptionId = store.getState().profile.user.subscriptionId;
//         if (subscriptionId) {
//             config.headers.common.subscriptionId = subscriptionId;
//         }
//         //set common headers
//         config.headers.common['Content-Type'] = "application/json";
//         config.headers.common['uuid'] = Math.floor(100000000 + Math.random() * 900000000);
//         let now = new Date();
//         config.headers.common['datetime'] = `${now.getDate()}-${now.getMonth().toString()}-${now.getFullYear()} ${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`;
//         return config;
//     },
//     error => {
//         return Promise.reject(error);
//     },
// );

// axios.interceptors.response.use(null, async error => {
//     // if (error.response.status === 401) {
//     //     //route to login
//     //     window.location.href = '/';
//     // } else {
//     //     return Promise.reject(error);
//     // }
// });

export * from './offer';
export * from './bank';
export * from './category';
export * from './insightApi';
export * from './impressionApi';
export * from './impressionApi';
export * from './subsribeApi';
