import * as actionTypes from "../actions/types";
import produce from "immer";

const initialState = {
  isMobileFavoriteList: false,
};

let newData = [];
export default produce((draft = initialState, action) => {
  const { type, payload } = action;
  console.log("mobileFavoriteReducer", payload);
  switch (type) {
    case actionTypes.OPEN_MOBILE_FAVORITE_LIST:
      draft.isMobileFavoriteList = true;
      return draft;
    case actionTypes.CLOSE_MOBILE_FAVORITE_LIST:
      draft.isMobileFavoriteList = false;
      return draft;
    default:
      return draft;
  }
});
