import axios from 'axios';
import {getToken} from '../utils';
import * as config from "../config/baseUrls";


export const uploadImage = async (cropBase64, callback) => {
    const _token = getToken();
    try {
        const res = await axios.post(config.baseUrl + 'temp/image/upload',
            {"base64": cropBase64},
            {
                headers: {
                    'Authorization': 'Bearer ' + _token,
                    'Content-Type': 'application/json'
                }
            });

        console.log('uploadImage');
        console.log(res.data);
        callback(res);

    } catch (e) {
        callback(e);
    }
    // axios.post(config.baseUrl + 'temp/image/upload',
    //     {"base64": cropBase64},
    //     {
    //         headers: {
    //             'Authorization': 'Bearer ' + _token,
    //             'Content-Type': 'application/json',
    //         }
    //     })
    //     .then(function (response) {
    //         callback(response);
    //     })
    //     .catch(function (error) {
    //         callback(error);
    //     });
};





















