import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

const Index = (props) => (
  <>
    {!props.disabled && (
      <div
        className={`card-navigation-wrapper d-flex justify-content-between align-items-center
        ${props.type} ${props.size} ${props.isState ? "active" : ""}`}
        onClick={props.onClick}
      >
        <div className={"cn-left-wrapper d-flex align-items-center"}>
          <div className={"cn-image mr-2"}>{props.imgTag}</div>
          <div className={"cn-text"}>
            {props.text}
            <div className="cn-title">{props.title}</div>
          </div>
        </div>
        <div className={"cn-right-icon"}>{props.icon}</div>
      </div>
    )}

    {props.disabled && (
      <div
        className={`card-navigation-wrapper disabled d-flex justify-content-between align-items-center
        ${props.type} ${props.size}`}
      >
        <div className={"cn-left-wrapper d-flex align-items-center"}>
          <div className={"cn-image mr-2"}>{props.imgTag}</div>
          <div className={"cn-text"}>{props.text}</div>
        </div>
      </div>
    )}
  </>
);

Index.propTypes = {
  text: PropTypes.string.isRequired,
};

Index.defaultProps = {
  text: "text not found",
  icon: "<RiCloseFill/>",
};

export default Index;
