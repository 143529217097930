import * as actionTypes from "../actions/types";
import produce from "immer";

const initialState = {
  data: [],
  users: [],
  isLogin: false,
  privateUser: null,
  error: null,
  loading: false,
  isPrivateUser: false,
  isOpenVerify: false,
  isOTPVerify: false,
  user: null,
  userMobileNo: null,
  token: null,
};
let newData = [];

function $set(...objects) {
  return Object.assign({}, ...objects);
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_DETAILS:
      return {
        ...state,
        error: null,
        data: action.data,
        loading: false,
      };
    case actionTypes.ADD_LOGIN_USER:
      console.log("User::", action?.loginUser);
      return {
        ...state,
        error: null,
        data: action.loginUser,
        loading: false,
      };
    case actionTypes.SET_USER_DATA:
      return {
        ...state,
        privateUser: action.user,
        loading: false,
      };
    case actionTypes.USER_LOGGED:
      return {
        ...state,
        isLogin: true,
      };
    case actionTypes.GET_USER_BY_TYPE_PENDING:
      return {
        ...state,
        error: null,
        users: [],
        loading: true,
      };
    case actionTypes.GET_USER_BY_TYPE:
      return {
        ...state,
        error: null,
        users: action.data,
        loading: false,
      };
    case actionTypes.GET_USER_BY_TYPE_FAILURE:
      return {
        ...state,
        error: action.error,
        users: [],
        loading: false,
      };
    case actionTypes.LOG_OUT:
      return {
        ...state,
        error: null,
        users: [],
        isLogin: false,
        loading: false,
      };
    case actionTypes.SET_SIGNUP_USER_MOBILE:
      return {
        ...state,
        userMobileNo: action.payload,
      };
    case actionTypes.SIGNUP_OTP_VERIFY:
      return {
        ...state,
        isOpenVerify: true,
        user: action.payload.user,
        token: action.payload.token,
      };
    case actionTypes.OPEN_OTP_VERIFY:
      return {
        ...state,
        isOTPVerify: true,
        user: action.payload.user,
        token: action.payload.token,
      };
    case actionTypes.CLOSE_OTP_VERIFY:
      return {
        ...state,
        isOpenVerify: false,
        isOTPVerify: false,
        user: "",
        token: "",
      };
    case actionTypes.GET_PRIVATE_USER_DETAILS_BY_ID_PENDING:
      return {
        ...state,
        error: null,
        privateUser: [],
        loading: true,
      };
    case actionTypes.GET_PRIVATE_USER_DETAILS_BY_ID:
      console.warn(actionTypes.GET_PRIVATE_USER_DETAILS_BY_ID);
      return {
        ...state,
        error: null,
        privateUser: action.data,
        loading: false,
      };
    case actionTypes.GET_PRIVATE_USER_DETAILS_BY_ID_FAILURE:
      return {
        ...state,
        error: action.error,
        privateUser: [],
        loading: false,
      };

    case actionTypes.UPDATE_PRIVATE_USER_PROFILE_IMAGE:
      console.warn(actionTypes.UPDATE_PRIVATE_USER_PROFILE_IMAGE);
      console.log(action);
      console.log(state.privateUser);
      state.privateUser.logoUrl = action.imageId;
      console.log(state.privateUser.logoUrl);
      return {
        ...state,
        error: null,
        privateUser: state.privateUser,
        loading: false,
      };
    default:
      return state;
  }
};
