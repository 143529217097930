import React, {Component} from "react";
import './style.scss'
import {
    RiUploadCloud2Line,
    RiAddLine,
    RiCheckLine,
    RiFileUploadLine,

} from "react-icons/ri";
import {
    userDetails,
    getToken
} from '../../../../utils';
import * as actionTypes from "../../../../store/actions/types";
import {connect} from "react-redux";
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import {openNotification} from "../../../../config/notification";
import {
    ConsoleHeader,
    Button,
    Input,
    TagBox,
    CheckBox,
    ProfileViewCard,
    ButtonIcon,
    ProfileViewCardUpload, CheckItem
} from '../../../../components'

import CategoryTag from "../../../categoryTag";
import {getLoginUserDetailsById, getLoginUserDetailsByIdCallBack} from "../../../../service/userApi";
import {uploadImage} from "../../../../service/uploadImage";
import axios from "axios";
import * as config from "../../../../config/baseUrls";
import {getAllCategory} from "../../../../service/categoryApi";


class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isEnableEdit: false,
            base64: null,
            cropBase64: null,
            fileName: null,
            isAttachment: null,
            src: null,
            isUploadImage: false,
            // imageRef:null,
            crop: {
                unit: 'px', // default, can be 'px' or '%'
                width: 100,
                x: 130,
                y: 50,
                aspect: 4 / 4
            },
            dataForm: {
                companyName: {
                    key: 'companyName',
                    elementType: 'input',
                    elementConfig: {
                        type: 'companyName',
                        placeholder: 'Ex : companyName',
                        disabled: true,
                    },
                    value: '',
                    validation: {
                        required: true,

                    },
                    valid: false,
                    touched: false,
                    label: 'Company Name'
                },
                companyEmail: {
                    key: 'companyEmail',
                    elementType: 'input',
                    elementConfig: {
                        type: 'companyEmail',
                        placeholder: 'Ex : companyEmail',
                        disabled: true
                    },
                    value: '',
                    validation: {
                        required: true,

                    },
                    valid: false,
                    touched: false,
                    label: 'Company Email',
                    isVerification: false
                },
                contactNo: {
                    key: 'contactNo',
                    elementType: 'input',
                    elementConfig: {
                        type: 'contactNo',
                        placeholder: 'Ex : contactNo',
                        disabled: true,
                    },
                    value: '',
                    validation: {
                        required: true,

                    },
                    valid: false,
                    touched: false,
                    label: 'Contact No'
                },
                BRno: {
                    key: 'BRno',
                    elementType: 'input',
                    elementConfig: {
                        type: 'BRno',
                        placeholder: 'Ex : contactNo',
                        disabled: true,
                    },
                    value: '',
                    validation: {
                        required: true,

                    },
                    valid: false,
                    touched: false,
                    label: 'Business Registration No',
                    isVerification: false
                },
                addressNo: {
                    key: 'addressNo',
                    elementType: 'input',
                    elementConfig: {
                        type: 'addressNo',
                        placeholder: 'Ex : addressNo',
                        disabled: true
                    },
                    value: '',
                    validation: {
                        required: true,

                    },
                    valid: false,
                    touched: false,
                    label: 'Street No'
                },
                streetName: {
                    key: 'streetName',
                    elementType: 'input',
                    elementConfig: {
                        type: 'streetName',
                        placeholder: 'Ex : streetName',
                        disabled: true
                    },
                    value: '',
                    validation: {
                        required: true,

                    },
                    valid: false,
                    touched: false,
                    label: 'Street Name'
                },
                city: {
                    key: 'city',
                    elementType: 'dropdown',
                    elementConfig: {
                        type: 'city',
                        placeholder: 'Ex : city',
                        disabled: true
                    },
                    value: '',
                    validation: {
                        required: true,

                    },
                    valid: false,
                    touched: false,
                    label: 'Street Name'
                }
            }
        };
    }

    componentDidMount() {
        console.log('merchant profile loading....');
    }

    //=== checkValidity
    checkValidity(value, rules) {
        let isValid = true;

        if (rules.required) {
            isValid = value.trim() !== '' && isValid;
        }

        if (rules.email) {
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            isValid = re.test(String(value).toLowerCase()) && isValid
        }
        return isValid;
    }

    //==== input Handle Change
    inputHandleChange = (event, inputIdentity) => {
        const updateSignUpForm = {
            ...this.state.dataForm
        };
        const updatedFormElement = {
            ...updateSignUpForm[inputIdentity]
        };

        updatedFormElement.value = event.target.value;
        updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        updatedFormElement.touched = true;
        updateSignUpForm[inputIdentity] = updatedFormElement;
        let formIsValid = true;
        for (let inputIdentifier in updateSignUpForm) {
            formIsValid = updateSignUpForm[inputIdentifier].valid && formIsValid;
        }
        this.setState({dataForm: updateSignUpForm, formIsValid: formIsValid});
    };


    //onclick enable edit mode
    onClickEnableEditMode = () => {
        //let state = userDetails().loginUser;
        //this.props.getLoginUserDetailsById(state.id);

    };

    //onclick update profile
    onClickUpdateProfile = () => {

    };


    //profile image upload
    toBase64 = () => {
        console.log('event fire...');
        let _this = this;
        const file = document.querySelector('#BRattachment').files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            _this.setState({
                base64: reader.result,
                fileName: file.name,
            });
            // console.log(reader.result);
            console.log(file.name);
        };
    };

    onSelectFile = e => {
        console.log('event fire...');
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener('load', () =>
                this.setState({src: reader.result})
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg'
            );
            const imageRefBase64 = this.imageRef.src;
            this.setState({
                base64: imageRefBase64,
                croppedImageUrl
            });
        }
    }

    // If you setState the crop in here you should return false.
    onImageLoaded = image => {
        this.imageRef = image;
    };
    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({crop});
    };

    onClickConfirmUpload = () => {
        console.log('event fire yes');
    };

    onClickUploadImage = () => {
        console.log('event fire...');
        let _this = this;
        this.setState({
            isUploadImage: true
        });

        uploadImage(this.state.cropBase64, function (res) {
            let data = res.data && res.data.data;
            _this.props.updateProfileImage(data && data.pictureName);
            console.log(data && data.pictureName);
            if (res.status) {
                _this.setState({
                    src: null,
                    isUploadImage: false
                });
                return true;

            } else {
                // openNotification(
                //     {
                //         title: 'Image Upload',
                //         message: 'Profile Image upload unsuccessful.Try again',
                //         type: 'danger',
                //         insert: 'top',
                //         container: 'top-center'
                //     });
            }
        });
    };

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        const base64Image = canvas.toDataURL('image/jpeg');
        this.setState({
            cropBase64: base64Image
        });
        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve(this.fileUrl);
            }, 'image/jpeg');
        });
    }

    onClickCloseUploadImage = () => {
        this.setState({
            src: null
        })
    };

    render() {
        const {
            dataForm, isEnableEdit,
            base64, test, isAttachment, crop, croppedImageUrl, src, isUploadImage,
            viewPointHeight
        } = this.state;
        const {privateUser} = this.props;

        return (
            <>

                {/*----- image crop section wrapper ------*/}
                {src && (<div className={'container'}>
                    <div className={'col-lg-12'}>
                        <div className={'modal-body-wrapper'}
                             style={{height: viewPointHeight}}>

                            <div className={'m-header-wrapper d-flex justify-content-between'}>
                                <div className={'m-h-title'}>
                                    Merchant Logo Upload
                                </div>
                                <div className={'m-h-close'}
                                     onClick={() => this.onClickCloseUploadImage()}>
                                    Close
                                </div>
                            </div>
                            <div
                                className={'image-crop-preview d-flex align-items-center justify-content-center'}>
                                <div className={'profile-logo-view-wrapper'}>
                                    {croppedImageUrl && (
                                        <img alt="Crop" src={croppedImageUrl}/>
                                    )}
                                </div>
                                <div className={'upload-action-wrapper'}>
                                    <div className={'profile-logo-wrapper ml-2 d-flex'}>
                                        <div className={`BRattachment-wrapper light`}>
                                            <>
                                                <input
                                                    id="uploadNew"
                                                    type='file'
                                                    accept="image/*"
                                                    onChange={this.onSelectFile}
                                                    hidden/>
                                                <div className={'d-flex'}
                                                     onClick={() => {
                                                         document.getElementById("uploadNew").click();
                                                     }}>
                                                    <div
                                                        className={`right-icon-wrapper`}>
                                                        <ButtonIcon
                                                            type={'dark'}
                                                            buttonIcon={<RiFileUploadLine/>}
                                                            size={'sm'}/>
                                                    </div>
                                                    <div className={'text'}>
                                                        Upload New Image
                                                    </div>
                                                </div>
                                            </>
                                        </div>

                                        <div className={'BRattachment-wrapper ml-3 light'}>
                                            {!isUploadImage &&
                                            <div className={'d-flex'}
                                                 onClick={() => this.onClickUploadImage()}>
                                                <div
                                                    className={`right-icon-wrapper`}>
                                                    <ButtonIcon
                                                        type={'dark'}
                                                        buttonIcon={<RiCheckLine/>}
                                                        size={'sm'}/>
                                                </div>
                                                <div className={'text'}>
                                                    Save
                                                </div>
                                            </div>
                                            }

                                            {isUploadImage &&
                                            <div className={'d-flex'}>
                                                <div
                                                    className={`right-icon-wrapper`}>
                                                    <ButtonIcon
                                                        type={'dark'}
                                                        buttonIcon={<RiFileUploadLine/>}
                                                        size={'sm'}/>
                                                </div>
                                                <div className={'text'}>
                                                    Uploading...
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>

                            {src && (
                                <div className={'d-flex align-item-center justify-content-center'}>
                                    <ReactCrop
                                        src={src}
                                        crop={crop}
                                        ruleOfThirds
                                        onImageLoaded={this.onImageLoaded}
                                        onComplete={this.onCropComplete}
                                        onChange={this.onCropChange}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>)}

                <CategoryTag title={'Please select interested area'}/>

                <div className={'up-console-profile'}>
                    <ConsoleHeader
                        isEnableEdit={isEnableEdit}
                        onClickEnableEditMode={() => this.onClickEnableEditMode()}
                        onClickUpdate={() => this.onClickUpdateProfile()}
                        title={'Company Profile'}/>

                    <div className={'up-console-body-wrapper d-flex flex-column'}>

                        {/*merchant logo*/}
                        <ProfileViewCardUpload
                            title={'Merchant logo'}
                            onSelectFile={this.onSelectFile.bind(this)}/>

                        <ProfileViewCard
                            type={'accountType'}
                            padding={'pt24'}
                            catData={this.props.catData}
                            title={'Account type'}/>

                        <ProfileViewCard
                            type={'MerchantInfo'}
                            merchantName={this.props.privateUser.businessName}
                            BRno={this.props.privateUser.BRno}
                            isEdit={true}
                            padding={'pt24'}
                            catData={this.props.catData}
                            title={'Merchant info'}/>

                        <ProfileViewCard
                            type={'ContactInfo'}
                            address={'No 298/c/12 main road Colombo 5'}
                            email={this.props.privateUser.userName}
                            phoneNo={this.props.privateUser.contactNo}
                            isEdit={true}
                            padding={'pt24'}
                            catData={this.props.catData}
                            title={'Contact info'}/>

                        <ProfileViewCard
                            type={'Other'}
                            tags={this.props.privateUser.favoriteArea}
                            location={this.props.privateUser.location}
                            onClickOpenTag={() => this.props.onClickOpenCategoryTag()}
                            isEdit={true}
                            catData={this.props.catData}
                            privateUser={this.props.privateUser}
                            padding={'pt24'}
                            title={'Other'}/>


                    </div>
                </div>

            </>
        )
    }
}


const mapStateToProps = state => {
    return {
        catData: state.category.data,
        locData: state.location.data,
        privateUser: state.user.privateUser,
        isLoadingUser: state.user.loading,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getLoginUserDetailsById: (id) => dispatch(getLoginUserDetailsById(id)),
        onClickOpenSignIn: () => dispatch({type: actionTypes.OPEN_SIGN_IN, status: true}),
        onClickOpenCategoryTag: () => dispatch({type: actionTypes.OPEN_TAG, status: true}),
        updateProfileImage: (imageId) => dispatch({type: actionTypes.UPDATE_PRIVATE_USER_PROFILE_IMAGE, imageId}),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);


