import React from "react";
import "./styles.scss";

const Index = (props) => (
  <>
    <div
      className={`empty-list-wrapper d-flex flex-column justify-content-center align-items-center align-content-center`}
    >
      <img
        src={require("../../../../assets/img/empty_list.svg")}
        alt="creditcard"
      />
      <div className={`elw__title`}>
        It looks like you don't have any favorite offers yet.
      </div>
      <div className={`elw__subtitle`}>
        Explore our available offers and select your favorites!
      </div>
    </div>
  </>
);

Index.propTypes = {
  //type: PropTypes.string.isRequired,
};

Index.defaultProps = {
  //type: '',
};
const mapStateToProps = (state) => {
  return {
    logInUser: state.user.data,
  };
};

export default Index;
