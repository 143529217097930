const AmountRegex = /^[0-9]*$/g;
export const StringRegex = /^[a-zA-Z]*$/;

export const validateAmountInput = (oldValue, newValue) => {
  if (
    newValue.split("").filter((i) => i !== "." && isNaN(parseInt(i))).length > 0
  ) {
    return oldValue;
  }
  let decimalSections = newValue.split(".");
  if (decimalSections.length > 2) {
    return oldValue;
  }
  if (decimalSections.length === 2 && decimalSections[1].length > 2) {
    //only allow two decimal places
    return false;
  }
  return newValue;
};

export const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email); // Returns true if valid, false if not
};

export const validateMobileNo = (mobileNo) => {
  if (mobileNo) {
    // Remove any non-digit characters (like spaces or hyphens)
    mobileNo = mobileNo.replace(/\D/g, "");

    // If the number starts with '0' or '+94', remove them
    if (mobileNo.startsWith("0")) {
      mobileNo = mobileNo.slice(1);
    } else if (mobileNo.startsWith("94")) {
      mobileNo = mobileNo.slice(2);
    }
  }
  return mobileNo;
};

export const validateNumber = (oldValue, newValue) => {
  if (newValue.split("").filter((i) => i && isNaN(parseInt(i))).length > 0) {
    return oldValue;
  }
  return newValue;
};

export const validateCardOrAccountNumber = (oldValue, newValue) => {
  if (newValue.split("").filter((i) => isNaN(parseInt(i))).length > 0) {
    return oldValue;
  }
  return newValue;
};

export const validateUserPassword = (password) => {
  let res = {
    status: false,
    hasCharCount: password.length >= 8,
    hasLower: password.toUpperCase() != password,
    hasUpper: password.toLowerCase() != password,
    hasNumbers: /\d/.test(password),
  };
  console.log("validateUserPassword:", res);
  res.status =
    res.hasLower && res.hasUpper && res.hasNumbers && res.hasCharCount;
  return res;
};
