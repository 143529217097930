import React from "react";

import {
    RiStore3Line,
    RiShoppingBagLine,
    RiBuilding2Line,
    RiEmpathizeLine,
    RiShoppingBasket2Line,
    RiShoppingCartLine,
    RiPencilRuler2Line,
    RiHeartsLine,
    RiTShirt2Line,
    RiHotelBedLine,
    RiCloseFill
} from "react-icons/ri";

export const renderIcon = (bannerIcon) => {
    switch (bannerIcon) {
        case 'RiBuilding2Line':
            return <RiBuilding2Line/>;
        case 'RiStore3Line':
            return <RiStore3Line/>;
        case 'RiShoppingBagLine':
            return <RiShoppingBagLine/>;
        case 'RiEmpathizeLine':
            return <RiEmpathizeLine/>;
        case 'RiShoppingBasket2Line':
            return <RiShoppingBasket2Line/>;
        case 'RiShoppingCartLine':
            return <RiShoppingCartLine/>;
        case 'RiPencilRuler2Line':
            return <RiPencilRuler2Line/>;
        case 'RiHeartsLine':
            return <RiHeartsLine/>;
        case 'RiTShirt2Line':
            return <RiTShirt2Line/>;
        case 'RiHotelBedLine':
            return <RiHotelBedLine/>;
        default:
            return 'foo';
    }
};

