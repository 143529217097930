import * as actionTypes from "../actions/types";
import produce from "immer";

const initialState = {
  insightData: [],
  isLoading: false
};

let newData = [];
export default produce((draft = initialState, action) => {
  const {type, payload} = action;
  switch (type) {
    case actionTypes.SET_USER_INSIGHT:
      console.log("insightReducer=>>", payload?.data);
      draft.insightData = payload.data;
      draft.isLoading = false;
      return draft;
    case actionTypes.CLEAR_USER_INSIGHT:
      draft.insightData = [];
      draft.isLoading = false;
      return draft;
    default:
      return draft;
  }
});
