import React from 'react';
import PropTypes from 'prop-types';
import './style.scss'

import {
    TimelineItem
} from '../../../components'

import {
    RiStore2Line,
    RiArticleLine,
    RiLock2Line,
    RiContactsBookLine
} from "react-icons/ri";

const Index = (props) => (
    <>

        <div className={'wizard-wrapper d-flex flex-row'}>
            <div className={'flex-row'}>
                <TimelineItem
                    itemNo={1}
                    userType={props.userType === 1 ? 'user' : 'merchant'}
                    formName={'Account Type'}
                    done={true}
                    isFocusTab={false}
                    formIcon={<RiStore2Line/>}
                    step={'Step 1'}/>
                <TimelineItem
                    itemNo={2}
                    userType={props.userType === 1 ? 'user' : 'merchant'}
                    done={props.wizardNo >= 3}
                    formName={'Basic Details'}
                    isFocusTab={props.wizardNo === 2}
                    formIcon={<RiArticleLine/>}
                    step={'Step 2'}/>

                {props.userType === 1 &&
                <>
                    <TimelineItem
                        itemNo={3}
                        userType={props.userType === 1 ? 'user' : 'merchant'}
                        formName={'Security'}
                        done={props.wizardNo >= 4}
                        isFocusTab={props.wizardNo === 3}
                        formIcon={<RiLock2Line/>}
                        step={'Step 3'}/>
                </>
                }

                {props.userType === 2 &&
                <>
                    <TimelineItem
                        itemNo={3}
                        userType={props.userType === 1 ? 'user' : 'merchant'}
                        formName={'Contact Details'}
                        done={props.wizardNo >= 4}
                        isFocusTab={props.wizardNo === 3}
                        formIcon={props.userType === 1 ? <RiLock2Line/> : <RiContactsBookLine/>}
                        step={'Step 3'}/>
                    <TimelineItem
                        itemNo={4}
                        userType={props.userType === 1 ? 'user' : 'merchant'}
                        formName={'Security'}
                        done={props.wizardNo >= 4}
                        isFocusTab={props.wizardNo === 4}
                        formIcon={<RiLock2Line/>}
                        step={'Step 4'}/>
                </>
                }
            </div>


        </div>
    </>
);

Index.propTypes = {
    wizardNo: PropTypes.string.isRequired,
};

Index.defaultProps = {
    wizardNo: 1,
};

export default Index;
