import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "./styles.scss";
import "./mobile.scss";

import {
  ButtonIcon,
  OfferCardFooterItem,
  OfferDetailCard,
  OfferShimmerCard,
} from "../../../components";
import { GetIcon } from "../../../config/icon";

import { onClickCloseOfferView } from "../../../store/actions";
import Scrollbars from "react-custom-scrollbars";
import {
  createNewImpression,
  getOfferByOfferId,
  getOfferInsights,
} from "../../../service";
import {
  getGeoLocation,
  getInsight,
  getIPAddress,
  getLocationName,
  userDetails,
} from "../../../utils";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      wizardNo: 1,
      registeredType: 1,
      viewPointWidth: 0,
      viewPointHeight: 0,
      wizardStep: 1,
      dataSource: null,
      bankData: null,
      categoryData: null,
      merchantData: null,
      insightsData: null,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    console.log("scrollHeight : " + scrollHeight);
    console.log("clientHeight :" + clientHeight);
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
    // this.props.getAllRecentTransfers();
    //this.props.getAllFavouritesTransfers();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  onClickConfirm = (_step) => {};

  async fetchOfferData() {
    // Fix method name
    let offerData = this.props.offerData;

    if (offerData) {
      let offerId = offerData?.offerId;
      let res = await getOfferByOfferId(offerId);
      if (res && res.status) {
        let offerData = res.data[0];
        let bank = offerData.bank;
        let category = offerData.category;
        let merchant = offerData.merchant;
        console.log({ offerData });

        // await this.creteImpression(
        //     offerId,
        //     bank?.bankId,
        //     category?.categoryId,
        //     merchant?.merchantId
        // );

        console.log("getOfferInsights", res);
        this.setState({
          // isLoading: false,
          dataSource: offerData,
          bankData: bank,
          categoryData: category,
          merchantData: merchant,
        });

        // // //get more Insights
        // let insightsRes = await getOfferInsights(
        //   offerData?.merchant?.merchantId,
        //   offerData?.bank?.bankId,
        //   offerData?.category?.categoryId,
        //   9
        // );
        //
        // console.log("insightsRes", insightsRes);
        // this.setState({
        //   insightsData: insightsRes?.data,
        // });

        // // this.props.onClickViewMoreOfferDetails(
        // //     offerData,
        // //     category,
        // //     bank,
        // //     merchant
        // // );
      }
    } else {
      console.error("Invalid path or parameter not found");
    }
  }

  async creteImpression(offerId, bankId, merchantId, categoryID) {
    try {
      // Get the geolocation
      const position = await getGeoLocation();
      const { latitude, longitude } = position?.coords;
      // Wait for all promises to resolve
      const [location, ip] = await Promise.all([
        getLocationName(latitude, longitude),
        getIPAddress(),
      ]);
      let state = userDetails();
      //create impression
      const insight = await getInsight();
      console.log("Insight:", insight);
      let impressionRes = await createNewImpression({
        merchantId: merchantId,
        offerId: offerId,
        categoryId: categoryID,
        bankId: bankId,
        latitude: position?.coords?.latitude,
        longitude: position?.coords?.longitude,
        location: location,
        ip: ip,
        user: state,
        insightId: insight?.insightId,
      });

      // props.setUserInsight({
      //   data: insightBody
      // });
    } catch (error) {
      console.error("Error:", error);
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    // Check if offerData has changed
    if (prevProps.offerData !== this.props.offerData) {
      try {
        await this.fetchOfferData();
      } catch (error) {
        console.error("Error fetching offer data:", error);
      }
    }
  }

  getTermsAndCondition(_termsAndCondition) {
    if (_termsAndCondition) {
      if (Object.keys(_termsAndCondition)) {
        let bankId = this.state.bankData?.bankId;
        console.log("_termsAndCondition_bankId", bankId);
        if (_termsAndCondition[bankId]?.length > 0) {
          return _termsAndCondition[bankId];
        } else {
          return _termsAndCondition;
        }
      } else {
        console.log("_termsAndCondition", _termsAndCondition);
      }
    }
  }

  render() {
    const { viewPointHeight, dataSource, bankData, categoryData } = this.state;
    const { isOfferView, offerData, onClickFavorite, isLogin, isLoading } =
      this.props;

    return (
      <section
        className={`coo__modal-main-wrapper animate__animated ${
          isOfferView ? "show-me" : "hide-me"
        } `}
      >
        <div className="row justify-content-md-center">
          <div
            className={
              "col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-6 col-xxl-6"
            }
          >
            <div
              className={
                "coo_modal-body-content offer-view-modal d-flex flex-column animate__animated animate__fadeInUp"
              }
            >
              <div className={"close-modal-wrapper flex-row"}>
                <ButtonIcon
                  type={"close"}
                  onClick={() => this.props.onClickCloseOfferView()}
                  size={"md"}
                  buttonIcon={GetIcon("close")}
                />
              </div>

              {/* offer view modal body start */}
              {!isLoading && (
                <>
                  {/* banner start */}
                  <div className={"coo_modal_banner_wrapper"}>
                    <img
                      src={dataSource?.bannerImageURL}
                      alt="credit card offers"
                    />
                    {/* title start */}
                    <div
                      className={
                        "coo_modal-offer-title d-flex align-items-center"
                      }
                    >
                      <div className={"coo_modal_merchant-logo"}>
                        <img
                          src={
                            offerData &&
                            dataSource?.merchant &&
                            dataSource?.merchant.logoURL
                          }
                          alt="creditcard offers"
                        />
                      </div>
                      <div className={"d-flex flex-column"}>
                        <div className={"coo__offer-title"}>
                          {offerData && dataSource?.label}
                        </div>
                        <h1 className={"text-white"}> {dataSource?.header} </h1>
                      </div>
                    </div>
                    {/* title end */}

                    {/*<div className={'offer-tag'}>*/}
                    {/*    {dataSource?.offerType === 'off' && <>{dataSource?.offerRate}% off</>}*/}
                    {/*    {dataSource?.offerType === 'upto' && <>upto {dataSource?.offerRate}% off</>}*/}
                    {/*    {dataSource?.offerType === 'customize' && <>{dataSource?.offerRate}</>}*/}
                    {/*</div>*/}

                    <div className={"coo_offer-banner-black-layer"} />
                  </div>
                  {/* banner end */}
                  <div
                    className={"coo_modal-content-wrapper d-flex flex-column"}
                  >
                    <Scrollbars
                      style={{ height: viewPointHeight - 98 }}
                      thumbMinSize={30}
                      universal={true}
                      autoHide
                    >
                      {/* mobile title start */}
                      <div
                        className={
                          "coo_modal-offer-title-mobile d-flex flex-column"
                        }
                      >
                        <div className={"coo_modal_merchant-logo-mobile"}>
                          <img
                            src={
                              offerData &&
                              dataSource?.merchant &&
                              dataSource?.merchant.logoURL
                            }
                            alt="creditcard offers"
                          />
                        </div>
                        <div className={"d-flex flex-column"}>
                          <div className={"coo__offer-title-mobile"}>
                            {offerData && dataSource?.label}
                          </div>
                          <h1 className={"text-white-mobile"}>
                            {dataSource?.header}
                          </h1>
                        </div>
                      </div>
                      {/* mobile title end */}

                      <OfferDetailCard
                        type={"tag"}
                        option={"d-flex flex-column text-left mb-2 "}
                        description={null}
                        tagList={dataSource?.location}
                        title={"Offer valid location"}
                        tagType={"yellow"}
                      />

                      <OfferDetailCard
                        type={"tag"}
                        option={"d-flex flex-column text-left mb-2 "}
                        description={null}
                        tagList={dataSource?.cardTypes}
                        title={"Valid card"}
                        tagType={"green"}
                      />
                      <OfferDetailCard
                        type={"offRate"}
                        option={"d-flex flex-column text-left mb-2 mt-3"}
                        offerType={dataSource?.offerType}
                        offerRate={dataSource?.offerRate}
                        title={"Offer"}
                      />

                      <OfferDetailCard
                        type={"text"}
                        option={"d-flex flex-column text-left mb-2 mt-3"}
                        offerDateType={dataSource?.offerDateType}
                        offerStart={dataSource?.offerStart}
                        offerEnd={dataSource?.offerEnd}
                        note={dataSource?.validTimeDateDescription}
                        title={"Validity"}
                      />
                      {dataSource && dataSource?.promoCode && (
                        <OfferDetailCard
                          type={"text"}
                          option={"d-flex flex-column text-left mb-2 mt-3"}
                          description={
                            dataSource?.promoCode ? dataSource.promoCode : "-"
                          }
                          title={"Promo code"}
                        />
                      )}
                      {dataSource &&
                        dataSource?.hotLine &&
                        dataSource?.hotLine.mobileNo &&
                        dataSource?.hotLine.mobileNo.length > 0 && (
                          <OfferDetailCard
                            type={"text"}
                            option={"d-flex flex-column text-left mb-2 mt-3"}
                            description={null}
                            contactList={
                              dataSource &&
                              dataSource?.hotLine &&
                              dataSource?.hotLine.mobileNo
                            }
                            title={"Mobile no"}
                          />
                        )}
                      {dataSource &&
                        dataSource?.hotLine &&
                        dataSource?.hotLine.email &&
                        dataSource?.hotLine.email.length > 0 && (
                          <OfferDetailCard
                            type={"text"}
                            option={"d-flex flex-column text-left mb-2 mt-3"}
                            description={null}
                            contactList={
                              dataSource &&
                              dataSource?.hotLine &&
                              dataSource?.hotLine.email
                            }
                            title={"E-mail"}
                          />
                        )}

                      <div className={"mt-4 mb-4 d-flex"}>
                        <OfferCardFooterItem
                          title={"Bank"}
                          img={
                            <img
                              className={"lading-bg"}
                              src={bankData && bankData.logoURL}
                              alt="all offers in one place"
                            />
                          }
                          content={bankData && bankData.bankName}
                          type={"bank"}
                        />
                        <OfferCardFooterItem
                          style={"category"}
                          title={"Category"}
                          content={categoryData && categoryData.categoryName}
                          iconName={categoryData && categoryData.iconName}
                          type={"icon"}
                        />

                        <OfferCardFooterItem
                          style={"views"}
                          title={"Views"}
                          content={dataSource?.views}
                          iconName={"eye"}
                          type={"icon"}
                        />
                        {isLogin && (
                          <OfferCardFooterItem
                            style={"favorite"}
                            title={"Favorite"}
                            iconName={"favorite"}
                            isFavorites={dataSource?.isFavorites}
                            content={dataSource?.interest}
                            type={"icon"}
                            onClick={onClickFavorite}
                          />
                        )}
                      </div>
                      <OfferDetailCard
                        type={"text"}
                        isHtml={true}
                        option={"d-flex flex-column text-left mb-2 mt-3"}
                        description={dataSource?.description}
                        title={"Offer Description"}
                      />

                      <OfferDetailCard
                        type={"termsConditions"}
                        option={"d-flex flex-column text-left mb-3 mt-3 mr-3"}
                        termsConditions={this.getTermsAndCondition(
                          dataSource?.termsConditions
                        )}
                        title={"Terms & Conditions"}
                      />
                    </Scrollbars>
                  </div>
                </>
              )}
              {/* offer view modal body end */}

              {/* modal shimmer start */}
              {isLoading && (
                <>
                  <div className={"coo_modal_banner-shimmer shimmerBG"}>
                    <div className={"cco_mbs-banner shimmerBG"}></div>

                    <div
                      className={
                        "coo_mbs-title d-flex flex-row align-items-center"
                      }
                    >
                      <div className={"coo_mbst-merchant-logo shimmerBG"}></div>
                      <div
                        className={"d-flex flex-column"}
                        style={{ marginLeft: "8px" }}
                      >
                        <div
                          className={"coo__mbst-offer-title shimmerBG"}
                        ></div>
                        <h1 className={"coo__mbst-text-white shimmerBG"}></h1>
                      </div>
                    </div>
                  </div>
                  <div
                    className={"coo_modal-content-shimmer d-flex flex-column"}
                  >
                    <div
                      className={"d-flex flex-column"}
                      style={{ marginBottom: "30px" }}
                    >
                      <div className={"coo__mcs-title shimmerBG"}></div>
                      <div className={"coo__mcs-tag shimmerBG"}></div>
                    </div>
                    <div
                      className={"d-flex flex-column"}
                      style={{ marginBottom: "30px" }}
                    >
                      <div className={"coo__mcs-title shimmerBG"}></div>
                      <div className={"coo__mcs-subtitle shimmerBG"}></div>
                    </div>
                    <div
                      className={"d-flex flex-column"}
                      style={{ marginBottom: "30px" }}
                    >
                      <div className={"coo__mcs-title shimmerBG"}></div>
                      <div className={"coo__mcs-subtitle shimmerBG"}></div>
                    </div>
                    <div
                      className={"d-flex flex-column"}
                      style={{ marginBottom: "30px" }}
                    >
                      <div className={"coo__mcs-title shimmerBG"}></div>
                      <div className={"coo__mcs-dec shimmerBG"}></div>
                    </div>
                    <div
                      className={"d-flex flex-row"}
                      style={{ gap: "12px", marginBottom: "30px" }}
                    >
                      <div className={"coo__mcs-category shimmerBG"}> </div>
                      <div className={"coo__mcs-bank shimmerBG"}> </div>
                      <div className={"coo__mcs-view shimmerBG"}> </div>
                    </div>
                    <div
                      className={"d-flex flex-column"}
                      style={{ marginBottom: "30px" }}
                    >
                      <div className={"coo__mcs-title shimmerBG"}></div>
                      <div
                        className={"d-flex flex-row  align-items-center"}
                        style={{ gap: "8px" }}
                      >
                        <div className={"coo__mcs-icon shimmerBG"}></div>
                        <div className={"coo__mcs-term shimmerBG"}></div>
                      </div>
                    </div>
                    <div className={"d-flex flex-column"}>
                      <div className={"coo__mcs-title shimmerBG"}></div>
                      <div
                        className={"d-flex flex-row  align-items-center"}
                        style={{ gap: "8px" }}
                      >
                        <div className={"coo__mcs-icon shimmerBG"}></div>
                        <div className={"coo__mcs-term shimmerBG"}></div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {/* modal shimmer end */}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isOfferView: state.form.isOfferView,
    offerData: state.form.offerData,
    categoryData: state.form.categoryData,
    bankData: state.form.bankData,
    isLogin: state.user.isLogin,
    isLoading: state.offer.isLoading,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      onClickCloseOfferView,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Index);
