import React from "react";
import {
    RiSearchEyeFill,
    RiBuilding2Line,
    RiSearchLine,
    RiDashboardLine,
    RiPercentLine,
    RiEmpathizeLine,
    RiFileUploadLine,
    RiCloseLine,
    RiCheckLine,

} from "react-icons/ri";


export const GetMessageText = (_message_key) => {
    switch (_message_key) {
        case 'invalidMobileNo':
            return 'Invalid mobile no';
        case 'invalidFirstName':
            return 'First Name should be text only.';
        case 'passwordNotMatch':
            return 'Password not match.';
        default:
            return 'please select message key..';
    }
};
