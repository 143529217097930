import * as actionTypes from "../actions/types";
import produce from "immer";

const initialState = {
  bankData: [],
  isLoading: false,
};

let newData = [];
export default produce((draft = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_ALL_BANK:
      console.log("SET_ALL_BANK=>>", payload);
      draft.bankData = payload;
      draft.isLoading = false;
      return draft;
    // case actionTypes.CLEAR_USER_INSIGHT:
    //   draft.insightData = [];
    //   draft.isLoading = false;
    //   return draft;
    default:
      return draft;
  }
});
