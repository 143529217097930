import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;


export const getCategory = async () => {
    try {
        const options = {data: {}};
        // Get response
        let response = await axios.get(
            `${BASE_URL}/get/all/categories`,
            options,
        );
        return response.data;
    } catch (err) {
        console.error('ERR_GET_ALL_CATEGORY_API:', err);
        throw err;
    }
};

