import React, { Component } from "react";
import "./styles.scss";
import "./mobile.scss";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  appVersion,
  getInsight,
  GetMessageText,
  getToken,
  StringRegex,
  validateEmail,
  validateNumber,
  validateUserPassword,
} from "../../utils";
import {
  RiFacebookFill,
  RiInstagramLine,
  RiMailLine,
  RiMapPin2Line,
  RiPhoneLine,
  RiPinterestFill,
  RiTiktokFill,
  RiYoutubeFill,
} from "react-icons/ri";
import { Button, FilterColor_1, FilterColor_2, Input } from "../../components";
import * as actionTypes from "../../store/actions/types";
import { createNewSubscribe } from "../../service";
import { openNotification } from "../../config/notification";
import SuccessfullTemplate from "./template/successfullTemplate";

const token = getToken();
const APP_VERSION = appVersion();

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewPointHeight: 0,
      viewPointWidth: 0,
      isLoading: false,
      formIsValid: false,
      isFound: false,
      isSubscribe: false,
      isSuccessFull: false,
      dataForm: {
        email: {
          key: "email",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "Enter your email",
          },
          value: "",
          validation: {
            required: true,
            email: true,
          },
          valid: false,
          touched: false,
          label: "",
        },
      },
    };
  }

  //==== input Handle Change
  inputHandleChange = (event, inputIdentity) => {
    const updateSignUpForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateSignUpForm[inputIdentity],
    };

    switch (inputIdentity) {
      case "mobileNo":
        this.setState({ isFoundUser: false });
        updatedFormElement.value = validateNumber(
          updatedFormElement.value,
          event.target.value.toString()
        );
        break;
      case "otpVerification":
        updatedFormElement.value = event.target.value;
        if (event.target.value.length === 6) {
          this.setState({ wizardNo: 4 });
          alert("event fire...");
        }
        break;
      default:
        updatedFormElement.value = event.target.value;
        break;
    }

    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updateSignUpForm[inputIdentity] = updatedFormElement;
    let formIsValid = true;
    for (let inputIdentifier in updateSignUpForm) {
      formIsValid = updateSignUpForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateSignUpForm, formIsValid: formIsValid });
  };

  //=== validation
  checkValidity(value, rules) {
    let isValid = true;

    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }
    if (rules.mobileNo) {
      isValid = true;
    }

    if (rules.email) {
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      isValid = re.test(String(value).toLowerCase()) && isValid;
    }
    return isValid;
  }

  validation = (validationValue, inputIdentity) => {
    const updateSignUpForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateSignUpForm[inputIdentity],
    };

    updatedFormElement.value = validationValue;
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updateSignUpForm[inputIdentity] = updatedFormElement;
    let formIsValid = true;
    for (let inputIdentifier in updateSignUpForm) {
      formIsValid = updateSignUpForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateSignUpForm, formIsValid: formIsValid });
  };

  async updateInputValidationErrors() {
    const { wizardNo, userType, termsAndCondition } = this.state;
    let df = { ...this.state.dataForm };
    let formIsValid = true;
    //========= VALIDATION USER -- OFFER VIEWER
    if (wizardNo === 2 && userType === 1) {
      for (let itemKey of Object.keys(df)) {
        if (itemKey === "email") {
          if (df[itemKey].value === "") {
            df[itemKey].touched = true;
            df[itemKey].valid = false;
            formIsValid = false;
          } else {
            if (!StringRegex.test(df[itemKey].value)) {
              df[itemKey].touched = true;
              df[itemKey].valid = false;
              df[itemKey].invalidReason = GetMessageText("Invalid Email");
              formIsValid = false;
            }
          }
        }
        // if (itemKey === "mobileNo") {
        //   if (df[itemKey].value === "") {
        //     df[itemKey].touched = true;
        //     df[itemKey].valid = false;
        //     formIsValid = false;
        //   } else {
        //     if (df[itemKey].value.length < df[itemKey].validation.maxLength) {
        //       df[itemKey].touched = true;
        //       df[itemKey].valid = false;
        //       df[itemKey].invalidReason = GetMessageText("invalidMobileNo");
        //       formIsValid = false;
        //     }
        //   }
        // }
      }
      this.setState({ dataForm: df, formIsValid: formIsValid });
    }

    if (wizardNo === 3 && userType === 1) {
      for (let itemKey of Object.keys(df)) {
        if (itemKey === "password") {
          if (!validateUserPassword(df[itemKey].value)) {
            df[itemKey].touched = true;
            formIsValid = false;
          }
          if (df[itemKey].value === "") {
            df[itemKey].touched = true;
            df[itemKey].valid = false;
            formIsValid = false;
          }
        }
        if (itemKey === "confirmPassword") {
          if (df[itemKey].value === "") {
            df[itemKey].touched = true;
            df[itemKey].valid = false;
            formIsValid = false;
          }
        }
        if (df.password.value !== df.confirmPassword.value) {
          df[itemKey].invalidReason = GetMessageText("passwordNotMatch");
          formIsValid = false;
        }

        if (!this.state.termsAndCondition) {
          formIsValid = false;
        }
      }
      this.setState({ dataForm: df, formIsValid: formIsValid });
    }
    return formIsValid;

    const history = useHistory();
  }

  render() {
    const { dataForm, isLoading, isSuccessFull, isSubscribed } = this.state;
    const { selectMoreInfo, title, props } = this.props;

    console.log("isSuccessfull", isSuccessFull);
    return (
      <>
        {/* <MoreInfo /> */}
        <div className={"footer-wrapper"}>
          <div className={"fw__filter-bg-1"}>
            <FilterColor_1 />
          </div>
          <div className={"container"}>
            {/* Logo */}
            <div className={"fw__logo"}>
              <img
                src={require("../../assets/img/logo/creditcardoffers.lk.svg")}
                alt="credit card offers"
              />
            </div>
            {/* Body wrapper */}
            <div
              className={
                "fw__body-wrapper d-flex flex-row justify-content-between"
              }
            >
              <div className={"fwbw__content"}>
                <div
                  className={
                    "d-flex flex-row align-items-center align-content-center"
                  }
                >
                  <span className={"fwbwc__title"}>Discover</span>
                  <span className={"fwbwc__best"}>best</span>
                  <span className={"fwbwc__title"}> offer in one place</span>
                </div>
                <div className={"fwbwc__subtitle d-flex felx-wrap"}>
                  {
                    "Unlock unbeatable offers from top brands, retailers, travel, hotels, and more. Save time and money by accessing the best deals conveniently gathered for you in one spot."
                  }
                </div>
                <div className={"fwbwc__contact-no d-flex flex-row"}>
                  <div className={"fwbwc__icon"}>
                    <RiPhoneLine />
                  </div>
                  {"+94 112 170 377"}
                </div>
                <div className={"fwbwc__email d-flex flex-row"}>
                  <div className={"fwbwc__icon"}>
                    <RiMailLine />
                  </div>
                  {"info@creditcardoffers.lk"}
                </div>
                <div className={"fwbwc__address d-flex flex-row"}>
                  <div className={"fwbwc__icon"}>
                    <RiMapPin2Line />
                  </div>
                  Colombo, Sri Lanka
                </div>
              </div>
              <div className={"fw__link-wrapper"}>
                <div className={"fwlw__link"}>
                  <a href={"/about-us"}>About us</a>
                </div>
                <div className={"fwlw__link"}>
                  <a href={"/contact-us"}>Contact us</a>
                </div>
                <div className={"fwlw__link"}>
                  <a href={"/careers"}>{"Careers"}</a>
                </div>
                <div className={"fwlw__link"}>
                  <a href="/sign-up">{"Sign up"}</a>
                  {/* <SectionHeader
                    text={"Sign up"}
                    size={"sm"}
                    type={"link"}
                    onClick={function () {
                      props.history.push("/sign-in");
                    }}
                  /> */}
                </div>
                <div className={"fwlw__link"}>
                  <a href="/sign-in">{"Sign in"}</a>
                </div>
              </div>
              <div className={"fw__newsletter-wrapper"}>
                {!isSuccessFull === true ? (
                  <>
                    <div>
                      <div className={"fwnw__title"}>
                        {"Sign up our newsletter"}
                      </div>
                      <div className={"fwnw__subtitle"}>
                        {"Join our community to receive updates"}
                      </div>
                      <div className={"d-flex flex-column"}>
                        <div className={"fwnw__textbox"}>
                          <Input
                            elementConfig={dataForm.email.elementConfig}
                            elementType={dataForm.email.elementType}
                            required={dataForm.email.validation.required}
                            isinvalid={!dataForm.email.valid}
                            isShowPasswordStrengthMeter={false}
                            invalidReason={dataForm.email.invalidReason}
                            touched={dataForm.email.touched}
                            value={dataForm.email.value}
                            isRightIcon={false}
                            rightIcon={""}
                            size={"sm-x"}
                            margin={"m-t-0"}
                            label={dataForm.email.label}
                            changed={(event) =>
                              this.inputHandleChange(event, dataForm.email.key)
                            }
                          />
                        </div>
                        <div className={"fwnw__action"}>
                          <Button
                            size={"md-x x"}
                            type={"round"}
                            reactIcon={""}
                            text={"Subscribe"}
                            isLoading={isLoading}
                            rightIcon={false}
                            leftIcon={false}
                            disabled={!dataForm.email.value}
                            onClick={async (event) => {
                              //props.history.push("/sign-up");

                              if (
                                dataForm["email"].value != "" &&
                                validateEmail(dataForm["email"].value)
                              ) {
                                await this.updateInputValidationErrors();
                                this.setState({ isLoading: true });
                                const insight = await getInsight();
                                let state = await createNewSubscribe({
                                  insightId: insight?.insightId,
                                  subscribeEmail: dataForm.email.value,
                                });

                                if (state?.isFound) {
                                  this.setState({
                                    isFound: true,
                                    isSubscribe: true,
                                    isLoading: false,
                                  });
                                  openNotification({
                                    title: "Subscribe",
                                    message: "Email already subscribe.",
                                    type: "info",
                                    container: "top-center",
                                  });
                                } else {
                                  openNotification({
                                    title: "Success",
                                    message: "Successfully Subscribed.",
                                    type: "success",
                                    container: "top-center",
                                  });
                                  const updateForm = { ...this.state.dataForm };
                                  updateForm["email"].value = "";
                                  updateForm["email"].touched = false;

                                  this.setState({
                                    isFound: false,
                                    isSubscribe: true,
                                    isLoading: false,
                                    dataForm: updateForm,
                                    isSuccessFull: true,
                                  });
                                }
                              } else {
                                openNotification({
                                  title: "Error",
                                  message: "Enter Your Email !",
                                  type: "info",
                                  container: "top-center",
                                });
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className={`fwnw__successfull `}
                      style={{ zIndex: "999999" }}
                    >
                      <SuccessfullTemplate />
                    </div>
                  </>
                )}
              </div>
            </div>
            {/* Bottom wrapper*/}
            <div
              className={
                "fw__bottom-wrapper d-flex flex-row justify-content-between align-items-center"
              }
            >
              <div
                className={"d-flex flex-row align-items-center"}
                style={{ padding: "10px" }}
              >
                <span className={"fwbwc__year"}>2024</span>
                <span className={"fwbwc__mail"}>creditcardoffers</span>
                <span className={"fwbwc__lk"}>.lk</span>
                <span className={"fwbwc__year"}>- All rights reserved</span>
              </div>
              <div
                className={"d-flex flex-row justify-content-between"}
                style={{ gap: "16px" }}
              >
                <div
                  className={"fwbwc__link"}
                  onClick={() =>
                    this.props.onClickOpenMoreInfo("Terms and Conditions")
                  }
                >
                  {"Terms and conditions"}
                </div>
                <div
                  className={"fwbwc__link"}
                  onClick={() =>
                    this.props.onClickOpenMoreInfo("Privacy Policy")
                  }
                >
                  {"Privacy policy"}
                </div>
                <div
                  className={"fwbwc__link"}
                  onClick={() =>
                    this.props.onClickOpenMoreInfo("Cookie Policy")
                  }
                >
                  {"Cookie policy"}
                </div>
              </div>
              <div
                className={"d-flex flex-row justify-content-center"}
                style={{ gap: "11px", padding: "10px" }}
              >
                <div class="fwbwc__logo">
                  <a
                    href="https://www.instagram.com/creditcardoffers.lk/"
                    target="_blank"
                    className="fwbwc__l-icon"
                  >
                    <RiInstagramLine />
                  </a>
                </div>
                <div class="fwbwc__logo">
                  <a
                    href="https://www.youtube.com/@creditcardofferslk"
                    target="_blank"
                    className="fwbwc__l-icon"
                  >
                    <RiYoutubeFill />
                  </a>
                </div>
                <div class="fwbwc__logo">
                  <a
                    href="https://www.tiktok.com/@creditcardoffers.lk?is_from_webapp=1&sender_device=pc"
                    target="_blank"
                    className="fwbwc__l-icon"
                  >
                    <RiTiktokFill />
                  </a>
                </div>
                <div class="fwbwc__logo">
                  <a
                    href="https://www.facebook.com/profile.php?id=61559636450006"
                    target="_blank"
                    className="fwbwc__l-icon"
                  >
                    <RiFacebookFill />
                  </a>
                </div>
                <div class="fwbwc__logo">
                  <a
                    href="https://www.facebook.com/profile.php?id=61559636450006"
                    target="_blank"
                    className="fwbwc__l-icon"
                  >
                    <RiPinterestFill />
                  </a>
                </div>
              </div>
            </div>
            <div
              className={"d-flex flex-row justify-content-center app_version"}
            >
              Version {APP_VERSION}
            </div>
          </div>
          <img
            className={"fw__img"}
            src={require("../../assets/img/Group 5.png")}
            alt="credit card offers"
          />
          <div className={"fw__filter-bg-2"}>
            <FilterColor_2 />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClickOpenMoreInfo: (payload) =>
      dispatch({
        type: actionTypes.OPEN_MORE_INFO_MODAL,
        payload: payload,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
