import React from "react";

import "./styles.scss";
import { GetIcon } from "../../../config/icon";
import { Dropdown, Menu } from "antd";

const Index = (props) => (
  <>
    <div
      className={`favorite-icon-main-wrapper d-flex flex-row justify-content-center align-items-center align-content-center ${props.type} `}
      onClick={props.onClick}
    >
      {props.type === "default" && (
        <>
          <div className={`fimw__icon`}>{GetIcon("favorite")}</div>
        </>
      )}
      {props.type === "active" && (
        <>
          {/* <Dropdown overlay={props.favoriteList}> */}
          <div>
            <div className={`fimw__icon`}>{GetIcon("favorite")}</div>
            <div className={`fimw__active-dot`}></div>
          </div>
          {/* </Dropdown> */}
        </>
      )}
    </div>
  </>
);

Index.propTypes = {
  //type: PropTypes.string.isRequired,
};

Index.defaultProps = {
  //type: '',
};
const mapStateToProps = (state) => {
  return {
    logInUser: state.user.data,
  };
};

export default Index;
