import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import "./styles.scss";
import "./mobile.scss";
import {
  Button,
  ButtonIcon,
  EmptyList,
  FilterColor_1,
  FilterColor_2,
  InfoCard,
  OfferListCard,
} from "../../../components";
import { GetIcon } from "../../../config/icon";
import * as actionTypes from "../../../store/actions/types";
import { onClickCloseMobileProfile } from "../../../store/actions/mobileProifleView";
import { RiArrowRightLine, RiHeart2Line } from "react-icons/ri";
import { MdChevronRight, MdOutlineFavoriteBorder } from "react-icons/md";
import Scrollbars from "react-custom-scrollbars";
import { userDetails, userLogOut } from "../../../utils";
import { getFavoriteOfferByUser } from "../../../service/userApi";
import { onClickCloseMobileFavorite } from "../../../store/actions/mobileFavorite";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wizardNo: 1,
      registeredType: 1,
      viewPointWidth: 0,
      viewPointHeight: 0,
      wizardStep: 1,
      isLoading: false,
      userFavoritesList: [],
      isMobileVerification: true,
      sessionUser: null,
      favoriteList: null,
      favoriteData: null,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    // console.log("scrollTop : " + scrollTop);
    // console.log("scrollHeight : " + scrollHeight);
    // console.log("clientHeight :" + clientHeight);
  };

  async componentDidMount() {
    console.log("componentDidMount");
    window.addEventListener("resize", this.updateWindowDimensions, true);
    window.addEventListener("scroll", this.handleOnScroll, true);

    this.updateWindowDimensions();
    this.handleOnScroll();

    //=========== is available session user ===========
    let sessionUser = userDetails();
    if (sessionUser?.userId) {
      console.log("sessionUser", sessionUser);
      this.setState({ sessionUser: sessionUser });
      this.props.onAddLoginUser(sessionUser);
      let favoriteData = await getFavoriteOfferByUser(sessionUser?.userId);
      if (favoriteData?.status) {
        console.log("favoriteData", favoriteData);
        this.setState({
          isMobileVerification: favoriteData?.data?.isMobileVerification,
          userFavoritesList: favoriteData?.data?.favorites,
        });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  onClickLogOut = () => {
    let _this = this;
    this.props.history.push("/");
    this.setState({
      isMobileVerification: true,
      userFavoritesList: [],
    });
    this.props.onClickLogOut();
    userLogOut(function (res) {
      console.log("logout------");
      console.log(res);
      window.location.reload();
    });
  };

  render() {
    const {
      viewPointHeight,
      isLoading,
      isMobileVerification,
      sessionUser,
      favoriteData,
      favorites,
      userFavoritesList,
    } = this.state;
    const {
      isMobileFavoriteList,
      selectMoreInfo,
      props,
      logInUser,
      privateUser,
      favoriteList,
    } = this.props;

    return (
      <section
        className={`coo__modal-main-wrapper animate__animated ${
          isMobileFavoriteList ? "show-me" : "hide-me"
        }`}
      >
        <div
          className={
            "coo_modal-body-content d-flex flex-column animate__animated animate__fadeInRight"
          }
          style={{
            top: "0",
            borderRadius: "0",
            height: "100%",
            padding: "16px",
          }}
        >
          <div className={"mpw__close"}>
            <div className={"d-flex flex-row align-items-center"}>
              <ButtonIcon
                type={"back"}
                onClick={() => this.props.onClickCloseMobileFavorite()}
                size={"sm x"}
                buttonIcon={GetIcon("close")}
              />
              <div className={"close-txt"}>My Favorite List</div>
            </div>
          </div>
          <Scrollbars
            style={{ height: viewPointHeight - 110 }}
            thumbMinSize={30}
            universal={true}
            autoHide
          >
            <div className={""}>
              <>
                {this.props.favoriteList &&
                  this.props.favoriteList.length === 0 && (
                    <>
                      <EmptyList />
                    </>
                  )}
                {this.props.favoriteList &&
                  this.props.favoriteList.length > 0 && (
                    <>
                      <div className={`favorite-list-wrapper`}>
                        <div
                          className={"d-flex flex-row justify-content-between "}
                          style={{ marginBottom: "12px" }}
                        >
                          <div className={`flw__title`}>
                            ({this.props.favoriteList?.length})
                          </div>
                          <div className={`flw__refresh`}>refresh</div>
                        </div>
                        <Scrollbars
                          style={{ height: viewPointHeight - 150 }}
                          thumbMinSize={30}
                          universal={true}
                          autoHide
                        >
                          {(this.props.favoriteList || []).map(
                            (item, index) => (
                              <>
                                <OfferListCard
                                  details={item?.header}
                                  name={item?.label}
                                  logo={item?.bank?.logoURL}
                                  imgUrl={item?.bannerImageURL}
                                  createAt={item?.createAt}
                                  onClickViewOffer={function () {
                                    props.history.push(
                                      `/offer-view/${item?.offerId}`
                                    );
                                  }}
                                />
                              </>
                            )
                          )}
                        </Scrollbars>
                        {/* <div className={"flw__bottom"}></div> */}
                      </div>
                    </>
                  )}
              </>
            </div>
          </Scrollbars>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobileFavoriteList: state.mobileFavoriteReducer.isMobileFavoriteList,
    logInUser: state.user.data,
    privateUser: state.user.privateUser,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddLoginUser: (userDara) =>
      dispatch({ type: actionTypes.ADD_LOGIN_USER, loginUser: userDara }),
    onClickCloseMobileProfile: () =>
      dispatch({ type: actionTypes.CLOSE_MOBILE_PROFILE_VIEW, status: false }),
    onClickLogOut: () => dispatch({ type: actionTypes.LOG_OUT }),
    onClickCloseMobileFavorite: () =>
      dispatch({ type: actionTypes.CLOSE_MOBILE_FAVORITE_LIST, status: false }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);
