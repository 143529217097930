import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import "./mobile.scss";
import { ButtonIcon, FilterColor_1, FilterColor_2 } from "../../../components";
import { GetIcon } from "../../../config/icon";
import * as actionTypes from "../../../store/actions/types";
import Scrollbars from "react-custom-scrollbars";
import { onClickCloseMobileSignIn } from "../../../store/actions/mobileSignIn";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wizardNo: 1,
      registeredType: 1,
      viewPointWidth: 0,
      viewPointHeight: 0,
      wizardStep: 1,
      isLoading: false,
      userFavoritesList: [],
      isMobileVerification: true,
      sessionUser: null,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    // console.log("scrollTop : " + scrollTop);
    // console.log("scrollHeight : " + scrollHeight);
    // console.log("clientHeight :" + clientHeight);
  };

  async componentDidMount() {
    console.log("componentDidMount");
    window.addEventListener("resize", this.updateWindowDimensions, true);
    window.addEventListener("scroll", this.handleOnScroll, true);

    this.updateWindowDimensions();
    this.handleOnScroll();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  render() {
    const { viewPointHeight, isLoading, isMobileVerification, sessionUser } =
      this.state;
    const { isMobileSignIn, selectMoreInfo, props, logInUser, privateUser } =
      this.props;

    return (
      <section
        className={`coo__modal-main-wrapper animate__animated ${
          isMobileSignIn ? "show-me" : "hide-me"
        }`}
      >
        <div
          className={
            "coo_modal-body-content d-flex flex-column animate__animated animate__fadeInUp"
          }
          style={{
            top: "0",
            borderRadius: "0",
            height: "100%",
            padding: "16px",
          }}
        >
          <div className={"filter-wrapper-1"}>
            <FilterColor_1 />
          </div>
          <div className={"image-wrapper-1"}>
            <img src={require("../../../assets/img/Group 5_mobile.png")} />
          </div>

          <div className={"mpw__close"}>
            <div className={"d-flex flex-row align-items-center"}>
              <ButtonIcon
                type={"back"}
                onClick={() => this.props.onClickCloseMobileSignIn()}
                size={"sm x"}
                buttonIcon={GetIcon("leftArrow")}
              />
              <div className={"close-txt"}>Home</div>
            </div>
          </div>
          <Scrollbars
            style={{ height: viewPointHeight - 110 }}
            thumbMinSize={30}
            universal={true}
            autoHide
          >
            <div
              className={
                "coo_modal_body d-flex flex-column justify-content-center align-items-center align-content-center"
              }
              style={{ marginTop: "160px" }}
            >
              <div className={"ccom__b-link"} style={{ marginBottom: "64px" }}>
                <a href="/sign-in">{"Sign in"}</a>
              </div>
              <div className={"ccom__b-link"}>
                <a href="/sign-up">{"Sign up"}</a>
              </div>
            </div>
          </Scrollbars>
          <div className={"coom__action"}>
            <div className={"coom__version"} style={{ marginBottom: "96px" }}>
              Version 1.0
            </div>
          </div>
          <div className={"filter-wrapper-2"}>
            <FilterColor_2 />
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isMobileSignIn: state.mobileSignInReducer.isMobileSignIn,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClickCloseMobileSignIn: () =>
      dispatch({ type: actionTypes.CLOSE_MOBILE_SIGN_IN_VIEW, status: false }),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);
