import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import zxcvbn from 'zxcvbn';
import './styles.scss';
import {RiInformationLine, RiCloseLine, RiCheckLine} from "react-icons/ri";
import {Menu, Dropdown} from 'antd';
import {validateUserPassword} from "../../../utils";

class Index extends Component {

    constructor(props) {
        super(props);
        const {minStrength = 3, thresholdLength = 7} = props;
        this.minStrength = typeof minStrength === 'number'
            ? Math.max(Math.min(minStrength, 4), 0)
            : 3;

        this.thresholdLength = typeof thresholdLength === 'number'
            ? Math.max(thresholdLength, 7)
            : 7;

        this.state = {
            hasCharCount: false,
            hasLower: false,
            hasUpper: false,
            hasNumbers: false,
            strength: 0
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.password !== this.props.password) {
            let validatedRes = validateUserPassword(this.props.password);
            this.setState({
                hasCharCount: validatedRes.hasCharCount,
                hasLower: validatedRes.hasLower,
                hasUpper: validatedRes.hasUpper,
                hasNumbers: validatedRes.hasNumbers,
                strength: zxcvbn(this.props.password).score
            });
        }
    }

    getPasswordStrengthText(val) {
        if (val <= 1) {
            return "weak";
        } else if (val <= 3) {
            return "medium";
        } else {
            return "high"
        }
    }

    render() {
        const {strength, hasCharCount, hasLower, hasUpper, hasNumbers} = this.state;
        const {password} = this.props;
        const passwordLength = password.length;
        const passwordStrong = strength >= this.minStrength;
        const passwordLong = passwordLength > this.thresholdLength;
        const counterClass = ['info-text ml-1', passwordLong ? passwordStrong ? 'success' : 'warning' : 'danger'].join(' ').trim();
        const strengthClass = ['strength-meter mt-2', passwordLength > 0 ? 'visible' : 'visible'].join(' ').trim();

        const menu = (
            <div className={'pw__info-content-wrapper d-flex flex-column'}>
                <div className={'pw__info-row d-flex align-items-center'}>
                    <div className={`pw__i-icon  ${hasCharCount ? 'active' : 'default'}`}>{hasCharCount ?
                        <RiCheckLine/> : <RiCloseLine/>}</div>
                    <div className={'pw__i-text'}>At least 8 characters in length</div>
                </div>

                <div className={'pw__info-row d-flex'}>
                    <div className={`pw__i-icon ${hasLower ? 'active' : 'default'}`}>{hasLower ? <RiCheckLine/> :
                        <RiCloseLine/>}</div>
                    <div className={'pw__i-text'}>Lower case letters (a-z)</div>
                </div>

                <div className={'pw__info-row d-flex'}>
                    <div className={`pw__i-icon ${hasUpper ? 'active' : 'default'}`}>{hasUpper ? <RiCheckLine/> : <RiCloseLine/>}</div>
                    <div className={'pw__i-text'}>upper case letters (A-Z)</div>
                </div>

                <div className={'pw__info-row d-flex'}>
                    <div className={`pw__i-icon ${hasNumbers ? 'active' : 'default'}`}>{hasNumbers ? <RiCheckLine/> : <RiCloseLine/>}</div>
                    <div className={'pw__i-text'}>Numbers (0-9)</div>
                </div>

            </div>
        );
        return (
            <>
                {this.props.password &&
                <div className="position-relative d-flex flex-column">
                    <div className={strengthClass}>
                        <div className="strength-meter-fill" data-strength={this.state.strength}/>
                    </div>
                    <div className="password-info d-flex align-items-center">
                        <span>Password strength  : </span>
                        <span className={counterClass}> {this.getPasswordStrengthText(this.state.strength)} </span>
                        <Dropdown overlay={menu} placement="bottomLeft" arrow>
                            <span className={'ml-1 pass-info'}><RiInformationLine/> </span>
                        </Dropdown>
                    </div>
                </div>
                }
            </>
        );
    }

}

Index.propTypes = {
    label: PropTypes.string.isRequired
    ,
    fieldId: PropTypes.string.isRequired
    ,
    placeholder: PropTypes.string.isRequired
    ,
    required: PropTypes.bool
    ,
    children: PropTypes.node
    ,
    minStrength: PropTypes.number
    ,
    thresholdLength: PropTypes.number
}

;

export default Index;
