import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import { RiEyeFill, RiEyeLine, RiHeartLine } from "react-icons/ri";

const Index = (props) => (
  <>
    <div
      className={`views-favorite-card-wrapper d-flex flex-row align-items-center justify-content-center align-conetnt-center`}
    >
      <div className={"vfcw__views d-flex flex-row align-items-center"}>
        <div className={"vfcw__v-icon"}>
          <RiEyeLine />
        </div>
        <div className={"vfcw__v-count"}>{props.views}</div>
      </div>
      <div className={"vfcw__favorites  d-flex flex-row align-items-center"}>
        <div className={"vfcw__f-icon"}>
          <RiHeartLine />
        </div>
        <div className={"vfcw__f-count"}>{props.favorites}</div>
      </div>
    </div>
  </>
);

Index.propTypes = {};

Index.defaultProps = {};

export default Index;
