import React from "react";
import "./styles.scss";
import "./mobile.scss";

const Index = (props) => (
  <>
    <div className={`details-card-wrapper d-flex flex-column `}>
      <div className={`d-flex flex-row`}>
        <div className={`dcw__no`}>{props.no}</div>
        <div className={`dcw__title`}>{props.title}</div>
      </div>

      <div className={`dcw__content`}>{props.content}</div>
      {props.subList && (
        <>
          {(props.subList || []).map((item, index) => (
            <ul className={""} style={{ listStyle: "disc" }}>
              <li className={`dcw__list-content`}>{item.listContent}</li>
            </ul>
          ))}
        </>
      )}
      {props.subContentList && (
        <>
          {(props.subContentList || []).map((item, index) => (
            <>
              <div className={"dcw__sub-content d-flex flex-column"}>
                <div className={`dcw__sc-title`}>{item.subContentTitle}</div>
                <div className={`dcw__sc--content`}>{item.subContent}</div>
              </div>
            </>
          ))}
        </>
      )}
    </div>
  </>
);

Index.propTypes = {};

Index.defaultProps = {};

export default Index;
