import React, {Component} from "react";
import './style.scss'
import {
    RiStore2Line,
    RiShieldCheckLine,
    RiFileShield2Line,
    RiHomeGearLine
} from "react-icons/ri";
import {
    userDetails
} from '../../../../utils';
import * as actionTypes from "../../../../store/actions/types";
import {connect} from "react-redux";
import {
    TimelineItem
} from '../../../../components'


class Index extends Component {
    constructor(props) {
        super(props);
        this.state = {
            wizardNo: 0
        };

    }

    //=== checkValidity

    //==== input Handle Change

    render() {
        const {dataForm} = this.state;
        return (
            <>
                <div className={'empty-p-console-profile'}>
                    <div className={'empty-p-console-body-wrapper d-flex flex-column'}>
                        <div className={'empty-image-wrapper'}>
                            <img width={'150'}
                                 src={require("../../../../assets/img/profilePeding.png")}
                                 alt="all offers in one place"/>
                        </div>
                        <div className={'empty-p-content-wrapper'}>
                            <h3>Your company profile is under review</h3>
                            <h5>Your profile has been submitted & will be reviewed by our team.
                                <br/>
                                You will be notified if any extra information is needed.</h5>
                        </div>

                        <div className={'time-line-wrapper'}>
                            <TimelineItem
                                itemNo={1}
                                formName={'Document Submitted'}
                                done={false}
                                isFocusTab={true}
                                formIcon={<RiFileShield2Line/>}
                                step={'Step 1'}
                                wizardNo={this.state.wizardNo}/>
                            <TimelineItem
                                itemNo={2}
                                formName={'Business Registration review'}
                                done={false}
                                isFocusTab={false}
                                formIcon={<RiHomeGearLine/>}
                                step={'Step 2'}
                                wizardNo={this.state.wizardNo}/>
                            <TimelineItem
                                itemNo={3}
                                formName={'Activation'}
                                done={false}
                                isFocusTab={false}
                                formIcon={<RiShieldCheckLine/>}
                                step={'Step 3'}
                                wizardNo={this.state.wizardNo}/>

                        </div>
                    </div>
                </div>

            </>
        )
    }
}


const mapStateToProps = state => {
    return {
        locData: state.location.data
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onClickOpenSignIn: () => dispatch({type: actionTypes.OPEN_SIGN_IN, status: true}),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)
(Index);


