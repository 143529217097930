import React, { useEffect, useState } from "react";
import { Drawer, Dropdown } from "antd";
import "./styles.scss";
import "./mobile.scss";

import {
  Button,
  ButtonIcon,
  EmptyList,
  FavoriteIcon,
  OfferListCard,
} from "../../../components";
import {
  RiAddLine,
  RiMenu2Line,
  RiMenu5Fill,
  RiUserLine,
  RiUserSharedLine,
} from "react-icons/ri";
import { BiChevronDown } from "react-icons/bi";
import Scrollbars from "react-custom-scrollbars";
import { isLogin } from "../../../utils";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import { useDispatch } from "react-redux";
import * as actionTypes from "../../../store/actions/types";
import { getFavoriteOfferByUser } from "../../../service/userApi";

const Index = (props, state) => {
  const [viewPointWidth, setViewPointWidth] = useState(0);
  const [viewPointHeight, setViewPointHeight] = useState(0);
  const [favoriteList, setFavoriteList] = useState([]);
  const dispatch = useDispatch();
  // scrollbars start
  useEffect(() => {
    console.log(props.isLogin);
    updateWindowDimensions();
    window.addEventListener("resize", updateWindowDimensions);
    window.addEventListener("scroll", handleOnScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      window.removeEventListener("scroll", handleOnScroll);
      window.removeEventListener("resize", updateWindowDimensions);
    };
  }, []);

  const updateWindowDimensions = () => {
    setViewPointWidth(window.innerWidth);
    setViewPointHeight(window.innerHeight);
  };

  const handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
  };
  // scrollbars end

  // const favoriteMenu = (
  //   <div className={"drop-down-wrapper"}>
  //     <>
  //       {props.favoriteList && props.favoriteList.length === 0 && (
  //         <>
  //           <EmptyList />
  //         </>
  //       )}
  //       {props.favoriteList && props.favoriteList.length > 0 && (
  //         <>
  //           <div className={`favorite-list-wrapper d-flex flex-column `}>
  //             <div
  //               className={"d-flex flex-row justify-content-between "}
  //               style={{ padding: "16px" }}
  //             >
  //               <div className={`flw__title`}>
  //                 My Favorite offers ({props.favoriteList?.length})
  //               </div>
  //               <div className={`flw__refresh`}>refresh</div>
  //             </div>
  //             <Scrollbars
  //               onScroll={handleOnScroll}
  //               renderView={(props) => (
  //                 <div
  //                   {...props}
  //                   style={{ ...props.style, overflowX: "hidden" }}
  //                 />
  //               )}
  //               style={{
  //                 height: viewPointHeight - 500,
  //               }}
  //             >
  //               {(props.favoriteList || []).map((item, index) => (
  //                 <>
  //                   <OfferListCard
  //                     details={item?.offer?.header}
  //                     name={item?.offer?.label}
  //                     logo={item?.offer?.bank?.logoURL}
  //                     imgUrl={item?.offer?.bannerImageURL}
  //                     createAt={item?.offer?.createAt}
  //                     //onClickViewOffer={() => this.props.onClickOpenOfferView(item)}
  //                   />
  //                 </>
  //               ))}
  //             </Scrollbars>
  //             <div className={"flw__bottom"}></div>
  //           </div>
  //         </>
  //       )}
  //     </>
  //   </div>
  // );

  const getFavoriteOffers = async () => {
    let favoriteData = await getFavoriteOfferByUser(props.logInUser?.userId);
    if (favoriteData?.status) {
      console.log("favoriteData", favoriteData);
      setFavoriteList(favoriteData?.data && favoriteData?.data[0]?.favorites);
    }
  };

  const [open, setOpen] = useState(false);
  const showDrawer = async () => {
    setOpen(true);
    await getFavoriteOffers();
  };
  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <section
        className={`header-main-wrapper ${
          props.isScrollTop ? "small-header" : ""
        }`}
      >
        <div
          className={
            "container d-flex justify-content-between align-items-center"
          }
        >
          <div className={"hm-inner-left-wrapper"}>
            <div
              className={"logo active-cursor"}
              onClick={function () {
                props.history.push("/");
              }}
            >
              <img
                className={"colombo-event-logo-svg"}
                width={"280"}
                onClick={function () {
                  props.history.push("/");
                }}
                src={require("../../../assets/img/logo/creditcardoffers.lk.svg")}
                alt="credit card offers"
              />
            </div>
          </div>
          {viewPointWidth > 480 && (
            <>
              <div className={"hm-inner-right-wrapper"}>
                <>
                  {!props.isLogin && (
                    <div className={"fresh-user-wrapper d-flex"}>
                      <div className={"sign-up-inner-wrp"}>
                        <Button
                          size={"sm"}
                          type={"light"}
                          reactIcon={<RiUserLine />}
                          text={"Sign up"}
                          rightIcon={false}
                          leftIcon={false}
                          onClick={function () {
                            props.history.push("/sign-up");
                          }}
                        />
                      </div>
                      <div className={"sign-in-inner-wrp ml-2"}>
                        <Button
                          size={"sm"}
                          type={"dark"}
                          reactIcon={<RiUserSharedLine />}
                          text={"Sign in"}
                          rightIcon={false}
                          leftIcon={false}
                          onClick={function () {
                            props.history.push("/sign-in");
                          }}
                        />
                      </div>
                    </div>
                  )}

                  {props.isLogin && (
                    <div
                      className={"d-flex felx-row align-items-center"}
                      style={{ gap: "24px" }}
                    >
                      <div>
                        <FavoriteIcon
                          // favoriteList={favoriteMenu}
                          type={"active"}
                          onClick={async () => {
                            await showDrawer();
                          }}
                        />
                      </div>
                      <div className={"login-user-wrapper d-flex"}>
                        {props.logInUser?.userType === 2 && (
                          <div
                            className={"create-event d-flex mr-3"}
                            onClick={props.onClickOpenNewOffer}
                          >
                            <div className={"icon-wrp mr-2"}>
                              <RiAddLine />
                            </div>
                            <div className={"name"}>Create Offer</div>
                          </div>
                        )}

                        <Dropdown overlay={props.profileMenu}>
                          <div className={"profile-wrapper d-flex"}>
                            <div className={"profile-img"}>
                              {/* {props.privateUser?.logoUrl && (
                      <img
                        alt={"credit card offers"}
                        src={config.baseImageURL + props.privateUser?.logoUrl}
                      />
                    )} */}
                              {props.privateUser && (
                                <img
                                  alt={"credit card offers"}
                                  src={props.privateUser}
                                />
                              )}
                            </div>
                            <div className={"profile-details"}>
                              <div className={"name"}>
                                Hi{" "}
                                {props.logInUser && props.logInUser?.firstName
                                  ? props.logInUser?.firstName
                                  : ""}
                              </div>
                              <div className={"type"}>
                                {props.logInUser &&
                                props.logInUser.userType === "1"
                                  ? "Customer"
                                  : props.logInUser &&
                                    props.logInUser?.userType === "2"
                                  ? "Merchant"
                                  : "error"}
                              </div>
                            </div>
                            <div className={"drop-down-menu"}>
                              <BiChevronDown />
                            </div>
                          </div>
                        </Dropdown>
                      </div>
                    </div>
                  )}
                </>
              </div>
            </>
          )}
          {viewPointWidth <= 480 && (
            <>
              <div>
                <ButtonIcon
                  type={"menu"}
                  buttonIcon={<RiMenu5Fill />}
                  size={"lg"}
                  onClick={async () => {
                    if (props.isLogin) {
                      dispatch({
                        type: actionTypes.OPEN_MOBILE_PROFILE_VIEW,
                        payload: null,
                      });
                    } else {
                      dispatch({
                        type: actionTypes.OPEN_MOBILE_SIGN_IN_VIEW,
                        payload: null,
                      });
                    }
                  }}
                />
              </div>
            </>
          )}

          <Drawer title="My Favorite offers" onClose={onClose} open={open}>
            <div className={""}>
              <>
                {favoriteList && favoriteList.length === 0 && (
                  <>
                    <EmptyList />
                  </>
                )}
                {favoriteList && favoriteList.length > 0 && (
                  <>
                    <div
                      className={`favorite-list-wrapper d-flex flex-column `}
                    >
                      <div
                        className={"d-flex flex-row justify-content-between "}
                        style={{ marginBottom: "12px" }}
                      >
                        <div className={`flw__title`}>
                          ({favoriteList?.length})
                        </div>
                        <div className={`flw__refresh`}>refresh</div>
                      </div>
                      <Scrollbars
                        onScroll={handleOnScroll}
                        renderView={(props) => (
                          <div
                            {...props}
                            style={{ ...props.style, overflowX: "hidden" }}
                          />
                        )}
                        style={{
                          height: viewPointHeight - 153,
                        }}
                      >
                        {(favoriteList || []).map((item, index) => (
                          <>
                            <OfferListCard
                              details={item?.offer?.header}
                              name={item?.offer?.label}
                              logo={item?.bank?.logoURL}
                              imgUrl={item?.offer?.bannerImageURL}
                              createAt={item?.offer?.createAt}
                              onClickViewOffer={function () {
                                props.history.push(
                                  `/offer-view/${item?.offer?.offerId}`
                                );
                              }}
                            />
                          </>
                        ))}
                      </Scrollbars>
                      <div className={"flw__bottom"}></div>
                    </div>
                  </>
                )}
              </>
            </div>
          </Drawer>
        </div>
      </section>
    </>
  );
};

Index.propTypes = {
  //type: PropTypes.string.isRequired,
};

Index.defaultProps = {
  //type: '',
};
const mapStateToProps = (state) => {
  return {
    logInUser: state.user.data,
  };
};
export default withRouter(Index);
