import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import { BiX } from "react-icons/bi";
import { GetIcon } from "../../../config/icon";

const Index = (props) => (
  <>
    <div
      key={props.key}
      className={`search-card-wrapper mr-1 d-flex ${props.size} ${props.type} ${
        props.isSelect ? "is-active" : ""
      }`}
      onClick={props.onClick}
    >
      <div className={"scw-left-wrapper d-flex"}>
        <div className={`sc-select-box ${props.isSelect ? "is-active" : ""}`}>
          {props.isSelect && <>{GetIcon("check")}</>}
        </div>
        <div className={"search-card-image-wrapper"}>
          {props.imageType === "image" && (
            <>
              <div className={"image-wrp"}>
                <img src={props.imgUrl} alt="creditcard offers" />
              </div>
            </>
          )}
          {props.imageType === "icon" && (
            <>
              <div className={`icon-wrp ${props.isSelect ? "is-active" : ""}`}>
                {GetIcon(props.iconName)}
              </div>
            </>
          )}
        </div>
      </div>
      <div className={"text-search-card"}>{props.text}</div>

      {props.select && (
        <div
          className={`selection-wrp-checkBox ${props.isSelect}`}
          onClick={props.onRemove}
        >
          {props.isSelect && <BiX />}
        </div>
      )}
    </div>
  </>
);

Index.propTypes = {
  text: PropTypes.string.isRequired,
};

Index.defaultProps = {
  text: "",
};

export default Index;
