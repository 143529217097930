import axios from 'axios';
import * as config from "../config/baseUrls";
import {getToken} from '../utils';
import * as actionTypes from "../store/actions/types";

const token = getToken();
let LOCATION_URL = config.testUrl + 'location/';

export const getAllLocation = () => dispatch => {
    dispatch({
        type: actionTypes.GET_LOCATION_PENDING,
    });
    axios.get(LOCATION_URL + 'all', {
        headers: {
            'Content-Type': 'application/json',
        }
    }).then(res => {
        if (res && res.data) {
            console.log('dispatch location data');
            dispatch({
                type: actionTypes.GET_ALL_LOCATION,
                data: res.data.response,
            });
        }
    }).catch(err => {
        dispatch({
            type: actionTypes.GET_LOCATION_FAILURE,
            error: err
        });
    });
};









