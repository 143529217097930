import { combineReducers } from "redux";
import categoryReducer from "./categoryReducer";
import locationReducer from "./locationReducer";
import eventReducer from "./eventReducer";
import tagReducer from "./tagReducer";
import userReducer from "./userReducer";
import artistReducer from "./ArtistReducer";
import formStatusReducer from "./formStatusReducer";
import searchReducer from "./searchReducer";
import offerReducer from "./offerReducer";
import moreInfoReducer from "./moreInfoReducer";
import insightReducer from "./insightReducer";
import mobileProfileViewReducer from "./mobileProfileViewReducer";
import mobileSignInReducer from "./mobileSignInReducer";
import bankReducer from "./bankReducer";
import mobileFavoriteReducer from "./mobileFavoriteReducer";
import updateMobileNoReducer from "./updateMobileNoReducer";
import profileViewReducer from "./profileViewReducer";

const rootReducer = combineReducers({
  category: categoryReducer,
  location: locationReducer,
  event: eventReducer,
  user: userReducer,
  hashTag: tagReducer,
  artist: artistReducer,
  form: formStatusReducer,
  filter: searchReducer,
  offer: offerReducer,
  moreInfoReducer: moreInfoReducer,
  insightReducer: insightReducer,
  mobileProfileViewReducer: mobileProfileViewReducer,
  mobileSignInReducer: mobileSignInReducer,
  bank: bankReducer,
  mobileFavoriteReducer: mobileFavoriteReducer,
  updateMobileNoReducer: updateMobileNoReducer,
  profileViewReducer: profileViewReducer,
});

export default rootReducer;
