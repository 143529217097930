import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const addNewFeedback = async (body) => {
  try {
    // Get response
    let response = await axios.post(`${BASE_URL}/add/new/feedback`, body);
    return response.data;
  } catch (err) {
    console.log("ERR_ADD_NEW_FEEDBACK_API:", err);
    throw err;
  }
};
