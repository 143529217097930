import React, {Component} from "react";
import './style.scss'


import {getAllLocation} from "../../service/locationApi";

import * as actionTypes from "../../store/actions/types";
import {openNotification} from "../../config/notification";
import * as user from "../../service/userApi";
import {Scrollbars} from "react-custom-scrollbars";
import {connect} from "react-redux";
import {Button, ButtonIcon, CheckItem, OfferCreateFormTitle} from "../../components";
import {
    RiArrowRightLine,
    RiCloseLine,
    RiRestaurantLine,
    RiTestTubeLine,
    RiTShirtLine,
    RiCheckLine
} from "react-icons/ri";
import {getAllCategory} from "../../service/categoryApi";

class CategoryTagComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewPointHeight: 0,
            viewPointWidth: 0,
            dataForm: {
                offerTitle: {
                    key: 'offerTitle',
                    elementType: 'input',
                    elementConfig: {
                        type: 'offerTitle',
                        placeholder: 'Ex : Hotel Name ,Restaurant Name',
                    },
                    value: '',
                    validation: {
                        required: true,

                    },
                    valid: false,
                    touched: false,
                    label: 'offer Title'
                },
                offerHeader: {
                    key: 'offerHeader',
                    elementType: 'input',
                    elementConfig: {
                        type: 'offerHeader',
                        placeholder: 'Ex : ABC House - 40% off',
                        maxlength: "90"
                    },
                    value: '',
                    validation: {
                        required: true,

                    },
                    valid: false,
                    touched: false,
                    label: 'offer Header'
                },
                offerDescription: {
                    key: 'offerDescription',
                    elementType: 'textArea',
                    elementConfig: {
                        type: 'text',
                        placeholder: 'Ex : This is my first offer description.',
                        rows: '4',
                        cols: '50',
                    },
                    value: '',
                    validation: {
                        required: false,
                    },
                    valid: false,
                    touched: false,
                    label: 'Offer Description'
                },
                offerValidity: {
                    key: 'offerValidity',
                    elementType: undefined,
                    elementConfig: {},
                    locationUrl: '',
                    value: 'FromTo',
                    validation: {
                        required: true,
                    },
                    valid: false,
                    touched: false,
                    label: undefined
                },
                offerType: {
                    key: 'offerType',
                    elementType: undefined,
                    elementConfig: {},
                    locationUrl: '',
                    value: 'off',
                    validation: {
                        required: true,
                    },
                    valid: false,
                    touched: false,
                    label: undefined
                },
                fromToDate: {
                    key: 'startDate',
                    elementType: 'datePicker',
                    elementConfig: {
                        format: 'DD/MM/YYYY',
                        label: 'Start Date',
                        size: 'md',
                        width: '100%',
                        placeholder: 'DD/MM/YYYY'
                    },
                    value: '',
                    validation: {
                        required: true,
                    },
                    valid: false,
                    touched: false,
                    label: 'Event Date'
                },
                offerRate: {
                    key: 'offerRate',
                    elementType: 'input',
                    elementConfig: {
                        type: 'offerRate',
                        placeholder: 'Ex : 10',
                    },
                    value: '',
                    validation: {
                        required: true,

                    },
                    valid: false,
                    touched: false,
                    label: 'Offer Rate (%)'
                },
                offerTitleTag: {
                    key: 'offerTitleTag',
                    elementType: undefined,
                    elementConfig: {},
                    locationUrl: '',
                    value: '',
                    validation: {
                        required: true,
                    },
                    valid: false,
                    touched: false,
                    label: undefined
                },
                bankInfo: {
                    key: 'bankInfo',
                    elementType: undefined,
                    elementConfig: {},
                    locationUrl: '',
                    value: '',
                    validation: {
                        required: true,
                    },
                    valid: false,
                    touched: false,
                    label: undefined
                },
                offerCategory: {
                    key: 'offerCategory',
                    elementType: undefined,
                    elementConfig: {},
                    locationUrl: '',
                    value: '',
                    validation: {
                        required: true,
                    },
                    valid: false,
                    touched: false,
                    label: undefined
                },
            }
        };


        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    };

    updateWindowDimensions() {
        this.setState({viewPointWidth: window.innerWidth, viewPointHeight: window.innerHeight});
    }

    handleOnScroll = () => {
        // http://stackoverflow.com/questions/9439725/javascript-how-to-detect-if-browser-window-is-scrolled-to-bottom
        let scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
        let scrollHeight = (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;
        let clientHeight = document.documentElement.clientHeight || window.innerHeight;

        console.log("scrollTop : " + scrollTop);
        console.log("scrollHeight : " + scrollHeight);
        console.log("clientHeight :" + clientHeight);

        // var scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;

        // if (scrolledToBottom) {
        //     this.setState(
        //         prevState => ({
        //             pageNo: prevState.pageNo + 1
        //         }),
        //         this.getOfferDataByPage
        //     );
        // }
    };

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        // window.addEventListener('scroll', this.listenToScroll);
        window.addEventListener('scroll', this.handleOnScroll);
        this.props.getAllLocation();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleOnScroll);
        window.removeEventListener('resize', this.updateWindowDimensions);
        // window.addEventListener('scroll', this.listenToScroll);
    }

    setWizardNavigation(wizardFormNo, userType) {
        const _this = this;
        this.setState({
            wizardNo: wizardFormNo,
            userType: userType ? userType : _this.state.userType,
            isEmailAlreadyTaken: false,
            isLoading: false
        });
    }


    onClickChangeType = (type, inputIdentity) => {
        let eventForm = {
            ...this.state.dataForm
        };
        let updatedFormElement = {
            ...eventForm[inputIdentity]
        };
        updatedFormElement.value = type;
        eventForm[inputIdentity] = updatedFormElement;
        let formIsValid = true;
        for (let inputIdentifier in eventForm) {
            formIsValid = eventForm[inputIdentifier].valid && formIsValid;
        }
        this.setState({dataForm: eventForm, formIsValid: formIsValid});
    };


    render() {
        const {
            viewPointWidth,
            viewPointHeight,
            dataForm
        } = this.state;
        return (
            <>
                {this.props.isLocationTag &&
                <>
                    <div className={'ct-modal-bg-wrapper top-form'}>
                        <div className={'join-form-content-wrapper'}
                             style={{
                                 height: viewPointHeight - 250,
                             }}>
                            <div className={'close-modal-wrapper flex-row'}>
                                <ButtonIcon
                                    type={'close'}
                                    onClick={() => this.props.onClickCloseLocationTag()}
                                    size={'md'}
                                    buttonIcon={<RiCloseLine/>}
                                />
                            </div>

                            <div className={'cno-main-wrapper '}>
                                <div className={'cno-data-form-wrapper d-flex flex-column  align-items-start'}
                                     style={{height: viewPointHeight - 380}}>
                                    {/*  ========== Offer Category ===========*/}
                                    <Scrollbars style={{height: viewPointHeight - 50}}>
                                        <OfferCreateFormTitle
                                            header={this.props.title}
                                            subTitle={' Lorem Ipsum is simply dummy text of the printing and typesetting industry.'}/>
                                        <div className={'cno-offer-body-wrapper mr-1 mb-1 d-flex flex-wrap '}>
                                            {this.props.locationData && this.props.locationData.map((d, index) =>

                                                <div
                                                    key={index}
                                                    className={'mr-1 mb-1 '}>
                                                    <CheckItem
                                                        key={index}
                                                        isImg={false}
                                                        isIcon={true}
                                                        isArrowRight={false}
                                                        type={'select-box'}
                                                        size={'sm'}
                                                        checkIcon={<RiCheckLine/>}
                                                        onClick={() => this.props.onSelectCategory(d)}
                                                        bannerIcon={<RiRestaurantLine/>}
                                                        arrowRight={<RiArrowRightLine/>}
                                                        isActive={d.isActive}
                                                        text={d.locationName}/>
                                                </div>
                                            )}
                                        </div>
                                    </Scrollbars>

                                </div>


                            </div>
                            <div className={'sign-in-button-wrapper d-flex justify-content-end'}>
                                <>
                                    <Button
                                        text={'Done'}
                                        type={'dark'}
                                        size={'sm'}
                                        leftIcon={false}
                                        rightIcon={true}
                                        reactIcon={<RiCheckLine/>}/>
                                </>
                            </div>

                        </div>

                    </div>


                </>
                }
            </>
        )
    }
}

const
    mapStateToProps = state => {
        return {
            catData: state.category.data,
            locationData: state.location.data,
            userData: state.user.data,
            isLogin: state.user.isLogin,
            isRegister: state.user.isRegister,
            isCategoryTag: state.form.isCategoryTag,
            isLocationTag: state.form.isLocationTag
        };
    };

const
    mapDispatchToProps = dispatch => {
        return {

            getAllLocation: () => dispatch(getAllLocation()),
            onSelectCategory: (selectedData) => dispatch({type: actionTypes.SELECTED_CATEGORY, sData: selectedData}),
            getLoginUserDetailsById: (id) => dispatch(user.getLoginUserDetailsById(id)),
            onClickCloseLocationTag: () => dispatch({type: actionTypes.CLOSE_LOCATION_TAG, status: false}),
        };
    };


export default connect(mapStateToProps, mapDispatchToProps)
(CategoryTagComponent);


