import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss'

const Index = (props) => (
    <div className={`pre__loader-outer-wrapper   ${props.isLoading ? 'loading' : 'loaded'}`}>
        <div className={`main__pre_loader-wrapper mb-4 ${props.option}`}>
            Loading...
        </div>
    </div>
);

Index.propTypes = {};

Index.defaultProps = {
    type: 'light',
    size: 'sm',
    icon: '<RiCloseFill/>'
};

export default Index;
