import "./style.scss";
import "./mobile.scss";
import { GetIcon } from "../../../../config/icon";
import React, { Component } from "react";
import { connect } from "react-redux";

class SuccessfullTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewPointHeight: 0,
      viewPointWidth: 0,
      isLoading: false,
      formIsValid: false,
      isFound: false,
      isSubscribe: false,
    };
  }

  render() {
    const {} = this.state;
    const {} = this.props;
    return (
      <>
        <div className={"successful"}>
          <div className={"s-icon"}>{GetIcon("check")}</div>

          <div className={"s-title"}>{"🎉 You’re Subscribed!"}</div>
          <div className={"s-subtitle"}>
            {
              " Thanks for joining us. Stay tuned for the latest and greatest credit card offers delivered right to your inbox!"
            }
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SuccessfullTemplate);
