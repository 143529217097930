import React from "react";
import { Switch } from "react-router-dom";

import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";

import ConsoleComponent from "../containers/console";
import SignInComponent from "../containers/signIn";
import SignUpComponent from "../containers/signUp";
import ViewOffer from "../containers/viewOffer";
import VerifyMobile from "../containers/verifyMobile";
import forgetPassword from "../containers/forgetPassword";
import AboutUs from "../containers/modal/MoreInfo/AboutUs";
import ContactUs from "../containers/modal/MoreInfo/ContactUs";
import Careers from "../containers/modal/MoreInfo/Careers";


const Index = (props) => (
  <Switch>
    <PublicRoute component={SignInComponent} path="/sign-in" />
    <PublicRoute component={SignUpComponent} path="/sign-up" />
    <PublicRoute component={forgetPassword} path="/forget-password" />
    <PublicRoute component={VerifyMobile} path="/verify-mobileNo" />
    <PublicRoute
      component={ViewOffer}
      path="/offer-view/:offerId/:bank/:category"
      exact
    />
    <PublicRoute component={AboutUs} path="/about-us" />
    <PublicRoute component={ContactUs} path="/contact-us" />
    <PublicRoute component={Careers} path="/careers" />

    <PublicRoute component={ConsoleComponent} path="/" exact />
    <PublicRoute component={ConsoleComponent} path="/:module" exact />
    <PrivateRoute component={ConsoleComponent} path="/:module/:module" exact />
    <PrivateRoute
      component={ConsoleComponent}
      path="/:module/:module/:module"
      exact
    />
    <PrivateRoute
      component={ConsoleComponent}
      path="/:module/:module/:module/:module"
      exact
    />
  </Switch>
);

export default Index;
