import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";

const Index = (props) => (
  <>
    <div
      className={`section-header-wrapper d-flex 
        ${props.type} ${props.size}`}
      onClick={props.onClick}
    >
      <div>{props.text}</div>
    </div>
  </>
);

Index.propTypes = {
  text: PropTypes.string.isRequired,
};

Index.defaultProps = {
  text: "text not found",
  icon: "<RiCloseFill/>",
};

export default Index;
