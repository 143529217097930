import React, {Component} from "react";
import {connect} from "react-redux";
import "./styles.scss";
import "./mobile.scss";
import {GetIcon} from "../../../../config/icon";
import {FilterCard, Input, SearchCard} from "../../../../components";
import * as actionTypes from "../../../../store/actions/types";
import {Scrollbars} from "react-custom-scrollbars";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      tabName: "bank",
      viewPointHeight: 0,
      viewPointWidth: 0,
      bankData: [],
      categoryData: [],
      recentBanks: [],
      recentCategories: [],
      activeList: [],
      activeBankName: [],
      isScrollTop: false,
      activeCategoryName: [],
      dataForm: {
        bank: {
          key: "bank",
          elementType: "input",
          elementConfig: {
            type: "input",
            placeholder: "Bank name type here..",
          },
          value: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "",
        },
        category: {
          key: "category",
          elementType: "input",
          elementConfig: {
            type: "input",
            placeholder: "category name type here..",
          },
          value: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "",
        },
      },
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
  }

  inputHandleChange = (event, inputIdentity) => {
    const updateSignUpForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateSignUpForm[inputIdentity],
    };
    updatedFormElement.value = event.target.value;
    updatedFormElement.touched = true;
    updateSignUpForm[inputIdentity] = updatedFormElement;
    let formIsValid = true;
    for (let inputIdentifier in updateSignUpForm) {
      formIsValid = updateSignUpForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateSignUpForm, formIsValid: formIsValid });
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    this.setState((prevState) => ({
      categoryData: prevState.categoryData.map((category) => ({
        ...category,
        isActive: false, // Set isActive to false for each bank
      })),
    }));

    this.setState((prevState) => ({
      isScrollTop: prevState.isScrollTop,
    }));

    this.setState((prevState) => ({
      bankData: prevState.bankData.map((bank) => ({
        ...bank,
        isActive: false, // Set isActive to false for each bank
      })),
    }));
    const sessionData = sessionStorage.getItem("recentBanks");
    if (sessionData) {
      // this.setState({ recentBanks: });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.bankData !== this.props.bankData ||
      prevProps.categoryData !== this.props.categoryData
    ) {
      const sessionData = sessionStorage.getItem("recentBanks");
      // console.log({ sessionData });
      if (
        this.props.bankData.length > 0 ||
        this.props.categoryData.length > 0
      ) {
        this.setState({ bankData: this.props.bankData });
        this.setState({ categoryData: this.props.categoryData });
      } else {
        this.setState({ bankData: [] });
        this.setState({ categoryData: [] });
      }
    }

    if (
      prevProps.bankList !== this.props.bankList ||
      prevProps.categoryList !== this.props.categoryList
    ) {
      if (
        this.props.bankList?.length > 0 ||
        this.props.categoryList?.length > 0
      ) {
        this.setState((prevState) => ({
          ...prevState,
          categoryData: this.props.categoryList.map((category) => ({
            ...category,
            isActive: false, // Set isActive to false for each bank
          })),
        }));

        this.setState((prevState) => ({
          ...prevState,
          bankData: this.props.bankList.map((bank) => ({
            ...bank,
            isActive: false, // Set isActive to false for each bank
          })),
        }));
      }
    }

    if (
      prevProps.activeBankName !== this.props.activeBankName ||
      prevProps.activeCategoryName !== this.props.activeCategoryName
    ) {
      if (this.props.activeCategoryName?.length === 0) {
        const { categoryData } = this.state;
        this.setState((prevState) => ({
          categoryData: prevState.categoryData.map((category) => ({
            ...category,
            isActive: false, // Set isActive to false for each bank
          })),
          activeCategoryList: [],
        }));
      }
      if (this.props.activeBankName?.length === 0) {
        this.setState((prevState) => ({
          bankData: prevState.bankData.map((bank) => ({
            ...bank,
            isActive: false, // Set isActive to false for each bank
          })),
          activeBankList: [],
        }));
      }

      // if (
      //   this.props.activeBankName?.length > 0 ||
      //   this.props.activeCategoryName?.length > 0
      // ) {
      this.setState({
        activeBankList: this.props.activeBankName,
        activeCategoryList: this.props.activeCategoryName,
      });
      // } else {
      //   this.setState({
      //     activeBankList: [],
      //     activeCategoryList: [],
      //   });
      // }
    }
  }

  //GET OFFER BY BANK
  getOfferFilterByBankId = async (
    bankId,
    bankName,
    logoURL,
    thumbnailURL,
    isActive
  ) => {
    this.props.setFilterBanks({
      type: "bank",
      id: bankId,
      filterName: bankName,
      logoURL: logoURL,
      thumbnailURL: thumbnailURL,
      isActive: !isActive,
    });

    this.setState({ ...this.state.bankIdArray, bankId });
  };
  //GET OFFER BY CATEGORY
  getOfferFilterByCategoryId = async (categoryId, categoryName, iconName) => {
    this.props.setFilterCategory({
      type: "category",
      id: categoryId,
      filterName: categoryName,
      iconName: iconName,
      isActive: true,
    });

    this.setState({ ...this.state.categoryIdArray, categoryId });
  };

  onClickSelectBank = async (bankId) => {
    const { bankData } = this.state;
    await this.setState((prevState) => ({
      bankData: prevState.bankData.map(
        (bank) =>
          bank.bankId === bankId ? { ...bank, isActive: !bank.isActive } : bank // Update category object
      ),
    }));
  };

  onClickSelectCategory = async (id) => {
    const { categoryData } = this.state;
    await this.setState((prevState) => ({
      categoryData: prevState.categoryData.map(
        (category) =>
          category.categoryId === id
            ? { ...category, isActive: !category.isActive }
            : category // Update category object
      ),
    }));
  };

  handleSetRecent = (item, type) => {
    // sessionStorage.setItem(sessionData);
    if (type === "bank") {
      if (!this.state.recentBanks.find((X) => X.id === item.id)) {
        this.setState({ recentBanks: [...this.state.recentBanks, item] });
      }
    } else {
      if (!this.state.recentCategories.find((X) => X.id === item.id)) {
        this.setState({
          recentCategories: [...this.state.recentCategories, item],
        });
      }
    }
  };

  clearAll() {
    const updateForm = { ...this.state.dataForm };
    for (let key in updateForm) {
      updateForm[key].value = "";
      updateForm[key].valid = false;
      updateForm[key].touched = false;
    }
    this.setState({ dataForm: updateForm });
  }

  render() {
    const {
      tabName,
      dataForm,
      viewPointHeight,
      viewPointWidth,
      categoryData,
      bankData,
      recentBanks,
      recentCategories,
      activeBankList,
      activeCategoryList,
      activeBankName,
      activeCategoryName,
    } = this.state;
    const {
      isFilterOpen,
      closeMe,
      recentSelectBanks,
      recentSelectCategories,
      props,
      isLoading,
      offerData,
    } = this.props;

    const banks = (
      <div className={"filter-list"}>
        <div style={{ marginBottom: "8px" }}>
          <Input
            elementConfig={dataForm.bank.elementConfig}
            elementType={dataForm.bank.elementType}
            required={dataForm.bank.validation.required}
            maxLength={dataForm.bank.validation.maxLength}
            invalidReason={dataForm.bank.invalidReason}
            isinvalid={!dataForm.bank.valid}
            touched={dataForm.bank.touched}
            value={dataForm.bank.value}
            size={"sm-search"}
            verificationStatus={dataForm.bank.valid}
            showVerification={!!dataForm.bank.value}
            margin={"m-t-8"}
            label={dataForm.bank.label}
            onClickClear={() => this.clearAll()}
            changed={(event) =>
              this.inputHandleChange(event, dataForm.bank.key)
            }
          />
        </div>
        <div>
          <Scrollbars
            style={{
              height:
                viewPointWidth <= 1600
                  ? viewPointHeight - 530
                  : viewPointHeight - 700,
            }}
            thumbMinSize={30}
            universal={true}
            autoHide
          >
            <>
              {this.state.bankData
                ?.filter((item) => {
                  if (dataForm["bank"].value === "") {
                    return item; // Corrected the return statement
                  } else if (
                    item.bankName
                      ?.toLowerCase()
                      .includes(dataForm["bank"].value.toLowerCase())
                  ) {
                    return item; // Corrected the return statement
                  }
                })
                .sort((a, b) => {
                  // Sort to place active items at the top
                  return b.isActive - a.isActive;
                })
                .map((item, index) => (
                  <SearchCard
                    type={"bank"}
                    size={"sm"}
                    imageType={"image"}
                    imgUrl={item.thumbnailURL}
                    remove={false}
                    isSelect={item.isActive}
                    select={false}
                    format={"select-box"}
                    text={item.bankName}
                    onClick={async () => {
                      await this.onClickSelectBank(item?.bankId);
                      await this.getOfferFilterByBankId(
                        item.bankId,
                        item.bankName,
                        item.logoURL,
                        item.thumbnailURL
                      );
                      this.handleSetRecent(
                        {
                          type: "bank",
                          id: item.bankId,
                          filterName: item.bankName,
                          logoURL: item.logoURL,
                          isActive: true,
                        },
                        "bank"
                      );
                    }}
                  />
                ))}
            </>
          </Scrollbars>
        </div>
      </div>
    );

    const category = (
      <div className={"filter-list"}>
        <div style={{ marginBottom: "8px" }}>
          <Input
            elementConfig={dataForm.category.elementConfig}
            elementType={dataForm.category.elementType}
            required={dataForm.category.validation.required}
            maxLength={dataForm.category.validation.maxLength}
            invalidReason={dataForm.category.invalidReason}
            isinvalid={!dataForm.category.valid}
            touched={dataForm.category.touched}
            value={dataForm.category.value}
            size={"sm-search"}
            verificationStatus={dataForm.category.valid}
            showVerification={!!dataForm.category.value}
            margin={"m-t-8"}
            label={dataForm.category.label}
            onClickClear={() => this.clearAll()}
            changed={(event) =>
              this.inputHandleChange(event, dataForm.category.key)
            }
          />
        </div>
        <div>
          <Scrollbars
            style={{
              height:
                viewPointWidth <= 1600
                  ? viewPointHeight - 530
                  : viewPointHeight - 700,
            }}
            thumbMinSize={30}
            universal={true}
            autoHide
          >
            <>
              {this.state.categoryData
                ?.filter((item) => {
                  if (dataForm["category"].value === "") {
                    return item; // Corrected the return statement
                  } else if (
                    item.categoryName
                      ?.toLowerCase()
                      .includes(dataForm["category"].value.toLowerCase())
                  ) {
                    return item; // Corrected the return statement
                  }
                })
                .sort((a, b) => {
                  // Sort to place active items at the top
                  return b.isActive - a.isActive;
                })
                .map((item, index) => (
                  <SearchCard
                    type={"bank"}
                    size={"sm"}
                    imageType={"icon"}
                    iconName={item?.iconName}
                    remove={false}
                    isSelect={item.isActive}
                    select={false}
                    format={"select-box"}
                    text={item?.categoryName}
                    onClick={async () => {
                      await this.onClickSelectCategory(item.categoryId);
                      await this.getOfferFilterByCategoryId(
                        item.categoryId,
                        item.categoryName,
                        item?.iconName
                      );
                      this.handleSetRecent(
                        {
                          type: "category",
                          id: item.categoryId,
                          filterName: item.categoryName,
                          iconName: item?.iconName,
                          isActive: true,
                        },
                        "category"
                      );
                    }}
                  />
                ))}
            </>
          </Scrollbars>
        </div>
      </div>
    );

    return (
      <>
        <div
          className={`console__search-wrapper ${
            this.props.isScrollTop ? "fix-top active-shadow" : ""
          }`}
        >
          <div className={"container"}>
            <div className={"d-flex flex-row"} style={{ gap: "8px" }}>
              <FilterCard
                  type={"filter"}
                  noActiveFilter={
                      (activeBankList ? activeBankList.length : 0) + (activeCategoryList ? activeCategoryList.length : 0)
                  }
                  isText={true}
                  isActive={false}
                  iconLeft={GetIcon("slider")}
                  text={"Filter"}
                  isRadioBtn={false}
                  isRemove={false}
                  onClick={() =>
                      viewPointWidth < 480 && this.props.onClickMobileFilterOpen()
                  }
              />
              {viewPointWidth > 480 && (
                <>
                  <FilterCard
                    type={"list-card"}
                    isText={true}
                    isActive={activeBankList?.length > 0 || false}
                    text={
                      activeBankList?.length > 0
                        ? activeBankList[0]?.filterName
                        : "Bank"
                    }
                    isRadioBtn={false}
                    isRemove={true}
                    isActiveCount={false}
                    count={activeBankList?.length || 0}
                    filterList={banks}
                  />
                  <FilterCard
                    type={"list-card"}
                    isText={true}
                    isActive={activeCategoryList?.length > 0 || false}
                    text={
                      activeCategoryList?.length > 0
                          ? activeCategoryList[0]?.filterName
                          : "Category"
                    }
                    isRadioBtn={false}
                    isRemove={false}
                    isActiveCount={false}
                    count={activeCategoryList?.length || 0}
                    filterList={category}
                  />
                </>
              )}

              {/*{viewPointWidth < 480 &&*/}
              {/*  activeCategoryList &&*/}
              {/*  activeCategoryList.length > 0 && (*/}
              {/*    <>*/}
              {/*      <FilterCard*/}
              {/*        type={"list-card"}*/}
              {/*        isText={true}*/}
              {/*        isActive={activeCategoryList?.length > 0 || false}*/}
              {/*        text={*/}
              {/*          activeCategoryList?.length > 0*/}
              {/*            ? activeCategoryList[0]?.filterName*/}
              {/*                ?.substring(0, 15)*/}
              {/*                .trimEnd() + "..."*/}
              {/*            : "Category"*/}
              {/*        }*/}
              {/*        isRadioBtn={false}*/}
              {/*        isRemove={false}*/}
              {/*        isActiveCount={false}*/}
              {/*        count={activeCategoryList?.length || 0}*/}
              {/*        filterList={category}*/}
              {/*      />*/}
              {/*    </>*/}
              {/*  )}*/}
              {((activeBankList && activeBankList.length > 0) ||
                  (activeCategoryList && activeCategoryList.length > 0)) && (
                  <FilterCard
                      type={"filter"}
                      isText={true}
                      isActive={
                        (activeBankList && activeBankList.length > 0) ||
                        (activeCategoryList && activeCategoryList.length > 0)
                            ? true
                            : false
                      }
                      iconLeft={""}
                      text={"Clear all"}
                      isRadioBtn={
                        (activeBankList && activeBankList.length > 0) ||
                        (activeCategoryList && activeCategoryList.length > 0)
                            ? true
                            : false
                      }
                      isRemove={true}
                  onClickClear={() => {
                    this.props.clearFilters();
                    closeMe();
                  }}
                  // onClick={() =>
                  //   viewPointWidth < 480 && this.props.onClickMobileFilterOpen()
                  // }
                />
              )}
            </div>
            {/* {(activeBankList?.length || activeCategoryList?.length) > 0 && (
              <Scrollbars
                thumbMinSize={30}
                universal={true}
                autoHide
                style={{ width: viewPointWidth - 430, minHeight: 60 }}
              >
                <div
                  className={"d-flex flex-row"}
                  style={{ gap: "8px", marginTop: "8px" }}
                >
                  <>
                    {(activeBankList || []).map((item, index) => (
                      <FilterCard
                        type={"view-card"}
                        isText={true}
                        isActive={item.isActive}
                        text={item.filterName}
                        isRadioBtn={true}
                        isRemove={true}
                        isActiveCount={false}
                        count={""}
                      />
                    ))}
                    {(activeCategoryList || []).map((item, index) => (
                      <FilterCard
                        type={"view-card"}
                        isText={true}
                        isActive={item.isActive}
                        text={item.filterName}
                        isRadioBtn={true}
                        isRemove={true}
                        isActiveCount={false}
                        count={""}
                      />
                    ))}
                  </>
                </div>
              </Scrollbars>
            )} */}
            {(isLoading || offerData?.length === 0) && (
              <>
                <div
                  className={`empty-offer-list d-flex flex-column justify-content-center align-items-center align-content-center`}
                  style={{
                    position:
                      viewPointWidth <= 480
                        ? (activeBankList && activeBankList.length > 0) ||
                          (activeCategoryList && activeCategoryList.length > 0)
                          ? "absolute"
                          : "relative"
                        : "absolute",
                  }}
                >
                  <img
                    src={require("../../../../assets/img/empty_list.svg")}
                    alt="creditcard"
                  />
                  <div className={`eol__title`}>
                    It looks like don't have any offers yet.
                  </div>
                  <div className={`eol__subtitle`}>
                    Explore our available offers and select your favorites!
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isOfferView: state.form.isOfferView,
    offerData: state.offer.offerData,
    categoryData: state.category.data,
    isFilterOpen: state.form.isFilterOpen,
    bankData: state.bank.bankData,
    isLogin: state.user.isLogin,
    activeBankName: state.offer.activeBankName,
    activeCategoryName: state.offer.activeCategoryName,
    recentSelectBanks: state.offer.recentSelectBanks,
    recentSelectCategories: state.offer.recentSelectCategories,
    isLoading: state.offer.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeMe: (payload) =>
      dispatch({
        type: actionTypes.FILTER_CLOSE,
      }),
    clearFilters: () =>
      dispatch({
        type: actionTypes.CLEAR_ALL_FILTER,
      }),
    setFilterBanks: (payload) =>
      dispatch({
        type: actionTypes.SET_FILTER_OPTION_BANK,
        payload: payload,
      }),
    setFilterCategory: (payload) =>
      dispatch({
        type: actionTypes.SET_FILTER_OPTION_CATEGORY,
        payload: payload,
      }),
    onLoadOffer: () =>
      dispatch({
        type: actionTypes.ON_LOAD_OFFER,
      }),
    onClickMobileFilterOpen: () =>
      dispatch({
        type: actionTypes.FILTER_OPEN,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
