import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import "./mobile.scss";

const Index = (props) => (
  <>
    <div
      className={`filter-color-second-wrapper d-flex flex-column align-items-center`}
    >
      <div className={`fcsw__color1`} />
      <div className={`d-flex flex-row justify-content-between`}>
        <div className={`fcsw__color2`} />
        <div className={`fcsw__color3`} />
      </div>
    </div>
  </>
);

Index.propTypes = {
};

Index.defaultProps = {
  text: "",
  icon: "",
};

export default Index;
