import React from 'react';
import './styles.scss'

const Index = (props) => (
    <>
        <div className={'cno-form-title-wrapper'}>
            <div className={'cno-form-title'}>
                {props.header}
            </div>
            <div className={'cno-form-sub-title'}>
                {props.subTitle}
            </div>
        </div>
    </>
);

export default Index;
