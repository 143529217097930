import * as actionTypes from '../actions/types';

const initialState = {
    data: [],
    error: null,
    loading: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_EVENT_PENDING:
            return {
                ...state,
                error: null,
                data: [],
                loading: true,
            };
        case actionTypes.GET_EVENT_FAILURE:
            return {
                ...state,
                error: action.error,
                data: [],
                loading: false,
            };
        case actionTypes.GET_ALL_EVENT:
            return {
                ...state,
                error: null,
                data: action.data,
                loading: false,
            };
        case actionTypes.ADD_EVENT:
            return {
                ...state,
                error: null,
                data: state.data.concat(action.newEvent),
                loading: false
            };
        default:
            return state
    }
};

